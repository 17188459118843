/* eslint-disable max-len */
import { urls } from "type-absenso";

let proxy = "";

if (process.env.NODE_ENV === "development") {
  proxy = "http://localhost:5001/stagging-absenso/europe-west1/api";
} else {
  proxy = process.env.REACT_APP_BACKEND_URL as string;
}

export const createUserUrl = `${proxy}${urls.createUser}`;
export const deleteLikesUrl = `${proxy}${urls.deleteLikes}`;
export const deleteFollowUrl = `${proxy}${urls.deleteFollow}`;
export const orderProduct = `${proxy}${urls.orderProduct}`;
export const deletePostUrl = `${proxy}${urls.deletePosts}`;
export const deleteCommentsUrl = `${proxy}${urls.deleteComments}`;
export const updateDeathUrl = `${proxy}${urls.updateDeceased}`;
export const addAdminUrl = `${proxy}${urls.addAdminDeceased}`;
export const subscribeToOfferUrl = `${proxy}${urls.subscribeToAnOffer}`;
export const formattedAddressUrl = `${proxy}${urls.formatAddress}`;
export const detailAddressUrl = `${proxy}${urls.detailsAddress}`;
export const createDeathUrl = `${proxy}${urls.createDeath}`;
export const closeOrderOfferUrl = `${proxy}${urls.closeOffer}`;
export const addPostUrl = `${proxy}${urls.addPost}`;
export const linkProductWithDeceased = `${proxy}${urls.linkProductWithDeceased}`;
export const createDeceasedWithproductUrl = `${proxy}${urls.createDeathWithProduct}`;
export const contactUrl = `${proxy}${urls.contact}`;
export const updateDeathProfileUrl =`${proxy}${urls.updateDeathProfile}`;
export const updateDeathLocationUrl = `${proxy}${urls.updateDeathLocation}`;
export const resetPasswordUrl = `${proxy}${urls.resetPassword}`;