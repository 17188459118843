import React from "react";
import { getLang } from "../../i18n/i18n";

const GeneralConditionOfUse = () => {
  const lang = getLang();
  return <iframe
    style={{
      height: "100vh"
    }}
    width="100%"
    src={require(`../../media/pdf/${lang}_general_condition_of_use.pdf`)}
  >

  </iframe>;
};

export default GeneralConditionOfUse;