import React from "react";
import { ButtonBase, Typography, Box } from "@mui/material";
import { IUserFront } from "../../../backend/type/frontDocument";
import { darkTurquoiseLight, gold } from "../../../ui/color";
import { UserPicture } from "./userPicture";
import { Person2Rounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { createFormatSubRoute, routing } from "../../../router";

export const UserConnectedMenu = ({user}: {user: IUserFront | undefined}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  if (user) {
    return(
      <Box sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: darkTurquoiseLight,
        p: 0.5,
        m: 1,
        borderRadius: theme => theme.spacing(0.5)
      }}>
        <UserPicture
          user={user}
          className="rounded_img"
          sx={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
            height: (theme: any) => theme.spacing(3),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            width: (theme: any) => theme.spacing(3),
            mr: 0.5,
          }}
          sxIcon={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            fontSize: (theme: any) => theme.spacing(3)
          }}
        />
        <Typography sx={{
          mr: 1,
          fontSize: theme => theme.spacing(1.5)
        }}>
          {`${user.firstName} ${user.name}`}
        </Typography>

        <ButtonBase
          onClick={() => navigate(createFormatSubRoute(
            routing.user.index, routing.user.indexPage
          ).replace(":id", user.id))}
          sx={{
            backgroundColor: gold,
            p: 1,
            px: 2,
          }}>
          <Person2Rounded sx={{
            fontSize: theme => theme.spacing(2),
            mr: 0.5
          }} />
          {t("profile")}
        </ButtonBase>
      </Box>
    );
  }

  return(<></>);
};