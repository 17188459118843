import { getAuth } from "@firebase/auth";
import { useState } from "react";
import { app } from "../../../../backend/config";
import { IOrderOffersFront } from "../../../../backend/type/frontDocument";
import { IDataFormDeceased }
  from "../../../../components/utils/deceased/formDeceased";
import { useAlert } from "../../../../provider/error/hooks/useAlert";
import {
  collections,
  IDataFrontCloseOrder,
  IDataFrontCreateDeceased,
  IDataResponseDeceased,
} from "type-absenso";
import { DocumentReference, Timestamp } from "firebase/firestore";
import { uploadFile } from "../../../../backend/utils/uploadFile";
import { postAuth } from "../../../../backend/utils/postAuth";
import { createDeathUrl } from "../../../../backend/utils/url";
import { closeOrderOffer } from "../../../../backend/orderOffer";
import { useNavigate } from "react-router-dom";
import { routing } from "../../../../router";

interface ICreateDeceasedHook {
    offer?: IOrderOffersFront;
    sessionId?: string;
}

export const useCreateDeceased = ({
  offer,
  sessionId
}: ICreateDeceasedHook) => {
  const auth = getAuth(app);
  const alert = useAlert();
  const navigate = useNavigate();

  const [loadingAction, setLoadingAction] = useState<boolean>(false);

  const transformInDataFrontDeath = (data: IDataFormDeceased) => {
    if (auth && auth.currentUser) {
      const deathData: IDataFrontCreateDeceased<
          DocumentReference, Timestamp
        > = {
          admin: [auth.currentUser.uid],
          profilePicture: null,
          coverPicture: null,
          name: data.name,
          firstName: data.firstName,
          fullname: `${data.firstName} ${data.name}`,
          lastAddress: data.address.label,
          geoLocation: {
            lat: data.latitude,
            lng: data.longitude,
            hash: data.hash
          },
          nOfCandlesOrdered: 0,
          dateOfBirth: data.dateOfBirth.toJSON(),
          dateOfDeath: data.dateOfDeath.toJSON(),
          description: data.biography || null,
          religion: (data.religion && data.religion.value) || undefined,
          type: offer && offer.id && sessionId ? "paid" : "free",
          payment: sessionId || undefined,
          offer: (offer && offer.id) || undefined,
          funeral: data.funeralCompany,
        };
      
      return deathData;
    }

    return undefined;
  };

  const actionHandler = async (
    data: IDataFormDeceased,
    deliveryData?: {
      funeral?: IDataFrontCloseOrder["funeral"],
      deliveryData?: IDataFrontCloseOrder["deliveryData"]
    }
  ) => {
    setLoadingAction(true);
    try {
      if (auth && auth.currentUser) {
        const deathData = transformInDataFrontDeath(data);

        if (deathData) {
          if (data.crop) {
            const url = await uploadFile({
              file: data.crop,
              collection: collections.death,
            });
            deathData.profilePicture = url;
          }

          const response = await postAuth(createDeathUrl, deathData);

          if (response && response.data) {
            const data = response.data as IDataResponseDeceased;
            if (offer && offer.id) {
              const dataToSend: IDataFrontCloseOrder = {
                idOrderOffer: offer.id,
                death: data.uid,
              };

              if (deliveryData) {
                if (deliveryData.funeral) {
                  dataToSend.funeral = deliveryData.funeral;
                }

                if (deliveryData.deliveryData) {
                  dataToSend.deliveryData = deliveryData.deliveryData;
                }
              }

              await closeOrderOffer(dataToSend);
            }
            navigate(routing.desceaded.index.replace(":id", data.uid));
          }
        }
      }
    } catch (error) {
      alert.defaultError();
    } finally {
      setLoadingAction(false);
    }
  };

  return {loadingAction, actionHandler, transformInDataFrontDeath};
};