/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Router
import { useLocation } from "react-router-dom";
import { IProduct } from "type-absenso";

// Backend
import { getProducts } from "../../backend/product";

// Utils
import { IStateOrder } from "../../components/utils/deceased/types";
import { useAlert } from "../../provider/error/hooks/useAlert";

// Ui
import {
  Grid,
  List,
  ListItem,
  Box,
  Typography,
  Card,
  Button
} from "@mui/material";

// Form
import { useForm } from "react-hook-form";
import AutoCompleteReactHookForm from "../../components/forms/autocomplete";
import { IAssetAutoCompleteField } from "../../components/forms/types";
import { IBasketProps } from "./types";
import OrderCheckout from "../../components/utils/order/orderCheckout";

const ConsultProduct = () => {
  const location = useLocation();
  const state = location.state as IStateOrder;
  const alert = useAlert();
  const {t} = useTranslation();
  const {control, handleSubmit} = useForm();

  const [products, setProducts] = useState<Array<IProduct>>();
  const [basket, setBasket] = useState<Array<IBasketProps>>([]);

  const calculationTotalAmount = (basketProps: Array<IBasketProps>) => {
    let totalPrice = 0;
    for (const b of basketProps) {
      const price = b.product.price * b.qte;
      totalPrice = totalPrice + price;
    }

    return totalPrice;
  };

  useEffect(() => {
    (async() => {
      if (state) {
        try {
          const productsData = await getProducts();
          setProducts(productsData);
        } catch (error) {
          alert.defaultError();
        }
      }
    })();
  }, [location.state]);

  const onSubmit = (
    data: {quantity: IAssetAutoCompleteField<string>},
    id: string,
  ) => {
    if (products) {
      const product = products.filter((el) => el.id === id);

      if (product.length > 0) {
        setBasket([...basket, {
          qte: parseInt(data.quantity.value),
          product: product[0]
        }]);
      }
    }
  };

  return(
    <Grid container>
      <Grid item md={6} xs={12}>
        {products && <List>
          {products.map((el, i) => (
            <ListItem key={i}>
              <Card sx={{
                width: "100%",
                p: 1,
                display: "flex",
                alignItems: "center"
              }}>
                <img 
                  style={{
                    height: "auto",
                    width: "120px",
                    borderRadius: "50%"
                  }}
                  src={el.image} 
                  alt={`Image of ${el.name}`}
                />

                <Box pl={1} sx={{width: "100%"}}>
                  <Typography>
                    {`${el.name} ${el.price}`}
                  </Typography>
                  <AutoCompleteReactHookForm
                    control={control}
                    element={{
                      label: "quantity",
                      id: "quantity",
                      name: "quantity",
                      options: Array.from(
                        Array(100),
                        (_, i) => i + 1).map(el => {
                        return {value: el.toString(), label: el.toString()};
                      })
                    }}
                  />
                  <Button
                    variant="contained"
                    onClick={handleSubmit((data: any) => {
                      onSubmit(data, el.id || "");
                    })}
                    sx={{
                      width: "100%",
                      mt: 1
                    }}
                  >
                    {t("addToCart")}
                  </Button>
                </Box>
              </Card>
            </ListItem>
          ))}
        </List>}
      </Grid>
      <Grid item md={6} xs={12}>
        <Box px={2}>
          <Typography>{t("basket")}</Typography>

          {basket && basket.length > 0 &&
            <>
              {basket.map((el, i) => (
                <Box key={i} sx={{display: "flex"}}>
                  <img 
                    style={{
                      height: "auto",
                      width: "120px",
                      borderRadius: "50%"
                    }}
                    src={el.product.image} 
                    alt={`Image of ${el.product.name}`}
                  />
                  <Typography>{el.qte}</Typography>
                </Box>
              ))}

              <Typography>
                {`Total : ${calculationTotalAmount(basket)}`}
              </Typography>

              <OrderCheckout 
                total={calculationTotalAmount(basket)}
                deathId={state.idDeath}
                basket={basket}
              />
            </>
          }
        </Box>
      </Grid>
    </Grid>
  );
};

export default ConsultProduct;
