import React, { useEffect } from "react";

// Hooks
import { useAlert } from "../../provider/error/hooks/useAlert";
import { useTranslation } from "react-i18next";

// Router
import { Params, useParams } from "react-router";
import { getStock } from "../../backend/stock";
import { useNavigate } from "react-router-dom";
import { routing } from "../../router";
import { adduserScanned } from "../../backend/userScanned";
import { 
  Timestamp, 
  doc, 
  increment, 
  updateDoc 
} from "firebase/firestore";
import { getAuth } from "@firebase/auth";
import { db, app } from "../../backend/config";
import { collections } from "type-absenso";

const ScanProduct = () => {
  const alert = useAlert();
  const { t } = useTranslation();
  const id: Readonly<Params<string>> = useParams();
  const user = getAuth(app).currentUser;
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const data = await getStock({ id: id.id as string });

        if (data && data.deceased) {
          const deceasedRef = doc(db, collections.death, data.deceased.id);

          await updateDoc(deceasedRef,{
            numberOfMemories: increment(1)
          });

          await adduserScanned({
            date: Timestamp.now(),
            death: data.deceased,
            userScanned:
              (user && doc(db, collections.users, user.uid)) || "unknown",
            type: "stock",
            thingScanned: doc(db, collections.stock, id.id as string),
          });

          navigate(
            `${routing.desceaded.index.replace(":id", data.deceased.id)}`
          );
        } else if (data && !data.deceased) {
          if (alert && alert.createAlert) {
            alert.createAlert({
              type: "error",
              message: t("errorNoDeceased"),
            });
          }
        } else {
          if (alert && alert.createAlert) {
            alert.createAlert({
              type: "error",
              message: t("idDoesntExist"),
            });
          }
        }
      } catch (error) {
        if (alert) {
          alert.defaultError();
        }
      }
    })();
  }, [id.id]);

  return <></>;
};

export default ScanProduct;
