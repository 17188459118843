import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { gold } from "../../../../ui/color";
import { LoadingButton } from "@mui/lab";
import { ChevronRight } from "@mui/icons-material";

export const Congrats = ({
  action,
  btnLoading,
}: {
    action: () => void;
    btnLoading: boolean | undefined;
}) => {
  const {t} = useTranslation();
  return(
    <>
      <Box sx={{
        width: "100%",
        minHeight: "100vh",
        backgroundImage: `url(${
          require("../../../../media/img/img-applaud.png")
        })`,
        backgroundSize: "contain",
        backgroundPositionY: "bottom",
        backgroundRepeat: "no-repeat",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
      }}>
        <Typography 
          variant={"title" as never}
        >
          {t("congrats")}
        </Typography>
        <Typography variant={"subtitle" as never} sx={{
          color: gold
        }}>
          {t("accountHasBeenCreated")}
        </Typography>

        <LoadingButton
          variant="contained"
          onClick={action}
          loading={btnLoading}
          sx={{
            backgroundColor: gold,
            display: "flex",
            justifyContent: "space-between",
            mt: 4
          }}
        >
          {t("connectTitle")}
          <ChevronRight />
        </LoadingButton>
      </Box>
    </>
  );
};