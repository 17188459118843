import { useState } from "react";
import { IPostFront } from "../../../../../backend/type/frontDocument";
import { IValueSortBy } from "../../../../../backend/post";
import { getPosts } from "../../../../../backend/post";
import { getAuth } from "@firebase/auth";
import { app } from "../../../../../backend/config";
import { useAlert } from "../../../../../provider/error/hooks/useAlert";

export const usePosts = (deathId: string | undefined) => {
  const alert = useAlert();

  const [posts, setPosts] = useState<Array<IPostFront>>();
  const [loading, setLoading] = useState<boolean>(false);
  const [sortValue, setSortValue] = useState<IValueSortBy>("dateOfPublication");
  const [hasMore, setHasMore] = useState<boolean>(true);

  const getData = async(infinite?: boolean) => {
    if (deathId) {
      const user = getAuth(app).currentUser;
      !infinite && setLoading(true);
      if (sortValue) {
        try {
          const limitQuery = 10;
          const data = await getPosts(
            deathId,
            sortValue,
            hasMore,
            (user && user.uid) || null,
            limitQuery,
            (posts && posts[posts.length - 1])
          );
          if (data.length === 0) {
            setHasMore(false);
          }
          if (posts) {
            setPosts(posts.concat(data));
          } else {
            setPosts(data);
          }
        } catch (error) {
          if (alert) {
            alert.defaultError();
          }
        }
      }
      !infinite && setLoading(false);
    }
  };

  const changeSortValue = (newSortValue: IValueSortBy) => {
    setSortValue(newSortValue);
    setPosts(undefined);
  };

  const addPost = (newPost: IPostFront) => {
    if (posts) {
      setPosts([newPost].concat(posts));
    }
  };

  return {
    posts,
    loading,
    sortValue,
    hasMore,
    getData,
    addPost,
    changeSortValue,
  };
};