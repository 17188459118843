import { Theme } from "@emotion/react";
import { AddPhotoAlternate, Person } from "@mui/icons-material";
import { Box, SxProps } from "@mui/material";
import React, { useState } from "react";
import { IDeathFront, IUserFront } from "../../../backend/type/frontDocument";
import ImageCropper from "../imagecropper";
import { gold } from "../../../ui/color";
import { useMemo } from "react";
import { useAlert } from "../../../provider/error/hooks/useAlert";
import { uploadFile } from "../../../backend/utils/uploadFile";

export const UserPicture = ({
  user,
  death,
  sx,
  sxIcon,
  className,
  isAdmin,
  collection,
  onUpload,
}: {
    user?: IUserFront;
    death?: IDeathFront;
    className?: string;
    isAdmin?: boolean;
    sx: SxProps<Theme>;
    sxIcon: SxProps<Theme>;
    collection?: string;
    onUpload?: (url: string) => Promise<void>;
  }) => {
  const alert = useAlert();
  const [urlCrop, setUrlCrop] = useState<string>();
  
  const picture = urlCrop || (
    (user && user.pictures) || (death && death.profilePicture)
  );
  const firstname = (user && user.firstName) || (death && death.firstName);

  const uploadNewPicture = async (cropp: File) => {
    if (cropp && isAdmin && collection) {
      try {
        const url = await uploadFile({
          file: cropp,
          collection,
        });
        if (onUpload) {
          await onUpload(url);
          setUrlCrop(undefined);
        }
      } catch (error) {
        alert.defaultError();
      }
    }
  };

  const Edit = () => {
    return <ImageCropper 
      id="uploadProfilePiture"
      onCrop={async (cropp) => {
        await uploadNewPicture(cropp);
      }}
      urlCrop={urlCrop}
      setUrlCrop={setUrlCrop}
      sxContainer={{
        width: "100%",
        height: "100%"
      }}
      customComponent={
        <Box sx={{
          ...sx,
          width: "100%",
          height: "100%",
          position: "absolute",
          backgroundColor: "rgba(1, 1, 1, 0.5)"
        }}>
          {!urlCrop &&
            <AddPhotoAlternate sx={{
              color: gold,
              fontSize: theme => theme.spacing(6)
            }} />
          }
        </Box>
      }
    />;
  };

  const edit = useMemo(() => {
    return <Edit />;
  }, []);

  if (picture && picture !== "default") {
    return(
      <Box sx={{
        ...sx,
        position: "relative",
      }}>
        {isAdmin && edit}
        <img
          className={`full_width ${className}`}
          src={picture} alt={`${firstname}'s picture`}
        />
      </Box>
    );
  }

  return <Box 
    className={className}
    sx={{
      ...sx,
      position: "relative",
    }}>
    {isAdmin ? edit
      : <Person sx={sxIcon} />}
  </Box>;
};