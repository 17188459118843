import React from "react";

// Router
import { Routes, Route } from "react-router-dom";
import { router } from "type-absenso";
import { routing } from "..";

// Views
import Signin from "../../views/auth/signin";
import Signup from "../../views/auth/signup";
import LayoutUnAuth from "../../views/layout/layoutUnAuth";
import ScanKit from "../../views/scan/kit";
import ScanProduct from "../../views/scan/product";
import PageProvider from "../../provider/deceased/pageProvider";
import AlertProvider from "../../provider/error/alertprovider";
import AskAuthProvider from "../../provider/auth/askAuthProvider";
import Post from "../../views/desceaded/post";
import PaymentSuccess from "../../views/order/paymentSuccess";
import PaymentFailed from "../../views/order/paymentFailed";
import PageCustomUrl from "../../views/desceaded/pageCustomUrl";
import HomeWebsite from "../../views/website/home";
import { MyContainer } from "../../components/utils/myContainer";
import ConsultProduct from "../../views/order/consultProduct";
import ResetPassword from "../../views/auth/resetpassword";
import Shop from "../../views/shop/shop";
import ProfilDeceased from "../../views/desceaded/profilDeceased";
import GeneralConditionOfUse from "../../views/website/generalConditionOfuse";
import PrivacyPolicy from "../../views/website/privacyPolicy";
import QrCodeView from "../../views/website/qrcode";

const UnAuthRoute = () => {
  return(
    <AskAuthProvider>
      <LayoutUnAuth>
        <Routes>
          <Route
            path={routing.website.index}
            element={
              <AskAuthProvider>
                <AlertProvider>
                  <HomeWebsite />
                </AlertProvider>
              </AskAuthProvider>
            }
          />

          <Route
            path={routing.auth.signin}
            element={
              <Signin />
            }
          />

          <Route 
            path={routing.auth.signup}
            element={
              <Signup submitFormFromOutside={0} />
            }
          />

          <Route 
            path={routing.auth.forgotPassword}
            element={
              <ResetPassword />
            }
          />

          <Route 
            path={routing.scan.kit}
            element={<ScanKit />}
          />

          <Route 
            path={routing.scan.product}
            element={<ScanProduct />}
          />

          <Route 
            path={routing.desceaded.index}
            element={
              <AskAuthProvider>
                <PageProvider>
                  <AlertProvider>
                    <MyContainer disabled="xs">
                      <ProfilDeceased />
                    </MyContainer>
                  </AlertProvider>
                </PageProvider>
              </AskAuthProvider>
            }
          />

          <Route 
            path={routing.desceaded.post}
            element={
              <AskAuthProvider>
                <AlertProvider>
                  <Post /> 
                </AlertProvider>
              </AskAuthProvider>
            }
          />

          <Route 
            path={router.payment.success}
            element={<PaymentSuccess />}
          />

          <Route 
            path={router.payment.failed}
            element={<PaymentFailed />}
          />

          <Route 
            path={routing.desceaded.page}
            element={<PageCustomUrl />}
          />

          <Route 
            path={routing.order.index}
            element={
              <MyContainer>
                <ConsultProduct />
              </MyContainer>
            }
          />

          <Route 
            path={routing.shop.index}
            element={<Shop />}
          />

          <Route 
            path={routing.website.generalConditionOfUse}
            element={<GeneralConditionOfUse />}
          />

          <Route 
            path={routing.website.privacyPolicy}
            element={<PrivacyPolicy />}
          />

          <Route 
            path={routing.website.qrcode}
            element={<QrCodeView />}
          />
        </Routes>
      </LayoutUnAuth>
    </AskAuthProvider>
  );
};

export default UnAuthRoute;
