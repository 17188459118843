import {
  addDoc, 
  collection,
  DocumentReference,
  getDocs,
  query,
  Timestamp,
  where,
  doc,
  orderBy,
  limit,
  DocumentData,
  Query,
  startAfter,
  getDoc,
} from "firebase/firestore";
import React from "react";
import { collections, IDeath, IPost } from "type-absenso";
import { db } from "./config";
import { getIsLiked } from "./likes";
import {
  IDeathFront,
  IPostFront,
  IPostFrontWithDeceased
} from "./type/frontDocument";
import { getUser } from "./user";

export declare type IValueSortBy = "dateOfRemembrance" | "dateOfPublication"

const IpostToIpostFront = async (
  data: IPost<DocumentReference, Timestamp>,
  snapId: string,
  idUser: string | null,
) => {
  const user = await getUser({uid: data.user.id});
  const likeData = await getIsLiked(snapId, idUser);

  if (user) {
    return {
      ...data,
      id: snapId,
      userData: user,
      likeId: (likeData && likeData.likeId) || undefined,
      liked: (likeData && likeData.liked) || undefined
    };
  }
};

export const addPost = async (data: IPost<DocumentReference, Timestamp>) => {
  const postRef = collection(db, collections.post);
  await addDoc(postRef, data);
};

export const getPost = async (id: string, idUser: string | null) => {
  const postRef = doc(db, collections.post, id);
  const snap = await getDoc(postRef);
  const data = snap.data() as IPost<DocumentReference, Timestamp> | undefined;

  if (data) {
    return await IpostToIpostFront(data, snap.id, idUser); 
  }

  return undefined;
};

export const getUserPosts = async (
  lastData: IPost<DocumentReference, Timestamp> | undefined,
  id: string,
  idUser: string | null,
  setHasMore: React.Dispatch<React.SetStateAction<
    boolean
  >>
) => {
  const emptyArray: Array<IPostFrontWithDeceased> = [];
  const postRef = collection(db, collections.post);
  const limitQuery = 10;
  let q: Query<DocumentData>;

  if (lastData) {
    q = query(
      postRef,
      where("user", "==", doc(db, collections.users, id)),
      orderBy("date", "desc"),
      startAfter(lastData.date),
      limit(limitQuery)
    );
  } else {
    q = query(
      postRef,
      where("user", "==", doc(db, collections.users, id)),
      orderBy("date", "desc"),
      limit(limitQuery)
    );
  }

  const snaps = await getDocs(q);

  if (snaps.docs.length < limitQuery) {
    setHasMore(false);
  }

  for await (const snap of snaps.docs) {
    const data = snap.data() as IPost<DocumentReference, Timestamp> | undefined;

    if (data) {
      const dataFormat = await IpostToIpostFront(data, snap.id, idUser);
      let death:IDeathFront | undefined;
      if ([
        "placedBouquet",
        "buyProduct",
        "default"
      ].includes(data.type)) {
        const deathSnap = await getDoc(data.deceased);
        const deathData = deathSnap.data() as IDeath<
        DocumentReference, Timestamp
      > | undefined;
        if (deathData) {
          death = {
            ...deathData,
            id: deathSnap.id,
          };
        }
      }

      if (dataFormat) {
        emptyArray.push({
          ...dataFormat,
          death: death
        });
      }
    }
  }

  return emptyArray;
};

export const getPosts = async (
  id: string, 
  sortValue: IValueSortBy,
  hasMore: boolean,
  idUser: string | null,
  limitSnap: number,
  lastData: IPost<DocumentReference, Timestamp> | undefined,
) => {
  const emptyArray: Array<IPostFront> = [];
  const postRef = collection(db, collections.post);
  let q: Query<DocumentData>;
  if (hasMore) {
    if (sortValue === "dateOfRemembrance" && lastData) {
      q = query(
        postRef,
        where("deceased", "==", doc(db, collections.death, id)),
        orderBy("dateOfRemembrance", "desc"),
        startAfter(lastData.dateOfRemembrance),
        limit(limitSnap)
      );
    } else if (sortValue === "dateOfPublication" && lastData) {
      q = query(
        postRef,
        where("deceased", "==", doc(db, collections.death, id)),
        orderBy("date", "desc"),
        startAfter(lastData.date),
        limit(limitSnap)
      );
    } else if (sortValue === "dateOfRemembrance" && !lastData) {
      q = query(
        postRef,
        where("deceased", "==", doc(db, collections.death, id)),
        orderBy("dateOfRemembrance", "desc"),
        limit(limitSnap)
      );
    } else {
      q = query(
        postRef,
        where("deceased", "==", doc(db, collections.death, id)),
        orderBy("date", "desc"),
        limit(limitSnap)
      );
    }
    
    const snaps = await getDocs(q);

    for await (const snap of snaps.docs) {
      const data = snap.data() as IPost<DocumentReference, Timestamp>;
      const dataFormat = await IpostToIpostFront(data, snap.id, idUser);
      
      if (dataFormat) {
        emptyArray.push(dataFormat);
      }
    }
  }

  return emptyArray;
};