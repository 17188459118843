import React, { ReactNode, createContext, useEffect, useState } from "react";

// Types
import {
  INotificationWithId,
  INotificationFront
} from "../../backend/type/frontDocument";
import {
  getNotifications,
  getAllDataOfNotifications
} from "../../backend/notification";
import { getAuth } from "@firebase/auth";
import { app } from "../../backend/config";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";

export const NotificationsContext = createContext<
    null | {
        hasMore: boolean;
        notifs: Array<INotificationWithId> | undefined;
        completeNotifs: () => Promise<void>;
        notifComplete: Array<INotificationFront> | undefined;
        readNotif: (id: string) => void;
        getNotificationsHandler: () => Promise<void>;
          }
          >(null);

const NotificationsProvider = ({children}: {children: ReactNode}) => {
  const [lastDoc, setLastDoc] = useState<QueryDocumentSnapshot<DocumentData>>();
  const [notifs, setNotifs] = useState<
  Array<INotificationWithId>
  >();
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [notifComplete, setNotifComplete] = useState<
  Array<INotificationFront>
>();

  const user = getAuth(app).currentUser;

  const getNotificationsHandler = async () => {
    if (user) {
      const notifsSnap = await getNotifications(
        user.uid, lastDoc, setLastDoc, setHasMore
      );

      if (notifs) {
        setNotifs(notifs.concat(notifsSnap));

        if (notifComplete) {
          const notifData = await getAllDataOfNotifications(
            notifsSnap
          );

          setNotifComplete(notifComplete.concat(notifData));
        }
      } else {
        setNotifs(notifsSnap);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await getNotificationsHandler();
    })();
  }, []);

  const completeNotifs = async () => {
    if (notifs && !notifComplete) {
      const notifData = await getAllDataOfNotifications(
        notifs
      );

      if (notifs) {
        setNotifComplete(notifData);
      }
    }
  };

  const readNotif = (id: string) => {
    if (notifs && notifComplete) {
      setNotifComplete(
        notifComplete.map(el => {
          if (el.id === id) {
            return {...el, hasBeenSeen: true};
          }

          return el;
        })
      );
    }
  };

  return(
    <NotificationsContext.Provider value={{
      notifs,
      readNotif,
      completeNotifs,
      notifComplete,
      getNotificationsHandler,
      hasMore,
    }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;