import React from "react";
import {
  IDeathPage,
  IPostFront
} from "../../../../../../backend/type/frontDocument";
import {
  darkTurquoise,
  darkTurquoiseRgb,
  gold
} from "../../../../../../ui/color";
import { UserPicture } from "../../../../user/userPicture";
import { BtnPlaceBouquet } from "./btnPlaceBouquet";
import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { usePage } from "../../../../../../provider/deceased/hooks/usePage";
import { grey } from "@mui/material/colors";

export const PlacedBouquet = ({
  post,
  death,
}: {
    post: IPostFront;
    death: IDeathPage;
}) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const page = usePage();
  return(
    <Box sx={{
      backgroundColor: darkTurquoiseRgb,
      p: 1,
      borderRadius: {xs: "inherit", md: theme.spacing(1)},
      width: {md: "80%", xs: "100%"},
      minHeight: theme => theme.spacing(50),
      backgroundPositionY: "bottom",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${
        require("../../../../../../media/img/ground-of-flower.png")
      })`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      // eslint-disable-next-line max-len
      boxShadow: {md: `0px 4px 12px ${grey[500]}`, xs: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)"},
    }}>
      <UserPicture 
        user={post.userData}
        className="rounded_img"
        sx={{
          width: "100px",
          height: "100px",
          border: "3px solid #FFFFFF",
          boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 2,
          // eslint-disable-next-line max-len
          filter: "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.12))"
        }}
        sxIcon={{
          fontSize: "80px",
          color: darkTurquoise
        }}
      />

      <Box sx={{width: "50%", mb: 2}}>
        <Typography sx={{textAlign: "center"}}>
          <Typography component="span" sx={{fontWeight: "bold"}}>
            {`${post.userData.firstName} ${post.userData.name}`}
          </Typography>
          {t("postDepositFlower")}
          <Typography component="span" sx={{fontWeight: "bold"}}>
            {`${death.fullname}`}
          </Typography>
        </Typography>
      </Box>

      <BtnPlaceBouquet
        death={death}
        variant="contained"
        action={page && page.changeBouquetOwner}
        sx={{
          backgroundColor: gold,
          height: theme => theme.spacing(5)
        }}
      />
    </Box>
  );
};