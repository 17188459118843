/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, ReactNode } from "react";

// Ui
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { darkTurquoise } from "../../../../../../ui/color";
import {
  ButtonBase,
  Tooltip,
  Typography
} from "@mui/material";

export interface IPropsBtnPost {
    icon: IconDefinition,
    text: string,
    action: () => void,
    color?: string | false;
    componentTooltip?: ReactNode;
    width?: string;
}

export const BtnPost = ({
  icon, text, action, color, componentTooltip, width
}: IPropsBtnPost) => {

  const [open, setOpen] = useState<boolean>(false);
  
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const actionBtn = () => {
    if (componentTooltip) {
      setOpen(!open);
    }

    action();
  };

  const Template = () => {
    return(
      <ButtonBase
        onClick={actionBtn}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: width ? width : "33%"
        }} >
        <>
          <FontAwesomeIcon
            icon={icon}
            color={color || darkTurquoise}
            style={{fontSize: "12px"}}
          />
          <Typography sx={{
            color: color || darkTurquoise,
            ml: 1,
            fontWeight: 600,
            lineHeight: "12px",
            fontSize: "12px"
          }}>
            {text}
          </Typography>
        </>
      </ButtonBase>
    );
  };

  const BtnWithTooltip = () => {
    
    return(
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement="top"
        title={<>{componentTooltip && componentTooltip}</>}
      >
        <ButtonBase
          onClick={actionBtn}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "33%",
            height: "100%"
          }} >
          <>
            <FontAwesomeIcon
              icon={icon}
              color={color || darkTurquoise}
              style={{fontSize: "12px"}}
            />
            <Typography sx={{
              color: color || darkTurquoise,
              ml: 1,
              fontWeight: 600,
              lineHeight: "12px",
              fontSize: "12px"
            }}>
              {text}
            </Typography>
          </>
        </ButtonBase>
      </Tooltip>
    );
  };

  if (componentTooltip) {
    return (
      <BtnWithTooltip />
    );
  }

  return(
    <Template />
  );
};