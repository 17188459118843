import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ISignupForm } from "../../../forms/asset/auth/signup";
import ImageCropper from "../../imagecropper";
import { LayoutFlow } from "./layoutflow";

export const ChoosePicture = ({
  action,
  loadingAction,
  currentData,
}: {
  action: (crop: File | undefined) => Promise<void>;
  loadingAction: boolean | undefined;
  currentData: ISignupForm;
}) => {
  const {t} = useTranslation();
  const [urlCrop, setUrlCrop] = useState<string>();
  const [crop, setCrop] = useState<File>();
  return(
    <>
      <LayoutFlow 
        titleBtn={t("createAccount")}
        titleStep={t("step4Signup")}
        subtitleStep={t("subtitleStepFour")}
        actionBtn={async () => await action(crop)}
        loadingBtn={loadingAction}
        content={
          <>
            <ImageCropper 
              urlCrop={urlCrop}
              setUrlCrop={setUrlCrop}
              onCrop={(crop) => setCrop(crop)}
              defautlImg={currentData && currentData.profilePictures}
            />
          </>
        }
      />
    </>
  );
};