/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { IPostFront } from "../../../../../../backend/type/frontDocument";
import { Box, Typography, useTheme } from "@mui/material";
import { Comments } from "./comments";
import { useForm } from "react-hook-form";
import { ActionBar } from "./actionBar";
import { grey } from "@mui/material/colors";
import { UserPicture } from "../../../../user/userPicture";
import { useTranslation } from "react-i18next";
import {
  darkTurquoise,
  darkTurquoiseRgb,
  gold,
  grayDark
} from "../../../../../../ui/color";
import { CalendarMonth } from "@mui/icons-material";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DeleteBtn } from "../../../deleteBtn";

export const TemplateInteractivePost = ({
  post,
  openCollapseComment,
  deletePostHandler,
  canDeleted,
}: {
    post: IPostFront;
    openCollapseComment?: boolean;
    deletePostHandler?: () => Promise<void>;
    canDeleted?: boolean;
}) => {
  const form = useForm();
  const {t} = useTranslation();
  const theme = useTheme();
  const [openComment, setOpenComment] = useState<boolean>(
    Boolean(openCollapseComment)
  );

  return(
    <Box sx={{
      my: {md: 0, xs: 1},
      width: {md: "80%", xs: "100%"},
      // eslint-disable-next-line max-len
      boxShadow: {md: `0px 4px 12px ${grey[500]}`, xs: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)"},
      borderRadius: {xs: 0, md: theme.spacing(1)},
      height: "100%",
      position: "relative",
    }}>
      {deletePostHandler && <DeleteBtn
        isAdmin={canDeleted}
        deleteAction={deletePostHandler}
      />}

      {post.type === "pictures" && post.media && <img

        className="full_width around_top"
        src={post.media}
        alt="test"
      />}

      {post.type === "video" && post.media && <video
        width="100%"
        height="auto"
        controls
      >
        <source src={post.media} type="video/mp4" />
      </video>}

      <Box sx={{p: 1}}>
        <Box sx={{
          display: "flex",
        }}>
          <UserPicture
            user={post.userData}
            className="rounded_img"
            sx={{
              width: (theme: any) => theme.spacing(5),
              height: (theme: any) => theme.spacing(5),
              border: `1px solid ${gold}`,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
            sxIcon={{
              fontSize: (theme: any) => theme.spacing(4),
              color: darkTurquoise,
            }}
          />

          <Box sx={{ml: 1}}>
            <Typography sx={{
              fontWeight: 500,
              fontSize: theme => theme.spacing(2),
            }}>
              {`${post.userData.firstName} ${post.userData.name}`}
            </Typography>
            <Typography sx={{
              fontSize: theme => theme.spacing(1.5),
              color: grayDark
            }}>
              {t("postedOn", {
                date: post.date.toDate().toLocaleDateString("eu-FR")
              })}
            </Typography>
          </Box>
        </Box>

        <Box sx={{display: "flex", alignItems: "center", mt: 0.5}}>
          <Box sx={{
            width: (theme: any) => theme.spacing(5),
            height: (theme: any) => theme.spacing(5),
            display: "flex",
            alignItems: "center",
            justifyContent: "center" 
          }}>
            <CalendarMonth sx={{
              fontSize: theme => theme.spacing(2.5),
              color: grayDark
            }} />
          </Box>
          <Typography sx={{
            fontSize: theme => theme.spacing(1.5),
            color: grayDark
          }}>
            {t("memoryOf", {
              date: post.dateOfRemembrance.toDate().toLocaleDateString("eu-FR")
            })}
          </Typography>
        </Box>
      </Box>

      <Box sx={{
        backgroundColor: darkTurquoiseRgb,
        p: 1
      }}>
        <FontAwesomeIcon icon={faQuoteRight} style={{color: darkTurquoise}} />
        <Typography>
          {post.description}
        </Typography>
      </Box>

      <ActionBar 
        post={post}
        onClickComment={() => setOpenComment(!openComment)}
      />
      
      <Comments 
        idPost={post.id}
        openComment={openComment}
        form={form}
      />
    </Box>
  );
};