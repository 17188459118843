import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { gold } from "../../../../ui/color";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const ScanTheKit = ({
  idKit,
  deathname
}: {
  idKit?: string;
  deathname?: string;
}) => {
  const {t} = useTranslation();
  return(
    <Box>
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <CheckCircleIcon sx={{
          color: gold,
          fontSize: theme => theme.spacing(5)
        }} />

        <Typography sx={{
          ml: 1,
          fontSize: theme => theme.spacing(3),
          fontWeight: 400,
          lineHeight: theme => theme.spacing(4)
        }}>
          {t("kitLinkedSuccess", {idKit, deathname})}
        </Typography>
      </Box>

      <Typography sx={{
        textAlign: "center",
        mt: 2
      }}>
        {t("kitLinkedDescription")}
      </Typography>
    </Box>
  );
};