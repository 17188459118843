import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";

// Router
import { Params, useNavigate, useParams } from "react-router-dom";
import { routing } from "../../router";

// Hooks
import { useAlert } from "../../provider/error/hooks/useAlert";

// Backend
import { getKit } from "../../backend/kit";
import { getAuth } from "firebase/auth";
import { app, db } from "../../backend/config";
import { collections } from "type-absenso";
import { 
  Timestamp, 
  doc,
  increment,
  updateDoc, 
} from "firebase/firestore";
import { adduserScanned } from "../../backend/userScanned";

const ScanKit = () => {
  const id: Readonly<Params<string>> = useParams();
  const alert = useAlert();
  const {t} = useTranslation();
  const navigate = useNavigate();
  
  useEffect(() => {
    (async() => {
      try {
        const user = getAuth(app).currentUser;
        const data = await getKit({id: id.id as string});
      

        if (data && data.deceased) {
          const deceasedRef = doc(db, collections.death, data.deceased.id);
          
          await updateDoc(deceasedRef,{
            numberOfMemories: increment(1)
          });

          await adduserScanned({
            date: Timestamp.now(),
            death: data.deceased,
            userScanned:
              (user && doc(db, collections.users, user.uid)) || "unknown",
            type: "kit",
            thingScanned: doc(db, collections.kit, id.id as string)
          });

          navigate(`${routing.desceaded.index.replace(
            ":id", data.deceased.id
          )}`);

        } else if (data && !data.deceased) {
          if (alert && alert.createAlert) {
            alert.createAlert({
              type: "error",
              message: t("errorNoDeceased")
            });
          }
        } else {
          if (alert && alert.createAlert) {
            alert.createAlert({
              type: "error",
              message: t("idDoesntExist")
            });
          }
        }
      } catch (error) {
        alert.defaultError();
      }
    })();
  }, [id.id]);

  return(
    <></>
  );
};

export default ScanKit;
