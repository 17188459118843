import React, { useState } from "react";

// Form
import FormComponent from "../../forms/formcomponent";
import { useForm } from "react-hook-form";
import { checkoutForm } from "../../forms/asset/order/checkout";
import { IFormCheckout } from "./types";

// Ui
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IDataFrontOrderProduct } from "type-absenso";
import { IDataReceivePaymentOrder } from "type-absenso/lib/api/order";
import { DocumentReference, Timestamp } from "firebase/firestore";
import { IBasketProps } from "../../../views/order/types";
import { postAuth } from "../../../backend/utils/postAuth";
import { orderProduct } from "../../../backend/utils/url";
import { useAlert } from "../../../provider/error/hooks/useAlert";
import { LoadingButton } from "@mui/lab";

const OrderCheckout = ({
  total,
  deathId,
  basket,
}: {
  total: number;
  deathId: string;
  basket: Array<IBasketProps>;
}) => {
  const {t} = useTranslation();
  const {watch, control, formState, handleSubmit} = useForm();
  const alert = useAlert();

  const [loading, setLoading] = useState<boolean>();

  const onSubmit = async (data: IFormCheckout) => {
    setLoading(true);
    try {
      const products = basket.map(el => {
        return {
          product: el.product,
          qte: el.qte
        };
      });
  
      const dataToSend: IDataFrontOrderProduct<
        DocumentReference, Timestamp
      > = {
        ...data,
        date: Timestamp.now(),
        products: products,
        deathId,
        total,
      };
  
      const response = await postAuth(orderProduct, dataToSend);
      if (response) {
        const res = response.data as IDataReceivePaymentOrder;
        window.location.href = res.url;
      }
    } catch (error) {
      if (alert) {
        alert.defaultError();
      }
    }
    setLoading(false);
  };

  return(
    <Box sx={{
      width: "100%"
    }}>
      <FormComponent 
        control={control}
        watch={watch}
        formState={formState}
        arrayForms={checkoutForm}
      />

      <LoadingButton
        loading={loading}
        onClick={handleSubmit(onSubmit as never)}
        variant="contained"
        sx={{
          width: "100%",
          mt: 1
        }}>
        {t("pay")}
      </LoadingButton>
    </Box>
  );
};

export default OrderCheckout;