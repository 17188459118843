import {
  faHeart,
  faComment,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { Box, ClickAwayListener, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { IPostFront } from "../../../../../../backend/type/frontDocument";
import { Likes } from "./likes";
import { BtnPost } from "./btnPost";
import { Share } from "../../../share";
import { useState } from "react";

export const ActionBar = ({
  post,
  onClickComment,
}: {
    post: IPostFront;
    onClickComment: () => void;
}) => {
  const {t} = useTranslation();

  const [openTootltip, setOpentooltip] = useState<string>("");

  return(
    <Box sx={{
      width: "100%",
      borderRadius: theme => theme.spacing(1),
      height: theme => theme.spacing(5),
      display: "flex",
      py: 1,
    }}>
      <Likes
        icon={faHeart}
        text={t("aThought")}
        idPost={post.id}
        dataPost={post}
      />
      <BtnPost
        icon={faComment}
        text={t("comment")}
        action={() => onClickComment()}
      />

      <ClickAwayListener onClickAway={() => setOpentooltip("")}>
        <Box sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "33%"
        }}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            placement="top"
            open={openTootltip === post.id}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={<Share type="post" id={post.id} />}
            onClose={() => setOpentooltip("")}
          >
            <Box sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "33%"
            }}>
              <BtnPost
                icon={faShareNodes}
                text={t("share")}
                action={() => setOpentooltip(post.id)}
                width="100%"
              />
            </Box>
          </Tooltip>
        </Box>
      </ClickAwayListener>
    </Box>
  );
};