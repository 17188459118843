import React from "react";
import {
  FormDeceased,
  IDataFormDeceased
} from "../../../../deceased/formDeceased";
import { UseFormReturn, FieldValues } from "react-hook-form";
import { FlowContentTemplate } from "../flowContentTemplate";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { useMemo } from "react";

export const FlowCreateDeceased = ({
  form,
  onSubmit,
  modalFlowView,
  loading
}: {
    form: UseFormReturn<FieldValues, unknown>;
    onSubmit: (data: IDataFormDeceased) => void;
    modalFlowView?: boolean;
    loading?: boolean;
}) => {
  const {t} = useTranslation();

  const disableBtn = useMemo(() => {
    const data = form.watch() as IDataFormDeceased;

    return !data.firstName ||
      !data.name ||
      !data.address ||
      !data.dateOfBirth ||
      !data.dateOfDeath ||
      !data.hash ||
      !data.latitude ||
      !data.longitude;
  }, [form.watch()]);

  return(
    <FlowContentTemplate
      displayedBtn={true}
      nextAction={form.handleSubmit(onSubmit as never)}
      title={t("secondlyStep")}
      subtitle={t("titleFlowCreateDeceased")}
      disableNextBtn={disableBtn}
      loadingNext={loading}
    >
      <Box sx={{width: "100%"}}>
        <FormDeceased
          notDisplayedTitle={true}
          form={form}
          modalFlowView={modalFlowView}
        />
      </Box>
    </FlowContentTemplate>
  );
};