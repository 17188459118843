import { getAuth } from "firebase/auth";
import { useContext } from "react";
import { app } from "../../../backend/config";
import { AskAuthContext } from "../askAuthProvider";

export const useAskAuth = () => {
  const user = getAuth(app);
  const askAuth = useContext(AskAuthContext);

  const userIsConnected = () => {
    return Boolean(user.currentUser && user.currentUser.uid);
  };

  const openHandler = (params?: {[x: string]: string}) => {
    if (askAuth) {
      askAuth.openHandler(params);
    }
  };

  return {openHandler, userIsConnected};
};