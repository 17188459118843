import axios from "axios";

import { getAuth } from "firebase/auth";
import { getLang } from "../../i18n/i18n";
import {app} from "../config";

export const postAuth = async (
  url: string,
  data: unknown,
) => {

  const auth = getAuth(app);

  if (auth.currentUser) {
    const token = await auth.currentUser.getIdToken();
    const lang = getLang();
    
    return axios.post(
      url,
      JSON.stringify(data),
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
          lang: lang,
          mode: "cors",
          cache: "default",
        }
      });
  }
};
