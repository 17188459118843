import { IForm } from "../../../forms/types";

export const contactForm: Array<IForm> = [
  {
    type: "TextField",
    elementTextField: {
      label: "name",
      name: "name",
      id: "name",
      type: "text"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      label: "email",
      name: "email",
      id: "email",
      type: "text"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      label: "numberPhone",
      name: "phone",
      id: "phone",
      type: "phone"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      label: "yourQuestion",
      name: "question",
      id: "question",
      type: "text",
      multiline: true,
      rows: 5,
    }
  }
];
