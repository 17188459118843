import { Box, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { gold } from "../../../../../ui/color";

export const TemplateCard = ({
  children, title, icon, bg, noPadding
}: {
    children: ReactNode;
    title: string;
    icon: ReactNode;
    bg?: string;
    noPadding?: true;
}) => {
  return(
    <Box sx={{
      borderRadius: theme => theme.spacing(1),
      position: "relative",
      p: noPadding ? 0 : 2,
      py: {xs: 0, md: noPadding ? 0 : 2},
      backgroundColor: "white",
      // eslint-disable-next-line max-len
      boxShadow: {md: "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)", xs: "inherit"}
    }}>
      <Box sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: bg || "white",
        p: noPadding ? 2 : 0,
        borderRadius: theme => theme.spacing(1),
      }}>
        {icon}
        <Typography sx={{
          color: gold,
          ml: 2,
          fontWeight: 500,
          fontSize: theme => theme.spacing(3)
        }}>
          {title}
        </Typography>
      </Box>

      {children}
    </Box>
  );
};