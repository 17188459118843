import React, { useState } from "react";

// Form
import FormComponent from "../../components/forms/formcomponent";
import { signinForm } from "../../components/forms/asset/auth/signin";
import { useForm } from "react-hook-form";

// Ui
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import VisibilityIcon from "../../components/utils/auth/signin/visibilityIcon";

// I18n
import { useTranslation } from "react-i18next";

// Hooks
import { useAuth } from "../../provider/auth/hooks/useAuth";

// Router
import { Link } from "react-router-dom";
import { routing } from "../../router";
import { darkTurquoise, gold } from "../../ui/color";
import { ChevronRight } from "@mui/icons-material";
import { MyContainer } from "../../components/utils/myContainer";
import { OtherMethod } from "../../components/utils/auth/othermethod";
import { TemplateAuth } from "../../components/utils/auth/templateauth";

interface ISigninProps {
  modalFlowView?: boolean;
  toggleSignin?: () => void;
}

const Signin = ({
  modalFlowView,
  toggleSignin,
}: ISigninProps) => {
  const {watch, control, formState, handleSubmit} = useForm();
  const {t} = useTranslation();
  const auth = useAuth();
  const [visibility, setVisibility] = useState<boolean>(false);
  
  const [loading, setLoading] = useState<boolean>();
  
  const onSubmit = async (data: {email: string, password: string}) => {
    setLoading(true);
    if (auth) {
      await auth.signin(data.email, data.password);
    }
    setLoading(false);
  };
  
  const handleClickShowPassword = () => setVisibility((show) => !show);

  if (modalFlowView){
    return (
      <TemplateAuth
        notDisplayedBgImage={modalFlowView}
        content={
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "100%",
                  md: "48%",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  minHeight: 500,
                }}
              >
                <Box></Box>
                <Box>
                  <FormComponent
                    watch={watch}
                    control={control}
                    formState={formState}
                    arrayForms={signinForm(
                      visibility ? "text" : "password",
                      <VisibilityIcon
                        showPassword={handleClickShowPassword}
                        visibility={visibility}
                      />
                    )}
                  />

                  <Box
                    my={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        fontSize: (theme) => theme.spacing(2),
                        lineHeight: (theme) => theme.spacing(3),
                      }}
                      control={<Checkbox defaultChecked />}
                      label={t("stayConnected")}
                    />

                    <Link
                      to={routing.auth.forgotPassword}
                      style={{ textDecoration: "none" }}
                    >
                      <Button variant="text">{t("forgotPassword")}</Button>
                    </Link>
                  </Box>
                </Box>
                <LoadingButton
                  loading={loading}
                  onClick={handleSubmit(onSubmit as never)}
                  variant="text"
                  sx={{
                    width: "100%",
                    mt: 4,
                    color: "white",
                    backgroundColor: gold,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {t("connexion").toUpperCase()}
                  <ChevronRight />
                </LoadingButton>
              </Box>
            </Box>
            <Box
              sx={{
                width: {
                  xs: "100%",
                  md: "48%",
                },
              }}
            >
              <OtherMethod
                goal="connexion"
                action={() => true}
                isLandscape={true}
              />
              <Box
                mt={5}
                sx={{
                  textAlign: "center",
                }}
              >
                <Typography>{t("notAccountYet")}</Typography>
                <Button
                  sx={{ mt: 2, textTransform: "uppercase", color: gold }}
                  variant="text"
                  onClick={() => toggleSignin && toggleSignin()}
                >
                  {t("createAccount")}
                </Button>
              </Box>
            </Box>
          </Box>
        }
      />
    );
  }

  return(
    <TemplateAuth 
      notDisplayedBgImage={modalFlowView}
      content={
        <Box sx={{
          flex: modalFlowView ? "" : "1 1 0px",
          width: modalFlowView ? "100%" : {md: "50%", xs: "100%"},
          py: modalFlowView ? 0 : 6,
        }}>
          <MyContainer maxWidth={modalFlowView ? "xl" : "sm"} sx={{
            minHeight: modalFlowView ? "auto" : "100vh",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column"
          }}>
            <Box sx={{
              pb: 2,
            }}>
              <Typography
                variant={"title" as never}
                sx={{
                  color: darkTurquoise
                }}
              >
                {t("titleSignin")}
              </Typography>
              <Typography
                sx={{
                  color: gold,
                  pt: 2
                }}
                variant={"subtitle" as never}
              >
                {t("subtitleSignin")}
              </Typography>
            </Box>
                
            <OtherMethod goal="connexion" action={() => true} />

            <Box>
              <FormComponent 
                watch={watch}
                control={control}
                formState={formState}
                arrayForms={signinForm(
                  visibility ? "text" : "password",
                  (
                    <VisibilityIcon 
                      showPassword={handleClickShowPassword} 
                      visibility={visibility}
                    />
                  )
                )}
              />
              <Box my={1} sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
                <FormControlLabel
                  sx={{
                    fontSize: theme => theme.spacing(2),
                    lineHeight: theme => theme.spacing(3)
                  }}
                  control={<Checkbox defaultChecked />}
                  label={t("stayConnected")}
                />
  
                <Link 
                  to={routing.auth.forgotPassword} 
                  style={{textDecoration: "none"}}
                >
                  <Button variant="text">
                    {t("forgotPassword")}
                  </Button>
                </Link>
              </Box>
            </Box>
  
            <LoadingButton
              loading={loading}
              onClick={handleSubmit(onSubmit as never)}
              variant="contained"
              sx={{
                width: "100%",
                mt: 4,
                backgroundColor: gold,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              {t("connexion")}
              <ChevronRight />
            </LoadingButton>
          
            <Box mt={5} sx={{
              textAlign: "center"
            }}>
              <Typography>
                {t("notAccountYet")}
              </Typography>
              <Link to={routing.auth.signup} style={{textDecoration: "none"}}>
                <Button
                  sx={{mt: 2, textTransform: "uppercase", color: gold}}
                  variant="text"
                >
                  {t("createAccount")}
                </Button>
              </Link>
            </Box>
          </MyContainer>
        </Box>
      }
    />
  );
};

export default Signin;
