import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FlowContentTemplate } from "../flowContentTemplate";
import { IOfferData, offersData } from "type-absenso";
import {
  Typography,
  ButtonBase,
  ListItem,
  ListItemText,
  List
} from "@mui/material";
import { Box } from "@mui/material";
import {
  backgroundOpacity,
  darkTurquoise,
  gold,
  grayDark
} from "../../../../../../ui/color";
import {
  IParamsSubscribeOffer,
  useOffer
} from "../../../../../../views/order/offers/hooks/useOffer";
import { IDataFormDeceased } from "../../../../deceased/formDeceased";
import { CheckCircle } from "@mui/icons-material";
import { useMemo } from "react";

export const FlowChooseOffer = ({
  offerHandler,
  deathData,
  previousAction,
}: {
  offerHandler: (subscribeOffer: (
    data: IParamsSubscribeOffer
  ) => Promise<void>, offer: IOfferData) => Promise<void>;
  deathData: IDataFormDeceased;
  previousAction: () => void;
}) => {
  const {t} = useTranslation();

  const [currentOffer, setCurrentOffer] = useState<string>();
  const [currentDetailOffer, setCurrentDetailOffer] = useState<string>();
  const [loadingNext, setLoadingNext] = useState<boolean>();

  const { subscribeToOffer } = useOffer(currentOffer);

  const offerChooseHandler = (uid: string) => {
    setCurrentOffer(uid);
  };

  const currentDataDetailOffer = useMemo(() => {
    if (currentDetailOffer) {
      setCurrentOffer(undefined);
      return offersData.find(od => od.uid === currentDetailOffer);
    }
  }, [currentDetailOffer]);

  const offerFinalHandler = async () => {
    setLoadingNext(true);
    if (currentOffer) {
      const offer = offersData.find(od => od.uid === currentOffer);

      if (offer) {
        await offerHandler(subscribeToOffer,  offer);
      }
    }
    setLoadingNext(false);
  };

  const detailOfferHandler = (uid: string) => {
    setCurrentDetailOffer(uid);
  };

  const previousHandler = () => {
    if (currentDataDetailOffer) {
      setCurrentDetailOffer(undefined);
    } else {
      previousAction();
    }
  };

  return(
    <FlowContentTemplate 
      title={t("fourthStep")}
      subtitle={t("titleChooseOffer", {
        fullname: `${deathData.firstName} ${deathData.name}`
      })}
      displayedBtn
      disableNextBtn={currentOffer === undefined}
      nextAction={offerFinalHandler}
      previousAction={previousHandler}
      loadingNext={loadingNext}
    > 
      {currentDataDetailOffer ? <Box sx={{
        background: backgroundOpacity,
        p: 2,
        width: "100%",
        borderRadius: theme => theme.spacing(1),
      }}>
        <Typography 
          sx={{
            fontWeight: 300,
            fontSize: theme => theme.spacing(1.5),
            lineHeight: theme => theme.spacing(2),
            color: grayDark,
            mb: 0.5,
          }}>
          {t("offerTitle")}
        </Typography>

        <Typography sx={{
          fontWeight: 400,
          fontSize: theme => theme.spacing(2.5),
          lineHeight: theme => theme.spacing(3),
          color: darkTurquoise,
          mb: 0.5
        }}>
          {t(currentDataDetailOffer.version)}
        </Typography>
        <Typography sx={{
          color: gold,
          fontWeight: 400,
          fontSize: theme => theme.spacing(2.5),
          lineHeight: theme => theme.spacing(3),
        }}>
          {`${currentDataDetailOffer.price}€`}
        </Typography>

        <List>
          {currentDataDetailOffer.items.map((el, i) => (
            <ListItem key={i}>
              <ListItemText sx={{
                color: darkTurquoise
              }}>
                {t(el)}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Box> : 
        <Box sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between"
        }}>
          {offersData.slice(0, 3).map((od, i) => (
            <Box key={i} 
              onClick={() => offerChooseHandler(od.uid)}
              sx={{
                cursor: "pointer",
                width: "33%",
                mx: 1,
                minHeight: theme => theme.spacing(20),
                p: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: theme => theme.spacing(1),
                position: "relative",
                // eslint-disable-next-line max-len
                border: `${od.uid === currentOffer ? "1px" : "1px"} solid ${od.uid === currentOffer ? gold : darkTurquoise}`
              }}>
              {
                od.uid === currentOffer && <Box sx={{
                  position: "absolute",
                  top: 3,
                  right: 3
                }}>
                  <CheckCircle sx={{
                    color: gold
                  }} />
                </Box>
              }

              <Typography 
                sx={{
                  fontWeight: 300,
                  fontSize: theme => theme.spacing(1.5),
                  lineHeight: theme => theme.spacing(2),
                  color: grayDark,
                  mb: 0.5,
                }}>
                {t("offerTitle")}
              </Typography>
              <Typography sx={{
                fontWeight: 400,
                fontSize: theme => theme.spacing(2.5),
                lineHeight: theme => theme.spacing(3),
                color: darkTurquoise,
                mb: 2
              }}>
                {t(od.version)}
              </Typography>

              <ButtonBase 
                onClick={() => detailOfferHandler(od.uid)}
                sx={{
                  p: 0.5,
                  borderRadius: theme => theme.spacing(0.3)
                }}>
                <Typography sx={{
                  fontSize: theme => theme.spacing(1.2),
                  color: gold
                }}>
                  {t("seeDetailOffer")}
                </Typography>
              </ButtonBase>
            </Box>
          ))}
        </Box>}
    </FlowContentTemplate>
  );
};