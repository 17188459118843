import React, { useState } from "react";
import { BtnPost, IPropsBtnPost } from "./btnPost";

// Backend
import { getAuth } from "firebase/auth";
import { app, db } from "../../../../../../backend/config";
import { addLike } from "../../../../../../backend/likes";
import { Timestamp, doc } from "firebase/firestore";

// Hooks
import { useAlert } from "../../../../../../provider/error/hooks/useAlert";

// Types
import { collections } from "type-absenso";
import { gold } from "../../../../../../ui/color";
import { IPostFront } from "../../../../../../backend/type/frontDocument";
import { useAskAuth } from "../../../../../../provider/auth/hooks/useAskAuth";


interface IPropsLikes extends Omit<IPropsBtnPost, "action"> {
    idPost: string;
    dataPost: IPostFront;
    fake?: boolean;
}

export const Likes = ({
  icon,
  text,
  idPost,
  fake,
  dataPost,
}: IPropsLikes) => {
  const alert = useAlert();
  const askAuth = useAskAuth();

  const [likeId, setLikeId] = useState<string | undefined>(
    dataPost.likeId
  );
  const [liked, setLiked] = useState<boolean | undefined>(
    dataPost.liked
  );

  const likeHandler = async () => {
    const user = getAuth(app).currentUser;
    if (user) {
      try {
        setLiked(!liked);
        await addLike(
          idPost,
          {
            user: doc(db, collections.users, user.uid),
            date: Timestamp.now()
          },
          liked,
          likeId,
          setLikeId,
        );
      } catch (error) {
        if (alert) {
          alert.defaultError();
        }
      }
    } else {
      askAuth && askAuth.openHandler();
    }
  };

  return(
    <>
      <BtnPost
        icon={icon}
        text={text}
        action={() => {
          if (!fake) {
            likeHandler();
          }
        }}
        color={liked && gold}
      />
    </>
  );
};