import React from "react";
import { Box, Typography } from "@mui/material";
import FormComponent from "../../../forms/formcomponent";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { checkoutForm } from "../../../forms/asset/order/checkout";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useEffect } from "react";

export const AddressForm = ({
  form,
  modalFlowView,
}: {
  form: UseFormReturn<FieldValues, unknown>
  modalFlowView?: boolean;
}) => {
  const {t} = useTranslation();

  useEffect(() => {
    form.setValue("sameDeliveryAndBilling", true);
  }, []);

  const displayedBillingForm = useMemo(() => {
    if (form.watch("sameDeliveryAndBilling")) {
      return true;
    }

    return false;
  }, [form.watch("sameDeliveryAndBilling")]);

  if(modalFlowView) {
    return (
      <>
        <Box sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row"
          },
          justifyContent: "space-between"
        }}>
          <Box sx={{mt: 2, width: {
            xs: "100%",
            md: "47%"
          }}}>
            <Typography variant={"subtitleOffer" as never} sx={{
              fontWeight: 700,
              textTransform: "uppercase",
              mb: 2
            }}>
              {t("baseInformation")}
            </Typography>

            <FormComponent 
              arrayForms={checkoutForm.slice(0, 4)}
              watch={form.watch}
              control={form.control}
              formState={form.formState}
            />
          </Box>

          <Box sx={{mt: 2, width: {
            xs: "100%",
            md: "47%"
          }}}>
            <Typography variant={"subtitleOffer" as never} sx={{
              fontWeight: 700,
              textTransform: "uppercase",
              mb: 2
            }}>
              {t("deliveryAddress")}
            </Typography>

            <FormComponent 
              arrayForms={checkoutForm.slice(4, !displayedBillingForm ? 8 : 9)}
              watch={form.watch}
              control={form.control}
              formState={form.formState}
            />
          </Box>
        </Box>
        {!displayedBillingForm && <Box sx={{mt: 2}}>
          <Typography variant={"subtitleOffer" as never} sx={{
            fontWeight: 700,
            textTransform: "uppercase",
            mb: 2
          }}>
            {t("billingAddress")}
          </Typography>

          <FormComponent 
            arrayForms={checkoutForm.slice(9, 13)}
            watch={form.watch}
            control={form.control}
            formState={form.formState}
          />

          <FormComponent 
            arrayForms={checkoutForm.slice(8, 9)}
            watch={form.watch}
            control={form.control}
            formState={form.formState}
          />
        </Box>}
      </>
    );
  }


  return(
    <>
      <Box sx={{mt: 2}}>
        <Typography variant={"subtitleOffer" as never} sx={{
          fontWeight: 700,
          textTransform: "uppercase",
          mb: 2
        }}>
          {t("baseInformation")}
        </Typography>

        <FormComponent 
          arrayForms={checkoutForm.slice(0, 4)}
          watch={form.watch}
          control={form.control}
          formState={form.formState}
        />
      </Box>

      <Box sx={{mt: 2}}>
        <Typography variant={"subtitleOffer" as never} sx={{
          fontWeight: 700,
          textTransform: "uppercase",
          mb: 2
        }}>
          {t("deliveryAddress")}
        </Typography>

        <FormComponent 
          arrayForms={checkoutForm.slice(4, !displayedBillingForm ? 8 : 9)}
          watch={form.watch}
          control={form.control}
          formState={form.formState}
        />
      </Box>

      {!displayedBillingForm && <Box sx={{mt: 2}}>
        <Typography variant={"subtitleOffer" as never} sx={{
          fontWeight: 700,
          textTransform: "uppercase",
          mb: 2
        }}>
          {t("billingAddress")}
        </Typography>

        <FormComponent 
          arrayForms={checkoutForm.slice(9, 13)}
          watch={form.watch}
          control={form.control}
          formState={form.formState}
        />

        <FormComponent 
          arrayForms={checkoutForm.slice(8, 9)}
          watch={form.watch}
          control={form.control}
          formState={form.formState}
        />
      </Box>}
    </>
  );
};