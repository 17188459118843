import { LoadingButton } from "@mui/lab";
import { Grid, Box, Typography } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IDataFrontAddAdmin } from "type-absenso";
import { IDeathFront, IUserFront } from "../../../backend/type/frontDocument";
import { postAuth } from "../../../backend/utils/postAuth";
import { addAdminUrl } from "../../../backend/utils/url";
import { DeceasedItem, SearchDeceased }
  from "../../../components/utils/deceased/searchDeceased";
import { SearchUser, UserItem }
  from "../../../components/utils/user/searchUser";
import { useAlert } from "../../../provider/error/hooks/useAlert";
import { backgroundOpacity, blueFrame, gold } from "../../../ui/color";
import LayoutActionParameter from "../../layout/layoutActionParameter";

const AddAdmin = () => {
  const {t} = useTranslation();
  const alert = useAlert();
  const [loading, setLoading] = useState<boolean>();
  const [selectedDeath, setSelectedDeath] = useState<IDeathFront>();
  const [selectedUser, setSelectedUser] = useState<IUserFront>();

  const onSubmit = async () => {
    if (selectedUser && selectedDeath) {
      setLoading(true);
      try {
        const dataToSend: IDataFrontAddAdmin<Timestamp> = {
          idDeath: selectedDeath.id,
          idUser: selectedUser.id
        };
        await postAuth(addAdminUrl, dataToSend);
        setSelectedUser(undefined);
        setSelectedDeath(undefined);
      } catch (error) {
        alert.defaultError();
      }
      setLoading(false);
    }
  };

  return(
    <>
      <LayoutActionParameter
        title={t("addAdminTitle")}
        btn={
          <LoadingButton 
            disabled={!selectedUser || !selectedDeath}
            loading={loading}
            onClick={onSubmit}
            sx={{
              height: "fit-content",
              color: "white",
              backgroundColor: gold
            }}
            variant="contained"
          >
            {t("addAdminBtn")}  
          </LoadingButton>
        }
      >
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Box sx={{
              width: "100%",
              p: selectedDeath ? 0 : 3,
              border: selectedDeath ? "inherit" : `1px solid ${blueFrame}`,
              borderRadius: theme => theme.spacing(0.5),
              backgroundColor: backgroundOpacity,
              mb: 1,
              display: "flex",
              justifyContent: "center"
            }}>
              {selectedDeath ? <DeceasedItem death={selectedDeath} /> :
                <Typography sx={{
                  color: blueFrame,
                  fontSize: theme => theme.spacing(2),
                  lineHeight: theme => theme.spacing(3)
                }}>
                  {t("notDeceasedChoose")}
                </Typography>}
            </Box>

            <SearchDeceased
              backgroundColor={backgroundOpacity}
              paddingContainer={2}
              action={(death) => setSelectedDeath(death)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Box sx={{
              width: "100%",
              p: selectedUser ? 0 : 3,
              border: selectedUser ? "inherit" : `1px solid ${blueFrame}`,
              borderRadius: theme => theme.spacing(0.5),
              backgroundColor: backgroundOpacity,
              mb: 1,
              display: "flex",
              justifyContent: "center"
            }}>
              {selectedUser ? <UserItem user={selectedUser} /> :
                <Typography sx={{
                  color: blueFrame,
                  fontSize: theme => theme.spacing(2),
                  lineHeight: theme => theme.spacing(3)
                }}>
                  {t("notUserChoose")}
                </Typography>}
            </Box>
            <SearchUser 
              backgroundColor={backgroundOpacity}
              paddingContainer={2}
              actionHandler={(user) => setSelectedUser(user)}
            />
          </Grid>
        </Grid>
      </LayoutActionParameter>
    </>
  );
};

export default AddAdmin;
