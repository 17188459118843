import {
  addDoc,
  collection,
  DocumentReference,
  Timestamp
} from "firebase/firestore";
import { collections, IScanReporting } from "type-absenso";
import { db } from "./config";

export const adduserScanned = async (data: IScanReporting<
        DocumentReference, Timestamp
    >) => {
  const userScannedRef = collection(db, collections.userScanned);
  await addDoc(userScannedRef, data);
};