import React, { ReactNode } from "react";
import { SxProps, Theme, Box } from "@mui/material";
import { IDeathFront } from "../../../../../backend/type/frontDocument";
import { AddPhotoAlternateRounded } from "@mui/icons-material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ImageCropper from "../../../imagecropper";
import { LoadingButton } from "@mui/lab";
import { useMemo } from "react";
import { gold } from "../../../../../ui/color";
import { collections, IDataFrontUpdateDeath } from "type-absenso";
import { postAuth } from "../../../../../backend/utils/postAuth";
import { updateDeathUrl } from "../../../../../backend/utils/url";
import { useAlert } from "../../../../../provider/error/hooks/useAlert";
import { uploadFile } from "../../../../../backend/utils/uploadFile";

export const CoverPicture = ({
  picture,
  sxIcon,
  sx,
  children,
  isAdmin,
  idDeath
}: {
    picture: IDeathFront["coverPicture"];
    sxIcon?: SxProps<Theme>;
    sx?: SxProps<Theme>;
    children?: ReactNode;
    isAdmin?: boolean;
    idDeath: string;
}) => {
  const {t} = useTranslation();
  const alert = useAlert();
  const [crop, setCrop] = useState<File>();
  const [urlCrop, setUrlCrop] = useState<string>();
  const [loading, setLoading] = useState<boolean>();

  const currentPicture = useMemo(() => {
    if (urlCrop) {
      return urlCrop;
    } else if (picture) {
      return picture;
    }
  }, [picture, urlCrop]);

  const changeSubmit = async () => {
    if (crop) {
      setLoading(true);
      try {
        const url = await uploadFile({
          file: crop,
          collection: collections.users
        });
        const dataToSend: IDataFrontUpdateDeath = {
          type: "coverPicture",
          idDeath: idDeath,
          newData: url
        };
        await postAuth(updateDeathUrl, dataToSend);
        setCrop(undefined);
      } catch (error) {
        alert.defaultError();
      }
      setLoading(false);
    }
  };

  const EditCoverPicture = () => {
    return <>
      <ImageCropper 
        sxContainer={{
          position: "absolute",
          top: 0,
          height: "100%",
          width: "100%",
          backgroundColor: "rgba(1, 1, 1, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          borderTopLeftRadius: (theme: any) => {
            return {xs: 0, md: theme.spacing(1)};
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          borderTopRightRadius: (theme: any) => {
            return {md: theme.spacing(1), xs: 0};
          },
        }}
        customComponent={<Box
          sx={{
            width: "100%",
            height: "100%",
          }}>
          {crop ? <LoadingButton
            onClick={changeSubmit}
            loading={loading}
            variant="contained" 
            sx={{
              backgroundColor: gold
            }}>
            {t("validateChange")}
          </LoadingButton> :<AddPhotoAlternateRounded sx={sxIcon} />}
        </Box>}
        onCrop={(crop) => setCrop(crop)}
        urlCrop={urlCrop}
        setUrlCrop={setUrlCrop}
        aspectRatio={3}
      />
    </>;
  };

  if (currentPicture) {
    return (
      <Box sx={{
        ...sx,
        backgroundImage: `url(${currentPicture})`
      }} >
        {isAdmin && <EditCoverPicture />}
        {children && children}
      </Box>
    );
  }

  return(
    <Box sx={{...sx}}>
      {isAdmin && <EditCoverPicture />}
      {!isAdmin && <AddPhotoAlternateRounded sx={sxIcon} />}
      {children && children}
    </Box>
  );
};