import { useTranslation } from "react-i18next";
import { useAskAuth } from "../../../../../provider/auth/hooks/useAskAuth";
import { useAlert } from "../../../../../provider/error/hooks/useAlert";
import { addBouquet } from "../../../../../backend/bouquet";
import { useState } from "react";
import { IDeathPage } from "../../../../../backend/type/frontDocument";
import { useUser } from "../../../../../provider/auth/hooks/useUser";

export const usePlaceBouquet = (
  death: IDeathPage,
  action?: () => void,
) => {
  const {t} = useTranslation();
  const user = useUser();
  const askAuth = useAskAuth();
  const alert = useAlert();

  const [loading, setLoading] = useState<boolean>(false);

  const placeBouquetHandler = async () => {
    if (user) {
      setLoading(true);
      await addBouquet(death.id, user.id);
      action && action();
      if (alert && alert.createAlert) {
        alert.createAlert({
          type: "success",
          message: t("successPutBouquet")
        });
      }
      setLoading(false);
    } else {
      askAuth && askAuth.openHandler();
    }
  };

  return {
    addBouquet: placeBouquetHandler,
    loading,
  };
};