import React, { ReactNode } from "react";
import {
  Container,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme
} from "@mui/material";

export const MyContainer = ({children, sx, maxWidth, disabled}: {
    children: ReactNode;
    sx?: SxProps<Theme>;
    maxWidth?: "xl" | "sm";
    disabled?: "xs" | "md";
}) => {
  const theme = useTheme();
  return(
    <Container
      sx={sx}
      maxWidth={maxWidth || "lg"}
      component="div"
      disableGutters={disabled && useMediaQuery(
        theme.breakpoints.only(disabled)
      )}
    >
      {children}
    </Container>
  );
};