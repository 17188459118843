import React from "react";
import { Grid, Box, Typography, useTheme } from "@mui/material";
import { backgroundOpacity, gold } from "../../../../ui/color";
import { SummaryOffer } from "./summaryoffer";
import { IOfferData } from "type-absenso";
import { useTranslation } from "react-i18next";
import { ReactNode } from "react";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

export const BtnOfferContent = ({
  action,
  loading,
  disableBtn,
  titleBtn,
  activatePrevious,
  previousAction,
}: {
  titleBtn: string,
  disableBtn?: boolean,
  loading?: boolean,
  action: () => void,
  activatePrevious?: boolean;
  previousAction?: () => void;
}) => {
  const {t} = useTranslation();
  const activePreviousStyle = (
    style: string | number, returnStyle?: string
  ) => {
    if (activatePrevious) {
      return style;
    } else {
      if (returnStyle) {
        return returnStyle;
      }

      return "inherit";
    }
  };

  const theme = useTheme();

  return(
    <Box sx={{
      display: activePreviousStyle("flex"),
      justifyContent: activePreviousStyle("space-between"),
      alignItems: activePreviousStyle("center"),
    }}>
      {activatePrevious && <LoadingButton
        variant="outlined"
        onClick={previousAction}
        loading={false}
        sx={{
          borderColor: gold,
          color: gold,
          height: theme => activePreviousStyle(theme.spacing(5))
        }}
      >
        {t("previous")}
      </LoadingButton>}

      <LoadingButton
        variant="contained"
        onClick={action}
        loading={loading}
        disabled={disableBtn}
        sx={{
          backgroundColor: gold,
          height: activePreviousStyle(theme.spacing(5), theme.spacing(6)),
          width: activePreviousStyle("inherit", "100%")
        }}
      >
        {titleBtn}
      </LoadingButton>
    </Box>
  );
};

export const TemplateOffer = ({
  offer,
  titleAction,
  subtitleAction,
  loadingAction,
  action,
  children,
  loading,
  offerExist,
  titleBtn,
  disableBtn,
  hideBtn,
}: {
    offer: IOfferData,
    titleAction: string,
    subtitleAction: string,
    loadingAction: boolean,
    action: () => void,
    children: ReactNode,
    loading?: boolean,
    offerExist: boolean,
    titleBtn: string,
    disableBtn?: boolean,
    hideBtn?: boolean,
}) => {
  const {t} = useTranslation();

  return(
    <Grid 
      spacing={2}
      container 
      sx={{
        minHeight: "100vh"
      }}>
      <Grid
        item
        md={4}
        xs={12}
        order={{ xs: 2, md: 1 }}
        sx={{
          minHeight: {md: "100vh", xs: "inheriht"},
          display: "flex",
          justifyContent: "center"
        }}>
        <Box sx={{
          width: "100%",
          p: {xs: 2, md: 4}
        }}>
          <SummaryOffer 
            offer={offer}
            titleAction={titleAction}
            subtitleAction={subtitleAction}
            loading={loadingAction}
            action={action}
            disableBtn={!offerExist || (disableBtn || false)}
            titleBtn={titleBtn}
            hideBtn={hideBtn}
          />
        </Box>
      </Grid>
      <Grid
        item
        md={8}
        xs={12}
        order={{ xs: 1, md: 2 }}
        sx={{
          backgroundColor: backgroundOpacity,
        }}>
        {loading ? <Box sx={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex"
        }}>
          <Close sx={{
            fontSize: theme => theme.spacing(20),
            color: gold
          }} />
        </Box> :<>
          <Box sx={{
            p: {xs: 2, md: 4},
            display: {xs: "block", md: "none"}
          }}>
            <Typography 
              variant={"titleOffer" as never}
              sx={{pb: 2}}
            >{t(titleAction)}</Typography>
            <Typography variant={"subtitleOffer" as never}>
              {t(subtitleAction)}
            </Typography>
          </Box>
          <Box sx={{p: {xs: 2, md: 4}}}>
            {children}
          </Box>
        </>}
      </Grid>
    </Grid>
  );
};