import { useContext } from "react";
import { AuthContext } from "../authprovider";

export const useUser = () => {
  const authData = useContext(AuthContext);

  if (authData) {
    return authData.userData;
  }

  return undefined;
};