import { createTheme } from "@mui/material";

// Color
import { darkTurquoise, gold, grayDark } from "./color";
import { grey } from "@mui/material/colors";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: darkTurquoise,
    }
  }
});

export const customTheme = createTheme(theme, {
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#f2f3f3",
          color: darkTurquoise,
          boxShadow: `1.2px 1.2px 1.6px ${grey[500]}`
        }
      }
    },
    MuiTypography: {
      variants: [
        {
          props: {variant: "default"},
          style: {
            fontFamily: "Raleway"
          }
        },
        {
          props: {variant: "subtitleSearch"},
          style: {
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "20px",
            letterSpacing: "0.4px",
            color: grayDark
          }
        },
        {
          props: {variant: "title"},
          component: "h1",
          style: {
            color: darkTurquoise,
            fontWeight: 400,
            fontSize: theme.spacing(6),
            lineHeight: theme.spacing(8)
          }
        },
        {
          props: {variant: "titleStep"},
          style: {
            fontWeight: 400,
            fontSize: theme.spacing(2.5),
            lineHeight: theme.spacing(2.5)
          }
        },
        {
          props: {variant: "titleWebsite"},
          component: "h4",
          style: {
            color: "white",
            fontWeight: "bold",
            fontSize: "28px",
            lineHeight: "37px",
            textAlign: "center"
          }
        },
        {
          props: {variant: "subtitle"},
          style: {
            color: "white",
            fontWeight: 400,
            fontSize: "22px",
            lineHeight: "25px",
          }
        },
        {
          props: {variant: "pageTitle"},
          component: "h4",
          style: {
            fontSize: "16px",
            lineHeight: "20px",
            fontWeight: 400,
            color: gold
          }
        },
        {
          props: {variant: "pageSubtitle"},
          component: "h6",
          style: {
            fontSize: "28px",
            lineHeight: "37px",
            fontWeight: 500,
            color: darkTurquoise,
            textAlign: "center"
          }
        },
        {
          props: {variant: "titleOffer"},
          component: "h3",
          style: {
            fontWeight: 600,
            fontSize: theme.spacing(5),
            lineHeight: theme.spacing(6),
          }
        },
        {
          props: {variant: "subtitleOffer"},
          component: "h6",
          style: {
            color: grayDark,
            fontSize: theme.spacing(2),
            lineHeight: theme.spacing(3)
          }
        }
      ],
      defaultProps: {
        variant: "default",
        component: "p",
      }
    },
    MuiButton: {
      variants: [
        {
          props: {variant: "contained"},
          style: {
            backgroundColor: darkTurquoise,
            height: theme.spacing(6),
            "&:hover": {
              background: "#002e3b",
            },
          }
        },
        {
          props: {variant: "containedWithoutHeight"},
          style: {
            color: "white",
            backgroundColor: darkTurquoise,
            "&:hover": {
              background: "#002e3b",
            },
          }
        },
        {
          props: {variant: "outlined"},
          style: {
            borderColor: darkTurquoise,
            color: darkTurquoise,
            height: theme.spacing(6),
            "&:hover": {
              background: "#002e3b",
              color: "#fff"
            },
          }
        },
        {
          props: {variant: "outlinedGold"},
          style: {
            border: `1px solid ${gold}`,
            color: gold,
            height: theme.spacing(6),
          }
        },
        {
          props: {variant: "gold"},
          style: {
            backgroundColor: gold,
            color: "white",
            border: `1px solid ${gold}`,
            "&:hover": {
              color: gold,
              backgroundColor: "white",
            }
          }
        },
        {
          props: {variant: "text"},
          style: {
            color: darkTurquoise,
            textTransform: "inherit",
            fontSize: theme.spacing(2),
            lineHeight: theme.spacing(3),
            "&:hover": {
              background: "none",
            },
          }
        },
        {
          props: {variant: "flatBtn"},
          style: {
            width: "160px",
            height: "32px"
          }
        }
      ]
    }
  }
});