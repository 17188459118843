import { Autocomplete, Box } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IAssetAutoCompleteField } from "../../forms/types";
import { backgroundOpacity, grayDark } from "../../../ui/color";
import { Search } from "@mui/icons-material";
import { searchFuneralCompany } from "../../../backend/funeralCompany";
import { IFuneralCompanyFront } from "../../../backend/type/frontDocument";
import {
  FuneralCompanyItem
} from "../../../components/utils/deceased/funeralCompanyItem";

export const SearchFuneralCompany = ({
  onSelect,
  backgroundInput,
}: {
  onSelect: (company: IFuneralCompanyFront) => void;
  backgroundInput?: string;
}) => {
  const {t} = useTranslation();

  const [valueTextField, setValueTextField] = useState<string>();
  const [options, setOptions] = useState<
    Array<IAssetAutoCompleteField<IFuneralCompanyFront>>
  >();
  const [loading, setLoading] = useState<boolean>();

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    setValueTextField(e.target.value);
    if (e.target.value) {
      const companies = await searchFuneralCompany(e.target.value);
      const newOptions: Array<
            IAssetAutoCompleteField<IFuneralCompanyFront>
        > = companies.map(el => {
          return {
            value: el,
            label: el.companyName,
          };
        });
      setOptions(newOptions);
    }
    setLoading(false);
  };

  const onSelectHandler = (checked: boolean, company: IFuneralCompanyFront) => {
    if (checked) {
      onSelect(company);
      setValueTextField("");
      setOptions([]);
    }
  };

  return(
    <>
      <label className="full_width">
        <Autocomplete 
          loading={loading}
          disablePortal={true}
          freeSolo={true}
          sx={{
            display: "inline-block",
            width: "100%",
            "& input": {
              width: "100%",
              fontFamily: "Raleway",
              color: (theme) =>
                theme.palette.getContrastText(theme.palette.background.paper),
            },
          }}
          id="search-deceased"
          options={options || []}
          renderOption={(_, option) => {
            const newOpt = option as unknown as IAssetAutoCompleteField<
                IFuneralCompanyFront
              >;
            
            return <Box key={newOpt.value.companyName}>
              <FuneralCompanyItem
                company={newOpt.value}
                onSelect={(checked) => onSelectHandler(checked, newOpt.value)}
              />
            </Box>;
          }}
          componentsProps={{
            paper: {
              sx: {
                borderRadius: "1px",
                marginTop: "2px",
                backgroundColor: "#FFFFFF",
              }
            }
          }}
          renderInput={(params) => (
            <Box 
              ref={params.InputProps.ref}
              className="wrapper-search-funeral-company"
              sx={{
                background: backgroundInput ? 
                  backgroundInput : backgroundOpacity,
                height: 60
              }}
            >
              <input
                placeholder={`${t("searchFunealCompany")} (${t("optional")})`}
                {...params.inputProps}
                style={{
                  background:  backgroundInput ? 
                    backgroundInput : backgroundOpacity
                }}
                className="input-search-funeral-company"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={valueTextField || ""}
                type="text"
              />

              <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderLeft: `1px solid ${grayDark}`
              }}>
                <Search sx={{color: grayDark, ml: 1}} />  
              </Box>            
            </Box>
          )}
        />
      </label>
    </>
  );
};