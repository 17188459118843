import React from "react";
import { TemplateAuth } from "../../components/utils/auth/templateauth";
import { Box, Typography, Button } from "@mui/material";
import { MyContainer } from "../../components/utils/myContainer";
import { useTranslation } from "react-i18next";
import { darkTurquoise, gold } from "../../ui/color";
import FormComponent from "../../components/forms/formcomponent";
import { useForm } from "react-hook-form";
import { resetPasswordForm }
  from "../../components/forms/asset/auth/resetpassword";
import { LoadingButton } from "@mui/lab";
import { ChevronRight } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { routing } from "../../router";
import { IDataFrontResetPassword, IDataResResetPassword } from "type-absenso";
import { post } from "../../backend/utils/post";
import { useAlert } from "../../provider/error/hooks/useAlert";
import { resetPasswordUrl } from "../../backend/utils/url";
import { useState } from "react";
import { AxiosError } from "axios";

const ResetPassword = () => {
  const {t} = useTranslation();
  const {watch, control, formState, handleSubmit} = useForm();
  const alert = useAlert();

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (data: {email: string}) => {
    setLoading(true);
    const dataToSend: IDataFrontResetPassword = {
      email: data.email
    };
    try {
      await post(resetPasswordUrl, dataToSend);
    } catch (error) {
      const err = error as AxiosError;
      const errData = (
        err.response && err.response.data
      ) as IDataResResetPassword;
      if (errData.code === "auth/email-not-found") {
        alert.createAlert({
          type: "error",
          message: t("errorResetPasswordEmailNotFound")
        });
      } else if (errData.code === "auth/invalid-email") {
        alert.createAlert({
          type: "error",
          message: t("errorResetPasswordEmailInvalid")
        });
      } else {
        alert.defaultError();
      }
    }
    setLoading(false);
  };

  return(
    <>
      <TemplateAuth 
        content={
          <Box sx={{
            flex: "1 1 0px",
            width: {md: "50%", xs: "100%"},
            py: 6
          }}>
            <MyContainer maxWidth="sm" sx={{
              minHeight: "100vh",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column"
            }} >
              <Box sx={{pb: 2}}>
                <Typography
                  variant={"title" as never}
                  sx={{
                    color: darkTurquoise
                  }}
                >
                  {t("forgotPassword")}
                </Typography>
                <Typography
                  sx={{
                    color: gold,
                    pt: 2
                  }}
                  variant={"subtitle" as never}
                >
                  {t("subtitleResetPassword")}
                </Typography>
              </Box>

              <Box>
                <FormComponent 
                  watch={watch}
                  control={control}
                  formState={formState}
                  arrayForms={resetPasswordForm}
                />
              </Box>

              <LoadingButton
                loading={loading}
                onClick={handleSubmit(onSubmit as never)}
                variant="contained"
                sx={{
                  width: "100%",
                  mt: 4,
                  backgroundColor: gold,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                {t("sendEmailResetPassword")}
                <ChevronRight />
              </LoadingButton>

              <Box mt={5} sx={{
                textAlign: "center"
              }}>
                <Typography>
                  {t("textBelowBtnResetPassword")}
                </Typography>
                <Link to={routing.auth.signin} style={{textDecoration: "none"}}>
                  <Button
                    sx={{mt: 2, textTransform: "uppercase", color: gold}}
                    variant="text"
                  >
                    {t("connectTitle")}
                  </Button>
                </Link>
              </Box>
            </MyContainer>
          </Box>
        }
      />
    </>
  );
};

export default ResetPassword;
