import { geohashForLocation } from "geofire-common";
import { IGeolocation } from "type-absenso";
import { IAssetAutoCompleteField } from "../components/forms/types";
import { postAuth } from "./utils/postAuth";
import { detailAddressUrl, formattedAddressUrl } from "./utils/url";

export const searchAddress: (text: string) => Promise<
    Array<IAssetAutoCompleteField<string>> | undefined
    > = async (text: string) => {
      const response = await postAuth(formattedAddressUrl, {
        data: text,
      });

      if (response && response.data) {
        const data: Array<{
            description: string;
            place_id: string;
        }> = response.data;

        return data.map(d => {
          return {label: d.description, value: d.place_id};
        });
      }

      return undefined;
    };

export const getDetailsAddress = async (placeId: string) => {
  const response = await postAuth(detailAddressUrl, {
    data: placeId
  });

  if (response && response.data.result.geometry.location) {
    const hash = geohashForLocation([
      response.data.result.geometry.location.lat,
      response.data.result.geometry.location.lng
    ]);

    return {
      lat: response.data.result.geometry.location.lat,
      lng: response.data.result.geometry.location.lng,
      hash,
    } as IGeolocation;
  }
};