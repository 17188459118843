import React, { ReactNode } from "react";
import { Box, Stepper, Step, StepLabel } from "@mui/material";
import { backgroundOpacity } from "../../ui/color";
import { useTranslation } from "react-i18next";
import { gold } from "../../ui/color";
import { MyContainer } from "../../components/utils/myContainer";
import { TStateMemorial } from "../order/linkProduct/funnelLinkProduc";

const LayoutLinkProduct = ({
  children,
  step,
  stateMemorial,
}: {
  children: ReactNode;
  step: number;
  stateMemorial: TStateMemorial;
}) => {
  const {t} = useTranslation();

  const steps = [
    t("selectYourKit"),
    t(stateMemorial === null ? "selectDeceased" : "tryitfree"),
    t("scanYourKit")
  ];

  return(
    <>
      <Box sx={{
        backgroundColor: backgroundOpacity,
        p: 6,
      }}>
        <MyContainer>
          <Stepper
            activeStep={step}
            alternativeLabel
            sx={{
              "& .MuiStepIcon-root.Mui-active": {
                color: gold
              },
              "& .MuiStepIcon-root.Mui-completed": {
                color: gold
              }
            }}
          >
            {steps.map((el, i) => (
              <Step key={i} sx={{
                "& .MuiStepIcon-root.Mui-active": {
                  color: gold
                },
                "& .MuiStepIcon-root.Mui-completed": {
                  color: gold
                }
              }}>
                <StepLabel sx={{
                  "& .MuiStepLabel-label.Mui-active": {
                    fontWeight: "bold"
                  },
                  "& .MuiStepLabel-label.Mui-completed": {
                    fontWeight: "bold"
                  }
                }}>
                  {el}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </MyContainer>
      </Box>

      <MyContainer sx={{mt: 4}}>
        {children}
      </MyContainer>
    </>
  );
};

export default LayoutLinkProduct;