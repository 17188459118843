import React, { ReactNode, createContext, useState } from "react";

// Ui
import {
  Dialog,
  Box,
  Typography,
  Grid,
  Button,
} from "@mui/material";

// Hook
import { useTranslation } from "react-i18next";
import {
  backgroundOpacity,
  darkTurquoise,
  gold,
  grayLight
} from "../../ui/color";
import { CheckCircleOutline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { routing } from "../../router";

export const AskAuthContext = createContext<null | {
    openHandler: (params?: {[x: string]: string}) => void;
      }>(null);

const AskAuthProvider = ({children}: {children: ReactNode}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [open, setOpen] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<{[x: string]: string}>();

  const openHandler = (params?: {[x: string]: string}) => {
    setOpen(!open);

    if (params) {
      setSearchParams(params);
    }
  };

  return(
    <AskAuthContext.Provider value={{
      openHandler,
    }}>

      <Dialog
        open={open}
        fullWidth={true}
        onClose={openHandler}
      >
        <Box sx={{
          
        }}> 
          <Box sx={{
            p: 2,
            backgroundColor: backgroundOpacity,
          }}>
            <Typography sx={{
              fontWeight: 400,
              fontSize: theme => theme.spacing(3),
              lineHeight: theme => theme.spacing(4),
              color: darkTurquoise,
              textAlign: "center"
            }}>
              {t("joinCommunityTitle")}
              <Typography component="span" sx={{
                color: gold,
                fontWeight: 900
              }}>
                {t("feature")}
              </Typography>
            </Typography>

            {/*<Box sx={{
              px: 8,
              mb: -3
            }}>
              <img
                className="full_width"
                src={require("../../media/img/community.png")}
                alt="Absenso community"
              />
          </Box>*/}
          </Box>

          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Box sx={{p: 2, pb: {xs: 0, md: "inherit"}}}>
                <Typography sx={{
                  fontWeight: 500,
                  pb: 1,
                  fontSize: theme => theme.spacing(2),
                  lineHeight: theme => theme.spacing(3),
                  color: darkTurquoise
                }}>
                  {t("subtitle1PopupAskAuth")}
                </Typography>

                <Typography sx={{
                  color: grayLight,
                  fontSize: theme => theme.spacing(1.5)
                }}>
                  {/* eslint-disable-next-line max-len */}
                  {t("descriptionFooter")}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box sx={{p: 2, pt: {xs: 0, md: "inherit"}}}>
                {[...new Array(4)].map((_, i) => <Box key={i} sx={{
                  display: "flex",
                  alignItems: "center"
                }}>
                  <CheckCircleOutline sx={{
                    color: gold,
                    mr: 1,
                    my: 0.8
                  }} />
                  <Typography sx={{
                    fontSize: theme => theme.spacing(1.5),
                    lineHeight: theme => theme.spacing(2),
                    fontWeight: 400,
                  }}>
                    {t(`item${i}AskAuthPopup`)}
                  </Typography>
                </Box>)}
              </Box>
            </Grid>
          </Grid>

          <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: {xs: "column", md: "inherit"},
            my: {md: 2, xs: 0},
            p: {xs: 2, md: 0}
          }}>
            <Button
              onClick={() => navigate({
                pathname: routing.auth.signup,
                search: searchParams && new URLSearchParams(
                  searchParams
                ).toString()
              })}
              sx={{
                backgroundColor: gold,
                mr: {md: 1, xs: 0},
                mb: {md: 0, xs: 1},
                maxHeight: theme => theme.spacing(5),
              }}
              variant="contained"
            >
              {t("createAccount")}
            </Button>
            <Button
              onClick={() => navigate({
                pathname: routing.auth.signin,
                search: searchParams && new URLSearchParams(
                  searchParams
                ).toString()
              })}
              sx={{
                backgroundColor: gold,
                ml: {md: 1, xs: 0},
                maxHeight: theme => theme.spacing(5),
              }}
              variant="contained"
            >
              {t("connectTitle")}
            </Button>
          </Box>

          <Box sx={{
            mb: 2,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}>
            <Button 
              onClick={() => openHandler()}
              sx={{
                color: gold,
                textTransform: "uppercase"
              }}
              variant="text"
            >{t("subBtnAskAuth")}</Button>
          </Box>
        </Box>
      </Dialog>

      {children}
    </AskAuthContext.Provider>
  );
};

export default AskAuthProvider;