/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react";
import { routing } from "../../router";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { darkTurquoiseLight, gold } from "../../ui/color";

const QrCode = () => {
  const { uid } = useParams();
  const {t} = useTranslation();

  const downloadQrCode = () => {
    const canvaRef = document.getElementById("qrcode_deceased");

    if (canvaRef) {
      const link = document.createElement("a");
      // @ts-ignore
      link.href = canvaRef.toDataURL("image/png");
      link.download = `${uid}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return(
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      minHeight: "100vh",
      px: 2,
    }}>
      <QRCodeCanvas
        id="qrcode_deceased"
        bgColor={darkTurquoiseLight}
        fgColor="white"
        style={{
          maxWidth: "400px",
          width: "100%",
          height: "400px",
        }}
        // eslint-disable-next-line max-len
        value={`https://${window.location.host}/#${routing.desceaded.index.replace(":id", uid as string)}`}
      />

      <Button
        variant="contained"
        onClick={downloadQrCode}
        sx={{
          mt: 4,
          backgroundColor: gold,
          maxWidth: "400px",
          width: "100%",
        }}
      >
        {t("downloadQrcode")}
      </Button>
    </Box>
  );
};

export default QrCode;
