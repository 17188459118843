import { useState } from "react";
import { IDataFrontUpdateDeath } from "type-absenso";
import { postAuth } from "../../../../../backend/utils/postAuth";
import { updateDeathUrl } from "../../../../../backend/utils/url";
import { useAlert } from "../../../../../provider/error/hooks/useAlert";

export const useUpdate = () => {
  const alert = useAlert();

  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
    
  const updateHandler = async (data: IDataFrontUpdateDeath) => {
    setLoadingUpdate(true);
    try {
      await postAuth(updateDeathUrl, data);
    } catch (error) {
      alert.defaultError();
    }
    setLoadingUpdate(false);
  };

  return { updateHandler, loadingUpdate };
};