import React, { ReactNode, useState, useMemo } from "react";

// Ui
import {
  Box,
  Badge,
  Tooltip,
  ClickAwayListener,
  ButtonBase,
  Button,
  Typography
} from "@mui/material";
import { NotificationTooltip }
  from "../../components/utils/notifications/notificationTooltip";

// Backend
import { useMediaQuery } from "@mui/material";
import { useContext } from "react";
import {NotificationsContext } 
  from "../../provider/notifications/notificationProvider";
import { Navbar } from "../../components/utils/layout/navbar";
import { SearchDeceased } from "../../components/utils/deceased/searchDeceased";
import { Menu, NotificationsRounded } from "@mui/icons-material";
import { useUser } from "../../provider/auth/hooks/useUser";
import { UserConnectedMenu }
  from "../../components/utils/user/userConnectedMenu";
import { UserPicture } from "../../components/utils/user/userPicture";
import { useNavigate, useSearchParams } from "react-router-dom";
import { createFormatSubRoute, routing } from "../../router";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LangManager } from "../../components/utils/website/langManager";
import { gold } from "../../ui/color";

const LayoutAuth = ({children}: {children: ReactNode}) => {
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openMemorialFlow, setOpenMemorialFlow] = useState<boolean>(
    Boolean(searchParams.get("open") === "true")
  );
  const {t} = useTranslation();

  const notifsHandler = useContext(NotificationsContext);
  const user = useUser();
  const navigate = useNavigate();
  const match = useMediaQuery("(min-width:380px)");

  const menuHandler = () => setOpenMenu(!openMenu);

  const openMemorialHandler = () => {
    setOpenMemorialFlow(!openMemorialFlow);
  };

  const countNotif = useMemo(() => {
    if (notifsHandler && notifsHandler.notifComplete) {
      const count = notifsHandler.notifComplete.filter(
        el => el.hasBeenSeen === false
      ).length;

      if (count > 9) {
        return "9+";
      }

      return count;
    } else if (notifsHandler && notifsHandler.notifs) {
      const count = notifsHandler.notifs.filter(
        el => el.hasBeenSeen === false
      ).length;

      if (count > 9) {
        return "9+";
      }

      return count;
    }

    return undefined;
  }, [
    notifsHandler && notifsHandler.notifComplete,
    notifsHandler && notifsHandler.notifs
  ]);

  const scrollToId = async (id: string) => {
    if (location.pathname !== routing.website.index) {
      navigate(routing.website.index);
      setOpenMenu(!openMenu);
    }
    
    const ref = document.getElementById(id);
    if (ref) {
      ref.scrollIntoView({
        behavior: "smooth"
      });
    }
  };

  const openNotif = async () => {
    if (notifsHandler) {
      setOpen(!open);
      await notifsHandler.completeNotifs();
    }
  };

  const notificationsComplete = useMemo(() => {
    return (notifsHandler && notifsHandler.notifComplete) || undefined;
  }, [notifsHandler && notifsHandler.notifComplete]);

  const getNotifications = async () => {
    if (notifsHandler) {
      await notifsHandler.getNotificationsHandler();
    }
  };

  return(
    <>
      <Navbar
        unShowBottomBar={false}
        openMobile={openMenu}
        mobileOnClose={() => setOpenMenu(!openMenu)}
        mobileComponent={<Box>
          {[
            {text: t("home"), action: () => scrollToId("home")},
            {
              text: t("presentation"),
              action: () => scrollToId("presentation")
            },
            {text: t("testimony"), action: () => scrollToId("testimony")},
            {text: t("offer"), action: () => scrollToId("offer")},
            {text: t("contact"),action: () => scrollToId("contact")}
          ].map((el, i) => (
            <ButtonBase 
              onClick={el.action}
              key={i} 
              sx={{
                display: "flex",
                justifyContent: "left",
                width: "100%",
                p: 2,
              }}>
              <Typography sx={{
                fontSize:theme => theme.spacing(2),
                lineHeight:theme => theme.spacing(2),
                fontWeight: 500,
              }}>{el.text}</Typography>
            </ButtonBase>
          ))}

          <Box px={2}>
            <LangManager />
          </Box>

          <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            pt: 2,
            pb: 2
          }}>
            <Box sx={{width: "90%"}}>
              <SearchDeceased afterChoose={() => setOpenMenu(!openMenu)} />
            </Box>
          </Box>

          <Box 
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              width: "100%"
            }}>
            <Button 
              variant="contained" 
              sx={{
                width: "90%",
                backgroundColor: gold,
                my: 1
              }}
              onClick={openMemorialHandler}
            >
              {t("tryitfree")}
            </Button>
          </Box>
        </Box>}
        left={
          <>
            {user && <ButtonBase 
              onClick={() => navigate(createFormatSubRoute(
                routing.user.index, routing.user.indexPage
              ).replace(":id", user.id)
              )}
              sx={{
                display: {xs: "flex", md: "none"},
                alignItems: "center"
              }}>
              <UserPicture 
                user={user}
                sxIcon={{
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  fontSize: (theme: any) => theme.spacing(5),
                  color: "white",
                }}
                className="rounded_img"
                sx={{
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  height: (theme: any) => theme.spacing(5),
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  width: (theme: any) => theme.spacing(5),
                  borderRadius: "50%",
                  border: "1px solid #FFFFFF"
                }}
              />
            </ButtonBase>}
            <Box sx={{
              display: {xs: "none", md: "flex"},
              width: "100%",
            }}>
              <SearchDeceased />
            </Box>
          </>
        }
        right={
          <>
            <Box sx={{
              display: {md: "none", xs: "initial"},
              justifyContent: "center",
              alignItems: "center"
            }}>
              <IconButton onClick={() => setOpenMenu(!openMenu)}>
                <Menu sx={{
                  color: "white"
                }} />
              </IconButton>
            </Box>
            <Box sx={{
              display: {xs: "none", md: "flex"}
            }}>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <ButtonBase
                  onClick={openNotif}
                >
                  <Tooltip
                    TransitionProps={{timeout: 0}}
                    componentsProps={{
                      tooltip: {
                        id: "container-notifications",
                        sx: {
                          width: match ? "300px" : "270px",
                          height: "400px",
                          overflowY: "scroll",
                          p: 0,
                          "& .MuiList-root": {
                            p: 0,
                          }
                        }
                      }
                    }}
                    PopperProps={{
                      disablePortal: true,
                    }}
                    open={open}
                    onClose={() => setOpen(false)}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={<NotificationTooltip
                      getNotifications={getNotifications}
                      hasMore={(notifsHandler && notifsHandler.hasMore) || true}
                      notifComplete={notificationsComplete}
                      sizeNotif={
                        (
                          notifsHandler && 
                          notifsHandler.notifs && 
                          notifsHandler.notifs.length
                        ) || 0
                      }
                    />}
                    placement="bottom-start"
                  >
                    <Badge badgeContent={countNotif} color="error" sx={{
                      mr: 1
                    }}>
                      <NotificationsRounded />
                    </Badge>
                  </Tooltip>
                </ButtonBase>
              </ClickAwayListener>
              <UserConnectedMenu user={user} />
            </Box>
          </>
        }
      >
        <Box sx={{
          minHeight: "100vh",
        }}>
          {children}
        </Box>
      </Navbar>
    </>
  );
};

export default LayoutAuth;
