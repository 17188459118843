import { FilterVintage, LocationOn } from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IDeathPage } from "../../../../backend/type/frontDocument";
import { darkTurquoise, gold, grayDark } from "../../../../ui/color";
import { UserPicture } from "../../user/userPicture";
import { usePlaceBouquet } from "./hooks/usePlaceBouquet";
import { EditDeceased } from "./utils/editDeceased";
import { Gmap } from "./utils/gmap";
import { TemplateCard } from "./utils/templateCard";
import FormComponent from "../../../forms/formcomponent";
import {
  IUpdateGeoLocationDeathForm,
  updateGeoLocationDeceasedForm
} from "../../../forms/asset/deceased/createdeceased";
import { useState, useMemo, useEffect } from "react";
import { searchAddress, getDetailsAddress } from "../../../../backend/adress";
import { IAssetAutoCompleteField } from "../../../forms/types";
import { useAlert } from "../../../../provider/error/hooks/useAlert";
import { postAuth } from "../../../../backend/utils/postAuth";
import { updateDeathLocationUrl } from "../../../../backend/utils/url";
import { IDataFrontUpdateLocation } from "type-absenso";
import { DocumentReference, Timestamp } from "firebase/firestore";

export const Map = ({
  death,
  actionBouquet,
  canModify
} : {
    death: IDeathPage,
    actionBouquet?: () => void;
    canModify?: boolean;
  }) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.only("xs"));
  const formUpdate = useForm();
  const alert = useAlert();
  const { loading, addBouquet } = usePlaceBouquet(death, actionBouquet);

  const [optionsAddress, setOptionsAddress] = useState<
    Array<IAssetAutoCompleteField<string>>
  >([]);
  const [loadingAddress, setLoadingAddress] = useState<boolean>(false);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
  const [replicaDeath, setReplicaDeath] = useState<IDeathPage>(death);

  const TemplateMapCard = ({children}: {children: ReactNode}) => {
    return <Box sx={{
      borderRadius: theme => theme.spacing(1),
      backgroundColor: "white",
      border: `1px solid ${gold}`,
      mb: 1,
      p: 1,
      mx: {xs: 1, md: 0},
      // eslint-disable-next-line max-len
      boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)"
    }}>
      {children}
    </Box>;
  };

  const addressField: IAssetAutoCompleteField<
    string
  > = useMemo(() => formUpdate.watch("address"), [formUpdate.watch("address")]);

  useEffect(() => {
    if (addressField) {
      (async() => {
        const details = await getDetailsAddress(addressField.value);
        if (details) {
          formUpdate.setValue("latitude", details.lat);
          formUpdate.setValue("longitude", details.lng);
          formUpdate.setValue("hash", details.hash);
        }
      })();
    }
  }, [addressField]);

  const onChangeAddress = async (text: string) => {
    setLoadingAddress(true);
    const resAddress = await searchAddress(text);
    if (resAddress) {
      setOptionsAddress(resAddress);
    }
    setLoadingAddress(false);
  };

  const editHandler = async (data : IUpdateGeoLocationDeathForm) => {
    setLoadingUpdate(true);
    try {
      const dataToSend: IDataFrontUpdateLocation<
        DocumentReference, Timestamp
      > = {
        lastAddress: data.address.label,
        geoLocation: {
          lat: data.latitude,
          lng: data.longitude,
          hash: data.hash
        },
        idDeath: death.id
      };
      await postAuth(updateDeathLocationUrl, dataToSend);
      setReplicaDeath({
        ...replicaDeath,
        lastAddress: data.address.label,
        geoLocation: {
          lat: data.latitude,
          lng: data.longitude,
          hash: data.hash
        }
      });
    } catch (error) {
      alert.defaultError();
    }
    setLoadingUpdate(false);
  };

  return(
    <TemplateCard
      icon={<LocationOn sx={{color: gold}} />}
      title={t("virtualTomb")}
      noPadding={true}
    >

      <EditDeceased 
        displayed={canModify || false}
        title={t("titleUpdateProfile")}
        content={<FormComponent 
          watch={formUpdate.watch}
          control={formUpdate.control}
          formState={formUpdate.formState}
          arrayForms={updateGeoLocationDeceasedForm({
            onChangeAddress: (search) => onChangeAddress(search),
            optionsAddress: optionsAddress,
            loadingAddress: loadingAddress
          })}
        />}
        actionTitle={t("btnUpdateData")}
        actionHandler={formUpdate.handleSubmit(editHandler as never)}
        actionLoading={loadingUpdate}
      />

      <Box sx={{
        position: "relative"
      }}>
        <Gmap geoLocation={replicaDeath.geoLocation} style={{
          width: "100%",
          height: match ? "640px" :"320px",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px"
        }} />

        <Box sx={{
          position: "absolute",
          right: {xs: 0, md: 3},
          bottom: 3,
          width: {md: "50%", xs: "100%"},
        }}>
          <TemplateMapCard>
            <Typography component="span" sx={{
              color: gold,
              textTransform: "uppercase",
              pb: 2
            }}>
              {t("locationVirtualTomb")}
            </Typography>

            <Box sx={{
              display: "flex",
              alignItems: "center"
            }}>
              <LocationOn sx={{color: gold}} />
              <Typography sx={{
                color: grayDark,
                fontSize: theme => theme.spacing(1.5),
                fontWeight: 400,
                letterSpacing: "0.4px",
                ml: 0.5,
              }}>
                {replicaDeath.lastAddress}
              </Typography>
            </Box>
          </TemplateMapCard>
          {death.bouquet && death.bouquet.date && 
          <TemplateMapCard>
            <Typography component="span" sx={{
              color: gold,
              textTransform: "uppercase",
              pb: 1
            }}>
              {t("lastDepositBouquetTitle")}
            </Typography>
            <Box sx={{
              mt: 1,
              display: "flex",
              alignItems: "center",
            }}>
              <UserPicture 
                user={death.bouquet.user}
                className="rounded_img"
                sx={{
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  width: (theme: any) => theme.spacing(5),
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  height: (theme: any) => theme.spacing(5),
                  backgroundColor: darkTurquoise,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                }}
                sxIcon={{
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  fontSize: (theme: any) => theme.spacing(4.5),
                  color: gold,
                }}
              />

              <Box sx={{
                pl: 1,
                display: "flex",
                flexDirection: "column"
              }}>
                <Typography component="span" sx={{
                  fontWeight: 500,
                  fontSize: theme => theme.spacing(2),
                  lineHeight: "150%"
                }}>
                  {/* eslint-disable-next-line max-len */}
                  {`${death.bouquet.user.firstName} ${death.bouquet.user.name}`}
                </Typography>
                <Typography component="span" sx={{
                  fontWeight: 400,
                  fontSize: theme => theme.spacing(1.5),
                  letterSpacing: "0.4px",
                  color: grayDark,
                }}>
                  {t("dateDepositFlower", {
                    date: death.bouquet.date.toLocaleDateString("eu-FR")
                  })}
                </Typography>
              </Box>
            </Box>
          </TemplateMapCard>}
          
          <Box sx={{
            mx: 1,
          }}>
            <ButtonBase
              onClick={addBouquet}
              sx={{
                backgroundColor: "white",
                p: 1,
                borderRadius: theme => theme.spacing(1),
                border: `1px solid ${gold}`,
                width: "100%",
              }}>
              {loading ? <CircularProgress size="24px" sx={{color: gold}} /> :
                <>
                  <FilterVintage sx={{
                    color: gold,
                    mr: 1
                  }}/>
                  <Typography sx={{
                    textTransform: "uppercase",
                    color: gold
                  }}>
                    {t("btnDepositVirtualBouquet")}
                  </Typography>
                </>}
            </ButtonBase>
          </Box>
        </Box>
      </Box>
    </TemplateCard>
  );
};