import React from "react";

// Ui
import { Typography } from "@mui/material";

// I18n
import { useTranslation } from "react-i18next";

const PaymentSuccess = () => {
  const {t} = useTranslation();
  return(
    <>
      <Typography>{t("paymentSuccess")}</Typography>
    </>
  );
};

export default PaymentSuccess;