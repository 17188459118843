import React from "react";
import {
  Box, ButtonBase, Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { createFormatSubRoute, routing } from "../../router";
import {
  matchRoutes,
  Outlet,
  useLocation,
  useNavigate
} from "react-router-dom";
import { backgroundOpacity, gold, grayDark } from "../../ui/color";

const items = [
  {text: "profileAdministredTitle", link: routing.user.parameter.adminProfil},
  {text: "addAdminTitle", link: routing.user.parameter.addAdmin},
  {
    text: "modifyLinkProfileTitle",
    link: routing.user.parameter.modifyLinkProfil
  },
  {
    text: "myAccount",
    link: routing.user.parameter.myAccount
  }
];

const widthDrawer = "240px";

const LayoutParameter = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const urlIsMatch = (url: string) => {
    const pathUrl = createFormatSubRoute(
      routing.user.index, routing.user.parameter.index
    ).concat(`/${url}`);

    return matchRoutes(
      [{path: pathUrl}],
      location.pathname
    );
  };

  return(
    <Box sx={{
      display: "flex",
      flexDirection: {xs: "column", md: "row"},
      width: "100%",
      my: 2,
      // eslint-disable-next-line max-len
      boxShadow: "0px 3px 25px -2px rgba(0, 0, 0, 0.14), 0px 2px 20px rgba(0, 0, 0, 0.02), 0px 1px 15px rgba(0, 0, 0, 0.03)",
      borderRadius: theme => theme.spacing(3),
      overflow: "hidden"
    }}>
      <Box sx={{
        width: {md: widthDrawer, xs: "100%"},
        backgroundColor: backgroundOpacity,
        p: 2,
      }}>
        <Typography sx={{
          fontWeight: 600,
          fontSize: theme => theme.spacing(2),
          lineHeight: theme => theme.spacing(3),
          letterSpacing: "0.4px"
        }}>
          {t("parameters")}
        </Typography>

        <Box sx={{
          mt: 2
        }}>
          {items.map((el, i) => (
            <ButtonBase 
              onClick={() => navigate(el.link)}
              key={i} 
              sx={{
                width: "100%",
                p: 2,
                display: "flex",
                justifyContent: "left",
                my: 1,
              }}>
              <Typography sx={{
                color: urlIsMatch(el.link) ? gold : grayDark
              }}>
                {t(el.text)}
              </Typography>
            </ButtonBase>
          ))}
        </Box>
      </Box>

      <Box sx={{
        width: {md: "calc(100% - 240px)", xs: "100%"}
      }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default LayoutParameter;