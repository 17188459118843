import React, { ReactNode } from "react";
import { TSexe } from "type-absenso";
import { routing } from "../../../../router";
import { gold } from "../../../../ui/color";
import { LinkLabelCheckbox } from "../../checkBox";
import { IAssetAutoCompleteField, IForm } from "../../types";

const optionsSexe: Array<IAssetAutoCompleteField<TSexe>> = [
  {label: "Boy", value: "boy"},
  {label: "Woman", value: "woman"},
  {label: "Other", value: "other"}
];

export interface ISignupForm {
  dateOfBirth?: Date;
  email?: string;
  firstName?: string;
  name?: string;
  phone?: string;
  sexe?: IAssetAutoCompleteField<TSexe>;
  password?: string;
  profilePictures?: string;
  uidGoogle?: string;
}

export interface IPasswordForm {
  password?: string;
  verifyPassword?: string;
}

export interface ISignupFormFlow extends IPasswordForm {
  dateOfBirth: Date;
  email: string;
  firstName: string;
  name: string;
  phone: string;
  sexe: IAssetAutoCompleteField<TSexe>;
  password: string;
  agreeGeneral: boolean;
  agreePrivacyPolicy: boolean;
}

export interface IPersonalInfoForm {
  firstName: string;
  name: string;
  phone: string;
  sexe: IAssetAutoCompleteField<TSexe>;
  dateOfBirth: Date;
  agreeGeneral: boolean;
  agreePrivacyPolicy: boolean;
}

export const sexeData: Array<IAssetAutoCompleteField<TSexe>> = [
  {label: "boy", value: "boy"},
  {label: "woman", value: "woman"},
  {label: "other", value: "other"}
];

export const personalInfoForm: Array<IForm> = [
  {
    type: "TextField",
    elementTextField: {
      name: "firstName",
      id: "firstName",
      type: "text",
      label: "firstName",
      variant: "standard"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      name: "name",
      id: "name",
      type: "text",
      label: "name",
      variant: "standard"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      name: "phone",
      id: "phone",
      type: "text",
      label: "phone",
      variant: "standard"
    }
  },
  {
    type: "Autocomplete",
    elementAutoComplete: {
      variant: "standard",
      options: sexeData,
      label: "sexe",
      name: "sexe",
      id: "sexe"
    }
  },
  {
    type: "DatePicker",
    elementDatePicker: {
      name: "dateOfBirth",
      label: "dateOfBirth",
      desactivateColor: true
    }
  },
  {
    type: "CheckBox",
    elementCheckBox: {
      name: "agreeGeneral",
      required: false,
      label: <LinkLabelCheckbox
        label="agreeGeneral"
        link={routing.website.generalConditionOfUse}
        underline={true}
      />,
      color: gold
    },
  },
  {
    type: "CheckBox",
    elementCheckBox: {
      name: "agreePrivacyPolicy",
      label: <LinkLabelCheckbox
        label="agreePrivacyPolicy"
        link={routing.website.privacyPolicy}
        underline={true}
      />,
      required: false,
      color: gold
    },
  }
];

export const passwordsForm: (
  type: string,
  typeVerify: string,
  icon: ReactNode,
  iconVerify: ReactNode
) => IForm[] = (type, typeVerify, icon, iconVerify) => [
  {
    type: "TextField",
    elementTextField: {
      name: "password",
      id: "password",
      type: type,
      label: "password",
      variant: "standard",
      InputProps: {
        endAdornment: (icon),
      }
    }
  },
  {
    type: "TextField",
    elementTextField: {
      name: "verifyPassword",
      id: "verifyPassword",
      type: typeVerify,
      label: "verifyPassword",
      variant: "standard",
      InputProps: {
        endAdornment: (iconVerify),
      }
    }
  },
];

export const signupForm: Array<IForm> = [
  {
    type: "TextField",
    elementTextField: {
      name: "email",
      id: "email",
      type: "email",
      label: "email",
      variant: "standard",
    }
  },
  {
    type: "TextField",
    elementTextField: {
      name: "firstName",
      id: "firstName",
      type: "text",
      label: "firstName",
      variant: "standard",
    }
  },
  {
    type: "TextField",
    elementTextField: {
      name: "name",
      id: "name",
      type: "text",
      label: "name"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      name: "phone",
      id: "phone",
      type: "text",
      label: "phone"
    }
  },
  {
    type: "Autocomplete",
    elementAutoComplete: {
      name: "sexe",
      id: "sexe",
      options: optionsSexe,
      label: "sexe",
    }
  },
  {
    type: "DatePicker",
    elementDatePicker: {
      name: "dateOfBirth",
      label: "dateOfBirth"
    }
  }
];