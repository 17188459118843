import React from "react";

// Forms
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

// Ui
import {
  Checkbox as Check,
  FormControlLabel,
  Typography,
} from "@mui/material";

// Type
import { IElementCheckBox } from "./types";
import { darkTurquoise } from "../../ui/color";
import { Link } from "react-router-dom";

interface IPropsCheckBox extends IElementCheckBox {
    control: Control;
}

export const LinkLabelCheckbox = ({
  link,
  label,
  underline,
}: {
  link: string;
  label: string;
  underline?: boolean;
}) => {
  const {t} = useTranslation();
  return(
    <Link 
      target="_blank" 
      to={link} 
      className="disable-a">
      <Typography component="span" sx={{
        textDecoration: underline ? "underline" : "none",
        color: darkTurquoise,
      }}>
        {t(label)}
      </Typography>
    </Link>);
};

const CheckBox = ({
  name,
  required,
  label,
  control,
  color,
}: IPropsCheckBox) => {
  const currentColor = color ? color : darkTurquoise;
  return(
    <Controller
      name={name}
      control={control}
      rules={{required: required}}
      render={(({field: {onChange, value}}) => {
        const {t} = useTranslation();
        return(
          <>

            <FormControlLabel 
              control={
                <Check
                  onChange={onChange}
                  value={value}
                  checked={value ? true : false}
                  sx={{
                    color: currentColor,
                    "&.Mui-checked": {
                      color: currentColor,
                    },
                  }}
                />
              }
              color={currentColor}
              label={typeof label === "string" ? t(label) : label}
            />
          </>
        );
      })}

    />
  );
};

export default CheckBox;