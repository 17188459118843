import React from "react";
import { IOfferData } from "type-absenso";
import { Box, Typography } from "@mui/material";
import { backgroundOpacity, darkTurquoise, gold } from "../../../ui/color";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

export const OfferUser = ({offer}: {offer: IOfferData}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return(
    <>
      <Box sx={{
        backgroundColor: backgroundOpacity,
        mx: 1,
        p: 2,
        minWidth: theme => theme.spacing(35),
        minHeight: theme => theme.spacing(20),
        borderRadius: theme => theme.spacing(3),
      }}>
        <Typography sx={{
          textAlign: "center"
        }}>
          {t("offerTitle")}
        </Typography>
        <Typography sx={{
          textAlign: "center",
          color: darkTurquoise,
          fontWeight: 400,
          fontSize: theme => theme.spacing(4),
          lineHeight: theme => theme.spacing(4),
        }}>
          {t(offer.version)}
        </Typography>

        <Box sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          mt: 2,
        }}>
          <LoadingButton 
            onClick={() => navigate(offer.url)}
            sx={{
              color: gold,
              textAlign: "center",
            }}>
            {`${t("chooseThisOffer")} >`}
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};