import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { gold, grayDark } from "../../../../../ui/color";

export const FlowContentTemplate = ({
  children,
  title,
  subtitle,
  displayedBtn,
  previousAction,
  nextAction,
  loadingNext,
  disableNextBtn,
}: {
    children: ReactNode;
    title?: string;
    displayedBtn?: boolean;
    previousAction?: () => void;
    nextAction?: () => void;
    loadingNext?: boolean;
    subtitle?: string;
    disableNextBtn?: boolean;
}) => {
  const {t} = useTranslation();
  return(
    <Box sx={{
      backgroundColor: "#FFF",
      minHeight: 600,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      position: "relative",
    }}>
      <Box>
        {title && subtitle && <Box sx={{
          px: 4,
          py: 2,
        }}>
          <Typography
            sx={{
              textAlign: "left",
              fontWeight: 400,
              fontSize: theme => theme.spacing(2),
              lineHeight: theme => theme.spacing(2.5),
              color: grayDark,
              pb: 1
            }}>
            {title}
          </Typography>
          <Typography sx={{
            color: gold,
            fontWeight: 600,
            fontSize: theme => theme.spacing(3),
            lineHeight: theme => theme.spacing(3)
          }}>
            {subtitle}
          </Typography>
        </Box>}

        <Box sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          minHeight: 500,
          px: 4,
          py: 2,
        }}>
          {children}
        </Box>
      </Box>

      {displayedBtn && <Box sx={{
        display: "flex",
        px: 4,
        alignItems: "center",
        justifyContent: "center",
        py: 2,
        position: "sticky",
        bottom: 0,
        background: "#FFF",
      }}>
        <LoadingButton 
          onClick={previousAction}
          disabled={Boolean(!previousAction)}
          sx={{
            height: theme => theme.spacing(5),
            width: 150,
            color: gold,
            textTransform: "uppercase"
          }}
        >
          {t("previous")}
        </LoadingButton>

        <LoadingButton
          loading={loadingNext}
          variant={"contained" as never}
          onClick={nextAction}
          disabled={disableNextBtn}
          sx={{
            height: theme => theme.spacing(5),
            width: 150,
            backgroundColor: gold,
          }}
        >
          {t("next")}
        </LoadingButton>
      </Box>}
    </Box>
  );
};