import { Autocomplete, ButtonBase, Typography } from "@mui/material";
import React from "react";
import { darkTurquoise } from "../../../ui/color";
import { Box } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useI18n } from "../../../provider/i18n/i18nProvider";
import { TLangAvailable } from "../../../i18n/i18n";
import { IAssetAutoCompleteField } from "../../forms/types";
import { useTranslation } from "react-i18next";
import { FR, NL } from "country-flag-icons/react/3x2";
import { useMemo } from "react";

const optsLang: Array<IAssetAutoCompleteField<TLangAvailable>> = [
  {value: "fr", label: "fr", component: <FR className="flag-lang" />},
  {value: "nl", label: "nl", component: <NL className="flag-lang" />},
];

export const LangManager = () => {
  const i18np = useI18n();
  const {t} = useTranslation();

  const currentLang = useMemo(() => {
    if (i18np && i18np.getLang()) {
      const filter = optsLang.filter(ol => ol.value === i18np.getLang());
      if (filter.length > 0) {
        return filter[0];
      }
    }
  }, [i18np]);

  if (i18np && i18np.getLang()) {
    return(
      <label className="full_width">
        <Autocomplete 
          disablePortal={true}
          freeSolo={true}
          options={optsLang}
          sx={{
            display: "inline-block",
            width: "100%",
            "& input": {
              width: "100%",
              fontFamily: "Raleway",
              color: (theme) =>
                theme.palette.getContrastText(theme.palette.background.paper),
            },
          }}
          renderOption={(_, option) => {
            return <Box key={option.label} px={1}>
              <ButtonBase 
                onClick={() => {
                  if (currentLang && currentLang.value !== option.value) {
                    i18np.setLang(option.value);
                  }
                }}
                sx={{
                  p: 1,
                  width: "100%",
                  display: "flex",
                  justifyContent: "left"
                }}>
                {option.component && option.component}
                {t(option.label)}
              </ButtonBase>
            </Box>;
          }}
          componentsProps={{
            paper: {
              sx: {
                borderRadius: "1px",
                marginTop: "2px",
                backgroundColor: "#FFFFFF",
              }
            }
          }}
          renderInput={(params) => (
            <div
              ref={params.InputProps.ref}
            >
              {currentLang && <>
                <label htmlFor="input-lang">
                  <Box 
                    {...params.inputProps}
                    sx={{
                      p: {md: 1.2, xs: 2},
                      backgroundColor: darkTurquoise,
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: theme => theme.spacing(0.5)
                    }}>
                    <KeyboardArrowDown sx={{
                      mr: 0.5,
                      height: theme => theme.spacing(2),
                      color: "white"
                    }} />
                    {currentLang.component}
                    <Typography sx={{
                      fontSize: "12px",
                      lineHeight: "15px",
                      fontWeight: 400,
                      color: "white"
                    }}>
                      {t(currentLang.label)}
                    </Typography>
                  </Box>
                </label>
                <input 
                  {...params.inputProps} 
                  className="hidden"
                  type="text"
                  id="input-lang"
                />
              </>}
            </div>
          )}
        />
      </label>
    );
  }

  return <></>;
};