import { CheckCircleRounded, ChevronRight } from "@mui/icons-material";
import { Box, Button, ButtonBase, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IFuneralCompanyFront } from "../../../../backend/type/frontDocument";
import { backgroundOpacity, gold } from "../../../../ui/color";
import { FuneralCompanyItem } from "../../deceased/funeralCompanyItem";
import { SearchFuneralCompany } from "../../deceased/searchFuneralCompany";
import { AddressForm } from "./addressform";
import { BtnOfferContent } from "./templateoffer";

export const TemplateTitle = ({step, title}: {
  step: string;
  title: string;
}) => {
  return <>
    <Typography sx={{
      textAlign: "center"
    }}>
      {step}
    </Typography>
    <Typography sx={{
      mb: 4,
      color: gold,
      fontWeight: 500,
      fontSize: theme => theme.spacing(3),
      textAlign: "center"
    }}>
      {title}
    </Typography>
  </>;
};

export type TChooseForm = "address" | "funeral";

export interface IDeliveryParams {
  setDisplayedDeliveryForm: (displayed: TChooseForm) => void;
  form: UseFormReturn<FieldValues, unknown>;
  onSelectFuneralCompany: (fc: IFuneralCompanyFront) => void;
  finalAction: () => void;
  loadingAction: boolean;
  disableBtn?: boolean;
  modalView?: boolean;
  getFromOutsideFormChoose?: (
    value: React.SetStateAction<TChooseForm | undefined>
  ) => void
  currentReceptionFromOutside?: number;
  displayedFormFromExternal?: TChooseForm;
}

export const DeliveryMethod = ({
  setDisplayedDeliveryForm,
  form,
  onSelectFuneralCompany,
  finalAction,
  loadingAction,
  disableBtn,
  modalView,
  getFromOutsideFormChoose,
  currentReceptionFromOutside,
  displayedFormFromExternal,
}: IDeliveryParams) => {
  const {t} = useTranslation();

  const [currentReception, setCurrentReception] = useState<number | undefined>(
    currentReceptionFromOutside
  );
  const [displayedForm, setDisplayedForm] = useState<TChooseForm | undefined>(
    displayedFormFromExternal
  );

  useEffect(() => {
    setDisplayedForm(displayedFormFromExternal);
  }, [displayedFormFromExternal]);

  const [funeralCompany, setFuneralCompany] = useState<IFuneralCompanyFront>();

  const nextStepHandler = (cf: TChooseForm) => {
    setDisplayedDeliveryForm(cf);
    setDisplayedForm(cf);
  };

  const onSelectFCompany = (fc: IFuneralCompanyFront) => {
    setFuneralCompany(fc);
    onSelectFuneralCompany(fc);
  };

  useEffect(() => {
    if (getFromOutsideFormChoose) {
      getFromOutsideFormChoose(
        currentReception === 0 ? "address" : "funeral",
      );
    }
  }, [currentReception]);
  
  const formChoose: TChooseForm = useMemo(
    () => {
      return currentReception === 0 ? "address" : "funeral";
    },
    [currentReception]
  );

  const goBack = () => {
    setCurrentReception(0);
    setDisplayedForm(undefined);
    setFuneralCompany(undefined);
  };

  useEffect(() => {
    window.scrollTo(document.body.scrollHeight, 0);
  }, [currentReception]);

  if (displayedForm && currentReception === 0) {
    return(
      <Box sx={{ width: "100%" }}>
        {!modalView && <TemplateTitle
          step={t("secondStep")}
          title={t("titleWichAddress")}
        />}

        <AddressForm form={form} modalFlowView={modalView}/>

        {!modalView && <Box sx={{mt: 4}}>
          <BtnOfferContent 
            titleBtn={t("finishOrder")}
            action={finalAction}
            activatePrevious={true}
            previousAction={goBack}
            loading={loadingAction}
            disableBtn={disableBtn}
          />
        </Box>}  
      </Box>
    );
  }

  if (displayedForm && currentReception === 1) {
    return(
      <Box sx={{ width: "100%" }}>
        {!modalView && <TemplateTitle
          step={t("secondStep")}
          title={t("wichFuneralAddress")}
        />}
        {funeralCompany && <Box mb={2}>
          <FuneralCompanyItem 
            selected={true}
            company={funeralCompany}
          />
        </Box>}
        <SearchFuneralCompany onSelect={(fc) => onSelectFCompany(fc)} />

        {!modalView && <Box sx={{mt: 4}}>
          <BtnOfferContent 
            titleBtn={t("finishOrder")}
            action={finalAction}
            activatePrevious={true}
            previousAction={goBack}
            loading={loadingAction}
            disableBtn={disableBtn}
          />
        </Box>}
      </Box>
    );
  }

  if (!currentReception || !displayedForm) {
    return(
      <>
        <Box sx={{width: "100%"}}>
          {!modalView && <TemplateTitle
            step={t("firstStep")}
            title={t("whereDeliveryTitle")}
          />}
    
          {[
            {title: t("homeReception")},
            {title: t("funeralReception")}
          ].map((el, i) => (
            <ButtonBase
              key={i}
              onClick={() => setCurrentReception(i)}
              sx={{
                width: "100%",
                backgroundColor: backgroundOpacity,
                p: 2.5,
                mt: 2,
                borderRadius: theme => theme.spacing(0.5),
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                border: i === currentReception ? 
                  `1px solid ${gold}` : "inherit",
              }}>
              <Typography>
                {el.title}
              </Typography>
    
              {i === currentReception && <CheckCircleRounded sx={{
                color: gold,
                fontSize: theme => theme.spacing(3)
              }} />}
            </ButtonBase>
          ))}
    
          {!modalView && <Box mt={4}>
            <Button
              onClick={() => nextStepHandler(formChoose)}
              disabled={Boolean(currentReception === undefined)}
              sx={{
                backgroundColor: gold,
                height: theme => theme.spacing(5)
              }}
              variant="contained"
            >
              {t("nextStep")}
    
              <ChevronRight />
            </Button>
          </Box>}
    
        </Box>
      </>
    );
  }

  return <></>;
};