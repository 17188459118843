import React from "react";
import { IOfferData } from "type-absenso";
import { Box, Typography } from "@mui/material";
import {
  backgroundOpacity, gold, grayBlue
} from "../../../../ui/color";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";

export const SummaryOffer = ({
  offer,
  titleAction,
  subtitleAction,
  action,
  loading,
  disableBtn,
  titleBtn,
  hideBtn,
}: {
    offer: IOfferData,
    titleAction: string,
    subtitleAction: string,
    action: () => void,
    loading: boolean,
    disableBtn: boolean,
    titleBtn: string,
    hideBtn?: boolean;
}) => {
  const {t} = useTranslation();
  return(
    <>
      <Box pb={4} sx={{
        display: {xs: "none", md: "block"}
      }}>
        <Typography 
          variant={"titleOffer" as never}
          sx={{
            pb: 2
          }}>{t(titleAction)}</Typography>
        <Typography variant={"subtitleOffer" as never}>
          {t(subtitleAction)}
        </Typography>
      </Box>

      <Box sx={{
        border: `1px solid ${grayBlue}`,
        borderRadius: theme => theme.spacing(0.5),
        background: "white",
        width: "100%",
      }}>
        <Box sx={{p: 2}}>
          <Typography>
            {t("version")}
          </Typography>
          <Typography sx={{
            fontWeight: 500,
            fontSize: theme => theme.spacing(3),
            lineHeight: theme => theme.spacing(4)
          }}>
            {t(offer.version)}
          </Typography>
        </Box>

        <Box sx={{
          backgroundColor: backgroundOpacity,
          p: 2
        }}>
          {offer.items.map((el, i) => (
            <Typography key={i} sx={{
              pt: i !== 0 ? 1 : 0
            }}>
              {t(el)}
            </Typography>
          ))}
        </Box>
        {!hideBtn && <Box sx={{
          p: 2
        }}>
          <LoadingButton
            variant="contained"
            onClick={action}
            loading={loading}
            disabled={disableBtn}
            sx={{backgroundColor: gold, width: "100%"}}
          >
            {titleBtn}
          </LoadingButton>
        </Box>}
      </Box>
    </>
  );
};