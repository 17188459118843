/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { IDeathFront } from "../../../../backend/type/frontDocument";
import { Box, Typography, useTheme } from "@mui/material";
import {
  darkTurquoise,
  gold,
  grayDark,
  grayLight
} from "../../../../ui/color";
import { CoverPicture } from "./utils/coverpicture";
import { UserPicture } from "../../user/userPicture";
import { ItemBtnProfile } from "./utils/itemBtnProfile";
import { FilterVintage, ShoppingCart, Star } from "@mui/icons-material";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { useTranslation } from "react-i18next";
import { formatDateDeceased } from "../searchDeceased";
import { formatReligion } from "../../format/formatReligion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePlaceBouquet } from "./hooks/usePlaceBouquet";
import { useFollow } from "./hooks/useFollow";
import { collections, IDataFrontUpdateProfile } from "type-absenso";
import { useUpdate } from "./hooks/useUpdate";
import { useState } from "react";
import { useAskAuth } from "../../../../provider/auth/hooks/useAskAuth";
import { useNavigate } from "react-router";
import { routing } from "../../../../router";
import { createSearchParams } from "react-router-dom";
import { EditDeceased } from "./utils/editDeceased";
import FormComponent from "../../../forms/formcomponent";
import { useForm } from "react-hook-form";
import {
  IUpdateProfileDeathForm,
  updateProfileDeceasedForm
} from "../../../forms/asset/deceased/createdeceased";
import { useEffect } from "react";
import { useAlert } from "../../../../provider/error/hooks/useAlert";
import { postAuth } from "../../../../backend/utils/postAuth";
import { updateDeathProfileUrl } from "../../../../backend/utils/url";
import { DocumentReference, Timestamp } from "firebase/firestore";

export const Profile = ({
  death,
  isAdmin,
  bouquetAction,
  modalView,
}: {
  death: IDeathFront;
  isAdmin: boolean;
  bouquetAction: () => void;
  modalView?: boolean;
}) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { addBouquet, loading } = usePlaceBouquet(death, bouquetAction);
  const { updateHandler } = useUpdate();
  const askAuthHandler = useAskAuth();
  const alert = useAlert();
  const follow = useFollow(death.id);
  const religion = formatReligion(death.religion);
  const formUpdate = useForm();

  const [replicaDeath, setReplicaDeath] = useState<IDeathFront>(death);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);

  const linkHandler = () => {
    if (askAuthHandler.userIsConnected()) {
      navigate(routing.order.linkProduct.funnel);
    } else {
      askAuthHandler.openHandler();
    }
  };

  const buyHandler = () => {
    navigate({
      pathname: routing.website.index,
      search: createSearchParams({
        id: "offer"
      }).toString()
    });
  };

  const buttons = [
    {
      item: <ItemBtnProfile 
        action={linkHandler}
        icon={<QrCodeIcon sx={{color: gold}} />}
        text={"linkDeceased"}
      />
    },
    {
      item: <ItemBtnProfile 
        action={buyHandler}
        icon={<ShoppingCart sx={{color: gold}} />}
        text="buyKit"
      />
    },
    {
      item: <ItemBtnProfile 
        icon={<FilterVintage sx={{color: gold}} />}
        action={addBouquet}
        loading={loading}
        text="btnDepositVirtualBouquetShort"
      />
    },
    {
      item: <ItemBtnProfile 
        action={follow.followHandler}
        loading={follow.loading}
        icon={<Star sx={{color: gold}} />}
        text={follow.followed ? "followed" : "follow"}
      />
    }
  ];

  const updateProfilePicture = async (url: string) => {
    await updateHandler({
      type: "profilePicture",
      newData: url,
      idDeath: death.id,
    });

    setReplicaDeath({
      ...replicaDeath,
      profilePicture: url
    });
  };

  const ButtonsMobile = () => {
    return(<Box sx={{
      mt: 2,
      display: {xs: "flex", md: "none"},
      width: "100%",
      justifyContent: "space-between"
    }}>
      {buttons.map((el, i) => (
        <Box sx={{
          width: "25%"
        }} key={i}>
          {el.item}
        </Box>
      ))}
    </Box>);
  };

  const updateProfileHandler = async (data: IUpdateProfileDeathForm) => {
    setLoadingUpdate(true);
    try {
      const dataToSend: IDataFrontUpdateProfile<
        DocumentReference, Timestamp
      > = {
        idDeath: replicaDeath.id,
        data: {
          ...data,
          dateOfBirth: data.dateOfBirth.toJSON(),
          dateOfDeath: data.dateOfDeath.toJSON(),
          description: data.biography
        },
      };
      await postAuth(updateDeathProfileUrl, dataToSend);
      setReplicaDeath({
        ...replicaDeath,
        fullname: `${data.firstName} ${data.name}`,
        dateOfBirth: Timestamp.fromDate(data.dateOfBirth),
        dateOfDeath: Timestamp.fromDate(data.dateOfDeath),
        description: data.biography,
        firstName: data.firstName,
        name: data.firstName
      });
    } catch (error) {
      alert.defaultError();
    }
    setLoadingUpdate(false);
  };

  const UpdateFormProfile = () => {
    useEffect(() => {
      if (replicaDeath) {
        formUpdate.setValue("firstName", replicaDeath.firstName);
        formUpdate.setValue("name", replicaDeath.name);
        formUpdate.setValue("biography", replicaDeath.description);
        formUpdate.setValue("dateOfBirth", replicaDeath.dateOfBirth.toDate());
        formUpdate.setValue("dateOfDeath", replicaDeath.dateOfDeath.toDate());
      }
    }, [replicaDeath]);

    return <FormComponent
      watch={formUpdate.watch}
      formState={formUpdate.formState}
      control={formUpdate.control}
      arrayForms={updateProfileDeceasedForm}
    />;
  };

  return(
    <Box sx={{
      borderRadius: "100px",
      boxShadow: {md: "0px 3px 5px -1px rgba(0, 0, 0, 0.2)", xs: "inherit"},
      // eslint-disable-next-line max-len
      filter: {md: "drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.12))", xs: "inherit"},
    }}>
      <EditDeceased
        displayed={isAdmin}
        title={t("titleUpdateProfile")}
        content={<UpdateFormProfile />}
        actionTitle={t("btnUpdateData")}
        actionHandler={formUpdate.handleSubmit(updateProfileHandler as never)}
        actionLoading={loadingUpdate}
      />
      <CoverPicture
        idDeath={death.id}
        isAdmin={isAdmin}
        picture={death.coverPicture}
        sx={{
          width: "100%",
          height: "380px",
          backgroundColor: darkTurquoise,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderTopLeftRadius: {md: theme.spacing(1), xs: 0},
          borderTopRightRadius: {md: theme.spacing(1), xs: 0},
          borderBottom: `3px solid ${gold}`,
          position: "relative"
        }}
        sxIcon={{
          color: gold,
          fontSize: (theme: any) => theme.spacing(10)
        }}
      >
        <Box sx={{
          position: "absolute",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "100%",
          bottom: "-75px",
        }}>
          {!modalView && <Box sx={{
            width: "33%",
            justifyContent: "space-evenly",
            display: {xs: "none", md: "flex"}
          }}>
            {buttons.slice(0, 2).map((el, i) => (
              <React.Fragment key={i}>
                {el.item}
              </React.Fragment>
            ))}
          </Box>}
          
          <UserPicture
            death={replicaDeath}
            isAdmin={isAdmin}
            collection={collections.users}
            onUpload={updateProfilePicture}
            className="rounded_img"
            sx={{
              height: "150px",
              width: "150px",
              border: "3px solid white",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: darkTurquoise
            }}
            sxIcon={{
              fontSize: "75px",
              color: gold
            }}
          />

          {!modalView && <Box sx={{
            width: "33%",
            display: {xs: "none", md: "flex"},
            justifyContent: "space-evenly"
          }}>
            {buttons.slice(2, 4).map((el, i) => (
              <React.Fragment key={i}>
                {el.item}
              </React.Fragment>
            ))}
          </Box>}
        </Box>
      </CoverPicture>

      <Box sx={{
        backgroundColor: "white",
        pt: 10,
        pb: 2,
        borderBottomLeftRadius: theme => theme.spacing(1),
        borderBottomRightRadius: theme => theme.spacing(1),
      }}>
        <Typography sx={{
          textAlign: "center",
          color: gold,
          fontSize: theme => theme.spacing(2),
          fontWeight: 500
        }}>
          {t("titleNameProfileD")}
        </Typography>
        <Typography sx={{
          fontSize: theme => theme.spacing(4),
          fontWeight: 400,
          lineHeight: theme => theme.spacing(5),
          textAlign: "center"
        }}>
          {replicaDeath.fullname}
        </Typography>
        <Typography sx={{
          textAlign: "center",
          color: grayLight,
          fontSize: theme => theme.spacing(2),
          fontWeight: 500,
          mt: 1,
        }}>
          {formatDateDeceased(
            replicaDeath.dateOfBirth, replicaDeath.dateOfDeath
          )}
        </Typography>
        {religion && <Box sx={{
          display: "flex",
          justifyContent: "center",
          mt: 1,
        }}>
          <FontAwesomeIcon icon={religion.icon} color={grayDark} />
          <Typography sx={{
            ml: 1,
            color: grayDark,
            fontSize: theme => theme.spacing(2),
            fontWeight: 500
          }}>
            {t(religion.text)}
          </Typography>
        </Box>}

        <Box
          sx={{
            px: 2,
            mt: 2,
          }}
        >
          <Box sx={{
            display: "flex",
            alignItems: "center",
          }}>
            <Box sx={{
              borderBottom: `1px solid ${grayDark}`,
              width: "100%",
              height: "0.1px"
            }} />
            <Typography sx={{
              px: 2,
              textTransform: "uppercase"
            }}>
              {t("biography")}
            </Typography>
            <Box sx={{
              borderBottom: `1px solid ${grayDark}`,
              width: "100%",
              height: "0.1px",
            }} />
          </Box>

          <Box sx={{
            my: 2,
            maxHeight: "300px",
            overflowY: "scroll"
          }}>
            <Typography sx={{
              px: {xs: 2, md: 0},
              textAlign: "center",
            }}>{replicaDeath.description}</Typography>
          </Box>

          {!modalView && <ButtonsMobile />}
        </Box>
      </Box>
    </Box>
  );
};