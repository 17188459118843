import { Grid, Box, Typography, ListItem, List } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { MyContainer } from "../../components/utils/myContainer";
import { darkTurquoiseLight } from "../../ui/color";
import { Work, Phone, AlternateEmail } from "@mui/icons-material";
import { routing } from "../../router";

const Footer = () => {
  const {t} = useTranslation();
  return(
    <>
      <footer>
        <Box sx={{backgroundColor: darkTurquoiseLight}}>
          <MyContainer sx={{
            minHeight: "15vh",
            color: "white",
            py: 7
          }}>
            <Grid container spacing={6}>
              <Grid item md={4} xs={12}>
                <Box sx={{mb: 3}}>
                  <img 
                    src={require("../../media/img/logo-letter.png")}
                    alt="Absenso's logo"
                  />
                </Box>
                <Typography sx={{mb: 3}}>
                  {/* eslint-disable-next-line max-len */}
                  {t("descriptionFooter")}
                </Typography>

                <Link
                  to={routing.website.generalConditionOfUse}
                  className="link-basic"
                >
                  {t("generalOfUse")}
                </Link>
                <br />
                <Link
                  to={routing.website.privacyPolicy}
                  className="link-basic"
                >
                  {t("privacyPolicy")}
                </Link>
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography sx={{mb: 3}} component="h2">
                  {t("contact")}
                </Typography>

                <List>
                  {[
                  // eslint-disable-next-line max-len
                    {text: "Av. Reine astrid 92 – 1310 La Hulpe", icon: <Work sx={{color: "white"}} />},
                    // eslint-disable-next-line max-len
                    {text: "32 475 60 04 16", icon: <Phone sx={{color: "white"}} />},
                    // eslint-disable-next-line max-len
                    {text: "contact@absenso.com", icon: <AlternateEmail sx={{color: "white"}} />}
                  ].map((el, i) => (
                    <ListItem key={i} sx={{padding: 0, mb: 1}}>
                      <Box mr={1}>
                        {el.icon}
                      </Box>
                      <Typography sx={{color: "white"}}>
                        {el.text}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography sx={{mb: 3}} component="h2">
                  {t("link")}
                </Typography>

                {[
                  {text: t("home"), path: "#"},
                  {text: t("createAccount"), path: "#"},
                  {text: t("connectTitle"), path: "#"}
                ].map((el, i) => (
                  <Box key={i} mb={1}>
                    <Link className="link-basic" to={el.path}>{el.text}</Link>
                  </Box>
                ))}
              </Grid>
            </Grid>
          </MyContainer>
        </Box>
      </footer>
    </>
  );
};

export default Footer;