import React from "react";

// Router
import { Routes, Route, Navigate } from "react-router-dom";
import { router } from "type-absenso";
import { routing } from "..";

// Views
import LayoutAuth from "../../views/layout/layoutAuth";
import NotificationsProvider
  from "../../provider/notifications/notificationProvider";
import ProfilDeceased from "../../views/desceaded/profilDeceased";
import ScanKit from "../../views/scan/kit";
import ScanProduct from "../../views/scan/product";
import PageProvider from "../../provider/deceased/pageProvider";
import AlertProvider from "../../provider/error/alertprovider";
import Post from "../../views/desceaded/post";
import ConsultProduct from "../../views/order/consultProduct";
import PaymentSuccess from "../../views/order/paymentSuccess";
import PaymentFailed from "../../views/order/paymentFailed";
import Page from "../../views/user/page";
import PageUserProvider from "../../provider/user/pageProvider";
import PageCustomUrl from "../../views/desceaded/pageCustomUrl";
import { MyContainer } from "../../components/utils/myContainer";
import HomeWebsite from "../../views/website/home";
import Shop from "../../views/shop/shop";
import FunnelOffer1 from "../../views/order/offers/funnelOffer1";
import FunnelOffer0 from "../../views/order/offers/funnelOffer0";
import FunnelOffer2 from "../../views/order/offers/funnelOffer2";
import FunnelOfferSupplement
  from "../../views/order/offers/funnelOfferSupplement";
import UserPosts from "../../views/user/child/userposts";
import LayoutParameter from "../../views/layout/layoutParameter";
import AdministredProfile from "../../views/user/parameter/administredProfile";
import AddAdmin from "../../views/user/parameter/addAdmin";
import ModifyLinkProfile from "../../views/user/parameter/modifyLinkProfile";
import MyAccount from "../../views/user/parameter/myAccount";
import FunnelLinkProduct from "../../views/order/linkProduct/funnelLinkProduc";
import { CreateMemorial } from "../../views/website/creatememorial";
import GeneralConditionOfUse from "../../views/website/generalConditionOfuse";
import PrivacyPolicy from "../../views/website/privacyPolicy";
import QrCodeView from "../../views/website/qrcode";

const AuthRoute = () => {
  return (
    <NotificationsProvider>
      <LayoutAuth>
        <Routes>
          <Route 
            path={routing.website.index}
            element={
              <AlertProvider>
                <HomeWebsite />
              </AlertProvider>
            }
          />

          <Route 
            path={routing.website.createMemorial}
            element={
              <CreateMemorial />
            }
          />

          <Route 
            path={routing.desceaded.index}
            element={
              <PageProvider>
                <AlertProvider>
                  <MyContainer disabled="xs">
                    <ProfilDeceased />
                  </MyContainer>
                </AlertProvider>
              </PageProvider>
            }
          />

          <Route 
            path={routing.desceaded.post}
            element={
              <AlertProvider>
                <MyContainer>
                  <Post />
                </MyContainer>
              </AlertProvider>
            }
          />

          <Route 
            path={routing.scan.kit}
            element={<ScanKit />}
          />

          <Route 
            path={routing.scan.product}
            element={<ScanProduct />}
          />

          <Route 
            path={routing.order.index}
            element={
              <MyContainer>
                <ConsultProduct />
              </MyContainer>
            }
          />

          <Route 
            path={router.payment.success}
            element={<PaymentSuccess />}
          />

          <Route 
            path={router.payment.failed}
            element={<PaymentFailed />}
          />

          <Route 
            path={router.offer.funnelOffer0}
            element={<FunnelOffer0 />}
          />

          <Route 
            path={router.offer.funnelOffer1}
            element={<FunnelOffer1 />}
          />

          <Route 
            path={router.offer.funnelOffer2}
            element={<AlertProvider>
              <FunnelOffer2 />
            </AlertProvider>}
          />

          <Route 
            path={router.offer.funnelOfferSupplement}
            element={<FunnelOfferSupplement />}
          />

          <Route path={routing.user.index} element={
            <PageUserProvider>
              <AlertProvider>
                <MyContainer>
                  <Page />
                </MyContainer>
              </AlertProvider>
            </PageUserProvider>
          }>
            <Route path={routing.user.indexPage} element={<UserPosts />} />
            <Route 
              path={routing.user.parameter.index}
              element={<LayoutParameter />} 
            >
              <Route
                path=""
                element={<Navigate to={routing.user.parameter.adminProfil} />}
              />
              <Route
                path={routing.user.parameter.adminProfil}
                element={
                  <AdministredProfile />
                }
              />
              <Route
                path={routing.user.parameter.addAdmin}
                element={<AddAdmin />}
              />
              <Route
                path={routing.user.parameter.modifyLinkProfil}
                element={<ModifyLinkProfile />}
              />
              <Route
                path={routing.user.parameter.myAccount}
                element={<MyAccount />}
              />
            </Route>
          </Route>

          <Route 
            path={routing.order.linkProduct.funnel}
            element={<FunnelLinkProduct />}
          />

          <Route 
            path={routing.desceaded.page}
            element={<PageCustomUrl />}
          />

          <Route 
            path={routing.shop.index}
            element={<Shop />}
          />


          <Route 
            path={routing.website.generalConditionOfUse}
            element={<GeneralConditionOfUse />}
          />

          <Route 
            path={routing.website.privacyPolicy}
            element={<PrivacyPolicy />}
          /> 

          <Route 
            path={routing.website.qrcode}
            element={<QrCodeView />}
          />
        </Routes>
      </LayoutAuth>
    </NotificationsProvider>
  );
};

export default AuthRoute;