import { religionTypes } from "type-absenso";
import {
  faChurch, 
  faSynagogue, 
  faMosque
} from "@fortawesome/free-solid-svg-icons";


export const formatReligion = (
  religion: religionTypes | undefined
) => {
  switch(religion) {
  case "catholic":
    return {
      icon: faChurch,
      text: "catholic",
    };
  case "jewish":
    return {
      icon: faSynagogue,
      text: "jewish"
    };
  case "muslim":
    return {
      icon: faMosque,
      text: "muslim"
    };
  }
};