import React from "react";
import { useTranslation } from "react-i18next";
import { darkTurquoise, gold, gray, grayDark } from "../../../../ui/color";
import { TemplateCard } from "./utils/templateCard";
import { Business, LocationOn, Phone } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { IDeathPage } from "../../../../backend/type/frontDocument";

export const FuneralCompanyCard = ({death}: {death: IDeathPage}) => {
  const {t} = useTranslation();

  const FuneralPicture = ({picture, name, size}: {
    picture: string | null;
    name: string;
    size: string;
  }) => {
    if (picture) {
      return(
        <>
          <img
            className="full_width rounded_img"
            src={picture}
            alt={name}
          />
        </>
      );
    }

    return(
      <>
        <Business sx={{
          color: darkTurquoise,
          fontSize: size
        }} />
      </>
    );
  };

  if (death.funeralData) {
    return(
      <TemplateCard
        title={t("funeralCompanyTitle")}
        icon={<Business sx={{color: gold}} />}
        noPadding
      >
        <Box sx={{
          backgroundColor: gray,
          p: 1,
          display: "flex",
          alignItems: "center"
        }}>
          <Box sx={{
            width: "75px",
            height: "75px",
            mr: 1
          }}>
            <FuneralPicture
              size="75px"
              picture={death.funeralData.pictures}
              name={death.funeralData.companyName}
            />
          </Box>

          <Box>
            <Typography sx={{
              fontWeight: "bold",
              fontSize: theme => theme.spacing(2.5),
              lineHeight: theme => theme.spacing(3),
              width: "100%",
              letterSpacing: "0.5px",
              pb: 0.5,
              mb: 0.5,
            }}>
              {death.funeralData.companyName}
            </Typography>

            <Box sx={{
              display: "flex",
              alignItems: "center",
              my: 0.5
            }}>
              <LocationOn sx={{color: gold}} />
              <Typography sx={{
                ml: 0.5,
                color: grayDark,
                fontSize: theme => theme.spacing(2),
                lineHeight: theme => theme.spacing(2)
              }}>
                {/* eslint-disable-next-line max-len */}
                {`${death.funeralData.headOffice} ${death.funeralData.postalCodeHeadOffice} ${death.funeralData.cityHeadOffice}`}
              </Typography>
            </Box>

            <Box sx={{
              display: "flex",
              alignItems: "center",
              my: 0.5
            }}>
              <Phone sx={{color: gold}} />
              <Typography sx={{
                ml: 0.5,
                color: grayDark,
                fontSize: theme => theme.spacing(2),
                lineHeight: theme => theme.spacing(2)
              }}>{death.funeralData.phone}</Typography>
            </Box>
          </Box>
        </Box>

        {death.funeralData.description && <Box sx={{
          backgroundColor: "white",
          borderRadius: theme => theme.spacing(1),
          p: 1,
        }}>
          <Typography sx={{
            color: gold,
            fontSize: theme => theme.spacing(1.5),
            lineHeight: theme => theme.spacing(2),
            my: 1,
            textTransform: "uppercase",
            fontWeight: 800,
          }}>
            {t("description")}
          </Typography>
          <Typography sx={{
            wordWrap: "break-word"
          }}>
            {death.funeralData.description}
          </Typography>
        </Box>}
      </TemplateCard>
    );
  }
  return(<></>);
};