import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { app } from "../../../backend/config";
import { checkIfPaymentHasBeenMadeByUser } from "../../../backend/orderOffer";
import { IOrderOffersFront } from "../../../backend/type/frontDocument";

export const useCheckIfPaymentHasBeenMadeByUser = () => {
  const [offer, setOffer] = useState<IOrderOffersFront>();
  const [loading, setLoading] = useState<boolean>(false);

  const location = useLocation();
  const auth = getAuth(app);
  const params = new URLSearchParams(location.search);
  const sessionId = params.get("session_id");

  useEffect(() => {
    setLoading(true);
    (async() => {
      if (sessionId) {
        if (auth.currentUser) {
          const offer = await checkIfPaymentHasBeenMadeByUser(
            sessionId,
            auth.currentUser.uid
          );
          setOffer(offer);
        }
      }
    })();
    setLoading(false);
  }, [sessionId]);

  return {offer, loading, sessionId};
};