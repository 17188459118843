/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";

// Ui
import {
  Box,
  ButtonBase,
  CircularProgress,
  Collapse,
} from "@mui/material";
import TextFieldReactHook from "../../../../../forms/textfield";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { gold } from "../../../../../../ui/color";

// Backend
import { getComments } from "../../../../../../backend/comments";
import { ICommentsFront } from "../../../../../../backend/type/frontDocument";
import { Comment } from "../../../comment";
import { IComments, collections } from "type-absenso";
import { getAuth } from "@firebase/auth";
import { app, db } from "../../../../../../backend/config";
import { doc, DocumentReference, Timestamp } from "@firebase/firestore";
import { addComments } from "../../../../../../backend/comments";
import { useAlert } from "../../../../../../provider/error/hooks/useAlert";
import { useAskAuth } from "../../../../../../provider/auth/hooks/useAskAuth";
import { useUser } from "../../../../../../provider/auth/hooks/useUser";

export const Comments = ({
  form,
  openComment,
  idPost,
}: {
    form: UseFormReturn<FieldValues, any>,
    openComment: boolean | undefined;
    idPost: string;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>();
  const [comments, setComments] = useState<Array<ICommentsFront>>();

  const user = getAuth(app).currentUser;
  const alert = useAlert();
  const askAuth = useAskAuth();
  const currentUser = useUser();

  const handleOpen = (a: boolean) => {
    setOpen(a);
  };

  useEffect(() => {
    if (openComment !== undefined) {
      handleOpen(openComment || false);
    }
  }, [openComment]);

  useEffect(() => {
    if (openComment === true && idPost) {
      (async() => {
        setLoading(true);
        try {
          const commentsSnap = await getComments(idPost);
          setComments(commentsSnap);
        } catch (error) {
          console.error(error);
        }
        setLoading(false);
      })();
    }
  }, [openComment, idPost]);

  const addCommentFront = (comment: IComments<
      DocumentReference, Timestamp
    >, idComment: string) => {
    if (user && currentUser) {
      const commentFront: ICommentsFront = {
        ...comment,
        id: idComment,
        user: currentUser
      };

      if (comments && comments.length > 0) {
        setComments([commentFront, ...comments]);
      } else {
        setComments([commentFront]);
      }
    } 
  };

  const onSubmit = async (dataForm: {addComment: string}) => {
    if (user) {
      try {
        const dataToSend: IComments<DocumentReference, Timestamp> = {
          user: doc(db, collections.users, user.uid),
          date: Timestamp.now(),
          comment: dataForm.addComment,
        };
        const comment = await addComments(idPost, dataToSend);
        addCommentFront(dataToSend, comment.id);
        form.setValue("addComment", "");
      } catch (error) {
        alert.defaultError();
      }
    } else {
      askAuth && askAuth.openHandler();
    }
  };

  const deleteCommentFront = (uid: string) => {
    if (comments) {
      const filteredComments = comments.filter(c => {
        return c.id !== uid;
      });

      setComments(filteredComments);
    }
  };

  return(
    <>
      <Collapse in={open}>
        <Box sx={{
          display: "flex",
          p: 1,
          pb: 0,
        }}>
          <Box sx={{width: {md: "90%", xs: "80%"}}}>
            <TextFieldReactHook
              control={form.control}
              element={{
                variant: "standard",
                id: "addComment",
                name: "addComment",
                label: "addComment",
                type: "text",
                multiline: true,
                maxRows: 4,
              }}
            />
          </Box>

          <Box sx={{width: {md: "10%", xs: "20%"}}}>
            <ButtonBase 
              onClick={form.handleSubmit(onSubmit as never)}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%"
              }}>
              <FontAwesomeIcon icon={faPaperPlane} color={gold} size="2x" />
            </ButtonBase>
          </Box>
        </Box>

        {comments && comments.length > 0 &&
          <Box p={1} sx={{width: "100%"}}>
            {comments.map((el, i) => (
              <React.Fragment key={i}>
                <Comment
                  deleteCommentFront={deleteCommentFront}
                  idPost={idPost}
                  comment={el}
                />
              </React.Fragment>
            ))}
          </Box>}
        
        {loading && 
            <Box pt={2} sx={{
              display: "flex",
              justifyContent: "center"
            }}>
              <CircularProgress />
            </Box>    
        }
      </Collapse>
    </>
  );
};
