import React from "react";
import { Box, Typography, Checkbox } from "@mui/material";
import { IFuneralCompanyFront } from "../../../backend/type/frontDocument";
import { gold, grayDark } from "../../../ui/color";

export const FuneralCompanyItem = ({
  company,
  onSelect,
  selected,
}: {
    company: IFuneralCompanyFront,
    onSelect?: (checked: boolean) => void,
    selected?: boolean,
}) => {
  return(
    <>
      <Box p={1} key={company.companyName} sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        border: selected ? `1px solid ${gold}` : "none",
        borderRadius: selected ? "5px" : "0px",
      }}>
        <Box sx={{
          display: "flex",
          alignItems: "center"
        }}>
          <Box sx={{
            width: "35px",
            height: "35px",
            borderRadius: "50%",
            gap: "10px",
            mr: 1
          }}>
            <img
              className="full_width"
              src={company.pictures || ""}
              alt={`${company.companyName}'s picture`}
            />
          </Box>

          <Box>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              {company.companyName}
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "20px",
                color: grayDark
              }}
            >
              {/* eslint-disable-next-line max-len */}
              {`${company.headOffice} ${company.postalCodeHeadOffice} ${company.city}`}
            </Typography>
          </Box>
        </Box>

        <Checkbox
          onChange={(_, checked) => onSelect && onSelect(checked)}
          checked={selected}
          sx={{
            color: gold,
            "&.Mui-checked": {
              color: gold,
            },
          }}
        />
      </Box>
    </>
  );
};