import { db } from "./config";
import {
  collection,
  doc,
  DocumentReference,
  getDocs,
  query,
  Timestamp,
  where,
  getDoc,
} from "@firebase/firestore";
import { collections, IDataFrontCloseOrder } from "type-absenso";
import { IOrderOffers, IPayment } from "type-absenso/lib/db/document";
import { IOrderOffersFront } from "./type/frontDocument";
import { postAuth } from "./utils/postAuth";
import { closeOrderOfferUrl } from "./utils/url";

export const closeOrderOffer = async (data: IDataFrontCloseOrder) => {
  await postAuth(closeOrderOfferUrl, data);
};

export const checkIfPaymentHasBeenMadeByUser: (
  sessionId: string,
  uidUser: string
) => Promise<IOrderOffersFront | undefined> = async (
  sessionId: string,
  uidUser: string,
) => {
  const orderOfferRef = collection(db, collections.offers);
  const q = query(
    orderOfferRef,
    where("payment", "==", doc(db, collections.payment, sessionId))
  );
  const snaps = await getDocs(q);

  if (snaps.docs.length > 0) {
    const data = snaps.docs[0].data() as IOrderOffers<
        DocumentReference, Timestamp
    > | undefined;

    if (data) {
      const snapPayment = await getDoc(data.payment);
      const dataPayment = snapPayment.data() as IPayment | undefined;

      if (
        dataPayment &&
          data.user.id === uidUser &&
          dataPayment.status === "complete"
      ) {
        return {...data, id: snaps.docs[0].id};
      }
    }
  }

  return undefined;
};