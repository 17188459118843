import React from "react";
import { Dialog } from "@mui/material";
import { useEffect } from "react";
import { getAuth } from "firebase/auth";
import { app } from "../../../../../backend/config";
import { useState } from "react";
import { FlowCreateAccount } from "./content/flowCreateAccount";
import { ISignupForm } from "../../../../forms/asset/auth/signup";
import { FlowWhereComeFrom } from "./content/flowWhereComeFrom";
import { FlowChooseOffer } from "./content/flowChooseOffer";
import { useAuth } from "../../../../../provider/auth/hooks/useAuth";
import { useForm } from "react-hook-form";
import { FlowSelectKit } from "./content/flowSelectKit";
import { useAlert } from "../../../../../provider/error/hooks/useAlert";
import { useTranslation } from "react-i18next";
import { getKit } from "../../../../../backend/kit";
import { FlowCreateDeceased } from "./content/flowCreateDeceased";
import { useMemo } from "react";
import { IDataFormDeceased } from "../../../deceased/formDeceased";
import { useCreateDeceased }
  from "../../../../../views/order/offers/hooks/useCreateDeceased";
import {
  IDataFrontCreateDeceased,
  IDataFrontCreateDeceasedWithproduct,
  IOfferData,
  offersData
} from "type-absenso";
import { DocumentReference, Timestamp } from "firebase/firestore";
import { postAuth } from "../../../../../backend/utils/postAuth";
import { createDeceasedWithproductUrl } from "../../../../../backend/utils/url";
import { useNavigate, useSearchParams } from "react-router-dom";
import { routing } from "../../../../../router";
import { IParamsSubscribeOffer, useOffer }
  from "../../../../../views/order/offers/hooks/useOffer";
import { FlowSelectDelivery } from "./content/flowSelectDelivery";
import { TChooseForm } from "../../../order/offer/deliverymethod";
import { IFuneralCompanyFront }
  from "../../../../../backend/type/frontDocument";
import { ICheckoutFormData } from "../../../../forms/asset/order/checkout";

export const FlowContainer = ({
  open,
  step,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
  step?: number;
}) => {
  const user = getAuth(app);
  const auth = useAuth();
  const formKit = useForm();
  const formDeceased = useForm();
  const formAddress = useForm();
  const alert = useAlert();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const createDeath = useCreateDeceased({});
  const offer2Handler = useOffer(offersData[2].uid);

  const [steps, setSteps] = useState<number>(step ? step : 0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSignupForm] = useState<ISignupForm>();
  const [loadingKit, setLoadingKit] = useState<boolean>(false);
  const [loadingDeceased, setLoadingDeceased] = useState<boolean>(false);
  const [deceasedForm, setDeceasedForm] = useState<IDataFormDeceased>();
  const [chooseDeliveryForm, setChooseDeliveryForm] = useState<TChooseForm>();
  const [displayedFormDelivery, setDisplayedFormDelivery] = useState<
    TChooseForm
  >();
  const [currentReception, setCurrentReception] = useState<number>();
  const [funeralCompany, setFuneralCompany] = useState<
    IFuneralCompanyFront
  >();
  const [loadingSelectDelivery, setLoadingSelectDelivery] = useState<boolean>(
    false
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [offerChoice, setOfferChoice] = useState<string>(
    searchParams.get("offer") || ""
  );
  const { subscribeToOffer } = useOffer(offerChoice);

  useEffect(() => {
    if (user.currentUser && user.currentUser !== null) {
      setSteps(1);
    }
  }, [user]);

  const afterCreateAccountHandler = async (signupForm: ISignupForm) => {
    const offer = searchParams.get("offer");
    if (signupForm && auth && signupForm.email && signupForm.password) {
      await auth.signin(
        signupForm.email, signupForm.password, {noRedirection: true}
      );
      setSignupForm(signupForm);
    } else if (signupForm.uidGoogle && auth && offer) {
      auth.signinWithNoRedirection(1, offer);
    } else if (signupForm.uidGoogle && auth) {
      auth.signinWithNoRedirection(1);
    }
  };

  const yesHandlerComeFrom = () => {
    setSteps(2.1);
  };

  const noHandlerComeFrom = () => {
    setSteps(3);
  };

  const offerHandler = async (subscribeOffer: (
    data: IParamsSubscribeOffer
  ) => Promise<void>, offer: IOfferData) => {
    if (deceasedForm) {
      const death = createDeath.transformInDataFrontDeath(deceasedForm);

      if (offer.uid !== offersData[2].uid && death) {
        await subscribeOffer({
          death,
          deathForm: deceasedForm,
        });
      } else {
        setSteps(3.1);
      }
    }
  };

  const handleSubmitKit = async (data: {kitId: string}) => {
    setLoadingKit(true);
    try {
      const kitRes = await getKit({ id: data.kitId });
      if (kitRes && deceasedForm) {
        const death = createDeath.transformInDataFrontDeath(deceasedForm);

        if (death) {
          const dataToSend: IDataFrontCreateDeceasedWithproduct<
            DocumentReference, Timestamp
          > = {
            death,
            kitUid: kitRes.id.trim(),
          };

          const response = await postAuth(
            createDeceasedWithproductUrl, dataToSend
          );

          if (response && response.data) {
            const dataRes: {uidDeath: string} = response.data;
            alert && alert.createAlert && alert.createAlert({
              type: "success",
              message: t("linkKitDeceasedDone")
            });

            navigate(routing.desceaded.index.replace(
              ":id", dataRes.uidDeath
            ));
          }
        }
      } else {
        alert && alert.createAlert && alert.createAlert({
          type: "error",
          message: t("kitDoesntFind")
        });
      }
    } catch (error) {
      alert.defaultError();
    }
    setLoadingKit(false);
  };

  const handleCreateDeceased = async (deceased: IDataFormDeceased) => {
    setDeceasedForm(deceased);
    setLoadingDeceased(true);
    if (offerChoice) {
      const death = createDeath.transformInDataFrontDeath(deceased);
      const offer = offersData.find(od => od.uid === offerChoice);
      
      if (offer && offer.uid === offersData[1].uid && death) {
        await subscribeToOffer({
          death,
          deathForm: deceasedForm,
        });
        setLoadingDeceased(false);
      } else if (offer && offer.uid === offersData[2].uid) {
        setLoadingDeceased(false);
        setSteps(3.1);
      } else {
        death && await createDeath.actionHandler(deceased);
        setLoadingDeceased(false);
      }
    } else {
      setLoadingDeceased(false);
      setSteps(2);
    }
  };

  const previousSelectKit = () => {
    setSteps(2);  
  };


  const deliveryFormSubmit = async (
    data: ICheckoutFormData,
    death: IDataFrontCreateDeceased<DocumentReference, Timestamp> | undefined
  ) => {
    await offer2Handler.subscribeToOffer({
      deliveryData: data,
      death,
    });
  };

  const flowDeliveryHandler = async () => {
    setLoadingSelectDelivery(true);
    if (steps === 3.1) {
      if (chooseDeliveryForm === "address") {
        setDisplayedFormDelivery(chooseDeliveryForm);
        setCurrentReception(1);
        setSteps(3.2);
      } else if (chooseDeliveryForm === "funeral") {
        setDisplayedFormDelivery(chooseDeliveryForm);
        setCurrentReception(0);
        setSteps(3.2);
      }
    } else if (steps === 3.2 && deceasedForm) {
      const death = createDeath.transformInDataFrontDeath(deceasedForm);

      if (chooseDeliveryForm === "address") {
        await formAddress.handleSubmit(
          ((data: ICheckoutFormData) => {
            return deliveryFormSubmit(data, death) as never;
          }) as never)();
      } else if (chooseDeliveryForm === "funeral" && funeralCompany) {
        await offer2Handler.subscribeToOffer({
          death,
          funeral: funeralCompany.id,
        });
      }
    }
    setLoadingSelectDelivery(false);
  };

  const previousActionFlowDelivery = () => {
    setDisplayedFormDelivery(undefined);
    setCurrentReception(undefined);
    setSteps(3);
  };

  const previousWhereComeFrom = () => {
    setSteps(1);
  };

  const previousActionOffer = () => {
    if (offerChoice) {
      setSteps(1);
    } else {
      setSteps(2);
    }
  };

  const content = useMemo(() => {
    switch (steps) {
    case 0:
      return <FlowCreateAccount
        methodAfterCreation={afterCreateAccountHandler}
        isLandscape={true}
      />;
    case 1:
      return <FlowCreateDeceased 
        form={formDeceased}
        onSubmit={handleCreateDeceased}
        modalFlowView={true}
        loading={loadingDeceased}
      />;
    case 2:
      return <FlowWhereComeFrom
        yesHandler={yesHandlerComeFrom}
        noHandler={noHandlerComeFrom}
        previousAction={previousWhereComeFrom}
      />;
    case 2.1:
      return <FlowSelectKit
        previous={previousSelectKit}
        formKit={formKit}
        handleSubmitKit={handleSubmitKit}
        loading={loadingKit}
      />;
    case 3:
      return <FlowChooseOffer
        offerHandler={offerHandler}
        deathData={formDeceased.watch() as IDataFormDeceased}
        previousAction={previousActionOffer}
      />;
    case 3.1:
    case 3.2:
      return <FlowSelectDelivery
        loading={loadingSelectDelivery}
        nextAction={flowDeliveryHandler}
        form={formAddress}
        loadingAction={true}
        finalAction={() => true}
        setDisplayedDeliveryForm={(displayed) => setDisplayedFormDelivery(
          displayed
        )}
        onSelectFuneralCompany={(fc) => setFuneralCompany(fc)}
        funeralCompany={funeralCompany}
        getFromOutsideFormChoose={setChooseDeliveryForm}
        currentReceptionFromOutside={currentReception}
        displayedFormFromExternal={displayedFormDelivery}
        previousAction={previousActionFlowDelivery}
        steps={steps}
      />;
    default:
      return <></>;
    }
  }, [steps, formDeceased.watch(), loadingSelectDelivery]);

  return(
    <Dialog 
      fullWidth
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: {
            xs: "xs",
            md: "md",
          } 
        }
      }}
    >
      {content}
    </Dialog>
  );
};