import React, { ReactNode } from "react";

// Ui
import InfiniteScroll from "react-infinite-scroll-component";
import { Box, CircularProgress } from "@mui/material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator 
} from "@mui/lab";
import { darkTurquoise } from "../../../../ui/color";
import { IDeathPage, IPostFront } from "../../../../backend/type/frontDocument";
import { DotDate } from "../dotDate";
import { Memorie } from "./post/memorie";
import { useState } from "react";
import { useEffect } from "react";
import { useAlert } from "../../../../provider/error/hooks/useAlert";
import { postAuth } from "../../../../backend/utils/postAuth";
import { deletePostUrl } from "../../../../backend/utils/url";
import { IDataFrontDeletePost } from "type-absenso";

export const TimelinePost = ({
  death,
  posts,
  getData,
  hasMore,
  loading,
}: {
  death: IDeathPage;
  posts: Array<IPostFront> | undefined;
  getData: (infinite?: boolean) => Promise<void>;
  hasMore: boolean;
  loading: boolean;
}) => {
  let lastGet: Date = new Date();
  const alert = useAlert();
  const [replicaPosts, setReplicaPosts] = useState<
    Array<IPostFront> | undefined
  >(posts);

  useEffect(() => {
    if (posts) {
      setReplicaPosts(posts.filter(p => p.type !== "litCandle"));
    }
  }, [posts]);

  const deletePost = async (idPost: string) => {
    try {
      const dataToSend: IDataFrontDeletePost = {
        idDeath: death.id,
        idPost,
      };
      await postAuth(deletePostUrl, dataToSend);
      setReplicaPosts(replicaPosts && replicaPosts.filter(rp => {
        return rp.id !== idPost || rp.type !== "litCandle";
      }));
    } catch (error) {
      alert.defaultError();
    }
  };

  const LoadingComponent = ({loading}: {loading: boolean}) => {
    if (loading) {
      return(
        <Box sx={{display: "flex", justifyContent: "center", py: 5}}>
          <CircularProgress />
        </Box>
      );
    }

    return <></>;
  };

  const Infinite = ({children}: {children: ReactNode}) => {
    return(
      <InfiniteScroll
        dataLength={(replicaPosts && replicaPosts.length) || 0}
        next={async() => {
          if (new Date().valueOf() - lastGet.valueOf() > 100) {
            await getData(true);
            lastGet = new Date();
          }
        }}
        pullDownToRefreshThreshold={200}
        hasMore={hasMore}
        loader={<LoadingComponent loading={true} />}
      >
        {children}
      </InfiniteScroll>
    );
  };

  return(
    <>
      {!replicaPosts && <LoadingComponent loading={loading} />}

      {/* Desktop view */}
      {replicaPosts && 
      <Timeline
        position="alternate"
        sx={{
          display: {xs: "none", md: "block"},
        }}
      >
        <Infinite>
          {replicaPosts.map((el, i) => {
            return (
              <TimelineItem key={i} position="right" sx={{
                pl: {xs: 0, md: 5},
                "&::before": {
                  content: "none"
                }}}>
                <TimelineSeparator>
                  <TimelineConnector 
                    sx={{
                      backgroundColor: darkTurquoise
                    }}
                  />
                  <TimelineDot
                    sx={{
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      padding: 0,
                      border: "none",
                      color: darkTurquoise,
                      borderRadius: "0px",
                      textAlign: "center",
                    }}>
                    <DotDate date={el.dateOfRemembrance} />
                  </TimelineDot>
                  <TimelineConnector sx={{
                    backgroundColor: darkTurquoise
                  }} />
                </TimelineSeparator>
                <TimelineContent sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  py: 2,
                }}>
                  <Memorie
                    post={el}
                    death={death}
                    deletePostHandler={() => deletePost(el.id)}
                  />
                </TimelineContent>
              </TimelineItem>
            );
          })}
          
        </Infinite>
      </Timeline>}
      
      {/* Mobile view */}
      {replicaPosts && <Box sx={{
        display: {xs: "flex", md: "none"},
        justifyContent: "center",
        flexDirection: "column",
        mt: {xs: 2, md: 0},
      }}>
        <>
          <Infinite>
            {replicaPosts.map((el, i) => (
              <React.Fragment key={i}>
                <div style={{height: "100%"}}>
                  <Box sx={{px: 2}}>
                    <DotDate date={el.dateOfRemembrance} />
                  </Box>
                  <Memorie
                    post={el}
                    death={death}
                    deletePostHandler={() => deletePost(el.id)}
                  />
                </div>
              </React.Fragment>
            ))}           
          </Infinite>
        </>
      </Box>}
    </>
  );
};