import {
  query,
  where,
  limit,
  collection,
  getDocs, 
  DocumentReference
} from "firebase/firestore";
import { collections, IFuneralCompany } from "type-absenso";
import { db } from "./config";
import { IFuneralCompanyFront } from "./type/frontDocument";

const funeralCompanyRef = collection(db, collections.funeralCompany);

export const searchFuneralCompany = async (search: string) => {
  const funeralCompanies: Array<IFuneralCompanyFront> = [];
  const q = query(
    funeralCompanyRef,
    where("companyName", ">=", search.toUpperCase()),
    where("companyName", "<=", search.toLowerCase()+"\uf8ff"),
    limit(10)
  );

  const snaps = await getDocs(q);

  for await (const snap of snaps.docs) {
    const data = snap.data() as IFuneralCompany<DocumentReference>;
    funeralCompanies.push({
      ...data, 
      id: snap.id
    });
  }

  return funeralCompanies;
};

export const getAllFuneralCompanies = async() => {
  const funeralCompanies: Array<IFuneralCompanyFront> = [];
  const q = query(
    funeralCompanyRef,
    limit(20)
  );

  const snaps = await getDocs(q);

  for await (const snap of snaps.docs) {
    const data = snap.data() as IFuneralCompany<DocumentReference>;
    funeralCompanies.push({
      ...data, 
      id: snap.id
    });
  }

  return funeralCompanies;
};