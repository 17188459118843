import React from "react";
import { Box } from "@mui/material";
import { ButtonBase } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { gold } from "../../../ui/color";
import { grey } from "@mui/material/colors";

export const EditBtn = ({
  onClick,
  isAdmin,
}: {
    onClick: () => void;
    isAdmin: boolean | undefined;
}) => {
  if (isAdmin) {
    return(
      <Box sx={{
        display: {xs: "none", md: "inherit"},
        position: "absolute",
        top: -10,
        right: -10,
        zIndex: 100
      }}>
        <ButtonBase 
          onClick={onClick}
          sx={{
            height: 30,
            width: 30,
            backgroundColor: "white",
            borderRadius: "50%",
            boxShadow: `0px 4px 8px ${grey[500]}`,
          }}>
          <FontAwesomeIcon icon={faPen} color={gold} />
        </ButtonBase>
      </Box>
    );
  }

  return<></>;
};