/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CheckCircle,
  ChevronRight,
  Edit,
  InsertPhoto,
  VideoCameraFront
} from "@mui/icons-material";
import React, { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { darkTurquoise, gold, grayBlue } from "../../../../ui/color";
import FormComponent from "../../../forms/formcomponent";
import { TemplateCard } from "./utils/templateCard";
import { Box, ButtonBase, CircularProgress, Typography } from "@mui/material";
import ImageCropper from "../../imagecropper";
import { useState } from "react";
import { UploadVideo } from "../../uploadVideo";
import { useRef } from "react";
import { useAlert } from "../../../../provider/error/hooks/useAlert";
import { collections, IDataFrontAddPost } from "type-absenso";
import { doc, DocumentReference, Timestamp } from "firebase/firestore";
import { IDeathPage, IPostFront } from "../../../../backend/type/frontDocument";
import { uploadFile } from "../../../../backend/utils/uploadFile";
import { postAuth } from "../../../../backend/utils/postAuth";
import { addPostUrl } from "../../../../backend/utils/url";
import { useAskAuth } from "../../../../provider/auth/hooks/useAskAuth";
import { useUser } from "../../../../provider/auth/hooks/useUser";
import { db } from "../../../../backend/config";

export const CreatorMemories = ({
  death,
  addPost
}: {
  death: IDeathPage,
  addPost: (newPost: IPostFront) => void;
}) => {
  const {t} = useTranslation();
  const form = useForm();
  const alert = useAlert();
  const askAuth = useAskAuth();
  const user = useUser();
  const [urlCrop, setUrlCorp] = useState<string>();
  const [crop, setCrop] = useState<File>();
  const [video, setVideo] = useState<File>();
  const [loading, setLoading] = useState<boolean>(false);

  const btnVideoRef = useRef<any>(null);
  const btnImageRef = useRef<any>(null);

  const onVideo = (video: File) => {
    if (askAuth.userIsConnected()) {
      if (crop) {
        setCrop(undefined);
        setUrlCorp(undefined);
      }

      setVideo(video);
    } else {
      askAuth && askAuth.openHandler();
    }
  };

  const onCrop = (crop: File) => {
    if (askAuth.userIsConnected()) {
      if (video) {
        setVideo(undefined);
      }
  
      setCrop(crop);
    } else {
      askAuth && askAuth.openHandler();
    }
  };

  const onSubmit = async (data: {
    description: string;
    dateOfMemories: Date;
  }) => {
    if (askAuth.userIsConnected()) {
      setLoading(true);
      try {
        const dataToSend: IDataFrontAddPost<DocumentReference, Timestamp> = {
          deceased: death.id,
          dateOfRemembrance: data.dateOfMemories.toJSON(),
          media: null,
          type: "default",
          description: data.description,
        };

        const currentFile = (crop || video);

        if (currentFile) {
          const url = await uploadFile({
            file: currentFile,
            collection: collections.post,
          });

          dataToSend.media = url;

          if (video) {
            dataToSend.type = "video";
          } else if (crop) {
            dataToSend.type = "pictures";
          }
        }

        const res = await postAuth(addPostUrl, dataToSend);
        form.reset();
        if (user && res && res.data) {
          addPost({
            user: doc(db, collections.users, user.id),
            deceased: doc(db, collections.death, death.id),
            date: Timestamp.now(),
            type: dataToSend.type,
            media: dataToSend.media,
            description: dataToSend.description,
            dateOfRemembrance: Timestamp.fromDate(data.dateOfMemories),
            id: res.data.id || "",
            userData: user,
            likeId: undefined,
            liked: undefined,
          });
        }
      } catch (error) {
        alert.defaultError();
      }
      setLoading(false);
    } else {
      askAuth && askAuth.openHandler();
    }
  };

  const BtnInputFile = ({
    children,
    onClick,
    uploaded,
  }: {
    children: ReactNode,
    onClick: () => void;
    uploaded: boolean;
  }) => {
    return(
      <ButtonBase 
        onClick={onClick}
        sx={{
          backgroundColor: darkTurquoise,
          width: "100%",
          p: 1,
          borderRadius: theme => theme.spacing(0.5),
          mx: 0.5,
          minHeight: {xs: "60px", md: "inherit"}
        }}>
        {uploaded && <Box sx={{
          position: "absolute",
          right: -8,
          top: -8,
        }}>
          <CheckCircle sx={{
            color: gold,
          }} />
        </Box>}
        {children}
      </ButtonBase>
    );
  };

  return(
    <TemplateCard
      icon={<Edit sx={{color: gold}} />}
      title={t("postMemoriesTitle")}
      noPadding
    >
      <Box sx={{p: 1}}>
        <FormComponent 
          py={0}
          watch={form.watch}
          formState={form.formState}
          control={form.control}
          arrayForms={[
            {
              type: "TextField",
              elementTextField: {
                name: "description",
                label: "description",
                id: "description",
                type: "text",
                variant: "standard",
                multiline: true,
                rows: 4
              }
            },
            {
              type: "DatePicker",
              elementDatePicker: {
                name: "dateOfMemories",
                label: "chooseDateOfMemories",
                maxDate: new Date(),
                variant: "filled"
              }
            }
          ]}
        />
        <Box sx={{
          display: "flex",
          width: "100%",
          pt: 1
        }}>
          <ImageCropper
            inputRef={btnImageRef}
            urlCrop={urlCrop}
            setUrlCrop={setUrlCorp}
            onCrop={onCrop}
            aspectRatio={null}
            customComponent={
              <BtnInputFile
                uploaded={Boolean(crop)}
                onClick={() => btnImageRef.current.click()}
              >
                <InsertPhoto sx={{color: "white"}} />
              </BtnInputFile>
            }
          />
          <UploadVideo
            inputRef={btnVideoRef}
            mx={1}
            onVideo={onVideo}
          >
            <BtnInputFile
              uploaded={Boolean(video)}
              onClick={() => btnVideoRef.current.click()}
            >
              <VideoCameraFront sx={{color: "white"}} />
            </BtnInputFile>
          </UploadVideo>
          <ButtonBase 
            onClick={form.handleSubmit(onSubmit as never)}
            sx={{
              mx: 0.5,
              p: 1,
              backgroundColor: loading ? grayBlue : gold,
              width: "100%",
              borderRadius: theme => theme.spacing(0.5)
            }}>
            <Box sx={{
              display: "flex",
              alignItems: "center"
            }}>
              <>
                {loading ? 
                  <CircularProgress size={20} /> :
                  <>
                    <Typography sx={{
                      color: "white",
                      textTransform: "uppercase",
                      fontWeight: "500"
                    }}>
                      {t("publishMemories")}
                    </Typography>

                    <ChevronRight sx={{color: "white"}} />
                  </>
                }
              </>
            </Box>
          </ButtonBase>
        </Box>
      </Box>
    </TemplateCard>
  );
};