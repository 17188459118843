import React, {useState} from "react";

// Ui
import {
  Box,
  ButtonBase,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { red } from "@mui/material/colors";
import { faTrash} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { darkTurquoise, gold } from "../../../ui/color";

export const DeleteBtn = ({
  isAdmin,
  deleteAction
}: {
    isAdmin: boolean | undefined;
    deleteAction: () => Promise<void>;
}) => {
  const {t} = useTranslation();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>();

  const openHandler = () => setOpenDeleteModal(!openDeleteModal);

  const deleteHandler = async () => {
    setLoading(true);
    await deleteAction();
    setLoading(false);
  };

  if (isAdmin) {
    return(
      <>
        <Dialog
          open={openDeleteModal}
          onClose={openHandler}
          fullWidth={true}
        >
          <DialogTitle>
            <Typography sx={{
              color: darkTurquoise
            }}>
              {t("titleDeleteBtn")}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("contentDeleteBtn")}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <LoadingButton
              loading={loading}
              onClick={deleteHandler}
              variant="contained"
              sx={{
                height: "auto",
                backgroundColor: gold,
              }}>
              {t("delete")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <Box sx={{
          position: "absolute",
          top: {xs: -10, md: -10},
          right: {xs: 0, md: -10},
        }}>
          <ButtonBase 
            onClick={openHandler}
            sx={{
              backgroundColor: red[500],
              p: 1,
              borderRadius: "50%",
            }}>
            <FontAwesomeIcon
              color="white"
              size="1x"
              icon={faTrash}
            />
          </ButtonBase>
        </Box>
      </>
    );
  }
  return(<></>);
};