/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

// Type
import { ICommentsFront } from "../../../backend/type/frontDocument";

// Ui
import { Box, Typography } from "@mui/material";
import {
  darkTurquoise,
  darkTurquoiseRgb,
  gold,
  grayDark
} from "../../../ui/color";
import { useContext } from "react";
import { PageContext } from "../../../provider/deceased/pageProvider";
import { DeleteBtn } from "./deleteBtn";
import { useAlert } from "../../../provider/error/hooks/useAlert";
import { postAuth } from "../../../backend/utils/postAuth";
import { IDataFrontDeleteComment } from "type-absenso";
import { deleteCommentsUrl } from "../../../backend/utils/url";
import { UserPicture } from "../user/userPicture";

export const Comment = ({
  comment,
  idPost,
  deleteCommentFront
}: {
    comment: ICommentsFront,
    idPost: string;
    deleteCommentFront?: (uid: string) => void;
}) => {
  const pageHandler = useContext(PageContext);
  const alert = useAlert();

  const deleteComment = async () => {
    if (deleteCommentFront) {
      try {
        const dataToSend: IDataFrontDeleteComment = {
          idPost,
          idComment: comment.id,
        };
        await postAuth(deleteCommentsUrl, dataToSend);
        deleteCommentFront(comment.id);
      } catch (error) {
        alert.defaultError();
      }
    }
  };

  return(
    <Box sx={{
      display: "flex",
      width: "100%",
      mt: 1
    }}>
      <Box sx={{
        backgroundColor: darkTurquoiseRgb,
        p: 1,
        borderRadius: 1,
        position: "relative",
        width: "100%",
      }}>
        <DeleteBtn
          isAdmin={(pageHandler && pageHandler.isAdmin) || false}
          deleteAction={deleteComment}
        />
        <Box sx={{
          display: "flex",
          alignItems: "center"
        }}>
          <UserPicture 
            user={comment.user}
            className="rounded_img"
            sx={{
              width: (theme: any) => theme.spacing(5),
              height: (theme: any) => theme.spacing(5),
              borderRadius: "50%",
              border: `1px solid ${gold}`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
            sxIcon={{
              fontSize: (theme: any) => theme.spacing(4),
              color: darkTurquoise,
            }}
          />

          <Box sx={{ml: 1}}>
            <Typography sx={{
              fontWeight: 500,
              fontSize: theme => theme.spacing(1.5),
            }}>
              {`${comment.user.firstName} ${comment.user.name}`}
            </Typography>
            <Typography sx={{
              fontWeight: 400,
              color: grayDark,
              fontSize: theme => theme.spacing(1)
            }}>
              {comment.date.toDate().toLocaleDateString("fr-EU")}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: theme => theme.spacing(5) }} />
          <Typography sx={{
            fontSize: "12px",
            wordBreak: "break-all",
            ml: theme => theme.spacing(1),
          }}>
            {comment.comment}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};