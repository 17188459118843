import React, { useEffect, useState, createContext, ReactNode } from "react";

// I18n
import { useTranslation } from "react-i18next";

// Error
import { useAlert } from "../error/hooks/useAlert";

// Firebase
import { app } from "../../backend/config";
import {
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  User,
} from "firebase/auth";

// Router
import UnAuthRoute from "../../router/component/unAuthRoute";
import AuthRoute from "../../router/component/authroute";

// Backend
import { getUser } from "../../backend/user";
import { IUserFront } from "../../backend/type/frontDocument";
import { routing } from "../../router";
import { GoogleAuthProvider } from "firebase/auth";
import { useOffer } from "../../views/order/offers/hooks/useOffer";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import { offersData } from "type-absenso";

export type goalSigninGoogle = "connexion" | "registration"

interface IPropsAuth {
  signin: (
    email: string,
    password: string,
    metadata?: {noRedirection?: boolean}
  ) => void;
  userData: IUserFront | undefined;
  signout: () => Promise<void>;
  signinWithGoogle: (goal: goalSigninGoogle) => Promise<User>;
  resetPassword: (email: string) => void;
  loading: boolean;
  signinWithNoRedirection: (step: number, offerId?: string) => void;
}

export const AuthContext = createContext<IPropsAuth | null>(null);

const AuthProvider = () => {
  const {t} = useTranslation();
  const alert = useAlert();
  const { offerUid } = useOffer();
  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [connected, setConnected] = useState<boolean>();
  const [userData, setUserData] = useState<IUserFront>();
  const [loading, setLoading] = useState<boolean>(false);

  const memorialAction = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get("memorial") === "create";
  }, [location.search]);

  useEffect(() => {
    onAuthStateChanged(getAuth(app), async (user) => {
      if (user) {
        const userInDb = await getUser({
          uid: user.uid,
        });

        if (userInDb) {
          setUserData(userInDb);
          setConnected(true);
        } else {
          setConnected(false);
        }
      } else {
        setConnected(false);
      }
    });
  }, []);

  const signinWithNoRedirection = (step: number, offerId?: string) => {
    const { protocol, host } = window.location;
    let newUrl = "";
    if (offerId) {
      // eslint-disable-next-line max-len
      newUrl = `${protocol}//${host}/#/?step=${step.toString()}&offer=${offerId}&open=true`;
    } else {
      newUrl = `${protocol}//${host}/#/?step=${step.toString()}&open=true`;
    }
    window.history.pushState({path: newUrl}, "", newUrl);   
    window.location.reload();
  };

  const goToOffer = () => {
    const offer = offersData.find(od => od.uid === offerUid);

    if (offer) {
      navigate(offer.url);
    }
  };

  const signinWithGoogle = async (goal: goalSigninGoogle) => {
    const auth = getAuth(app);
    const result = await signInWithPopup(auth, provider);

    if (goal === "connexion" && offerUid) {
      goToOffer();
    } else if (goal === "connexion" && memorialAction) {
      navigate(
        routing.website.createMemorial
      );
    } else if (goal !== "registration") {
      const user = getAuth(app).currentUser;
      if (user) {
        navigate(
          routing.user.index.replace(
            ":id",
            user.uid as string
          )
        );
      }
    }
    
    return result.user;
  };

  const signin = async (
    email: string,
    password: string,
    metadata?: {noRedirection?: boolean}
  ) => {
    try {
      await signInWithEmailAndPassword(getAuth(app), email, password);
      const offer = searchParams.get("offer");
      if (!metadata) {
        if (offerUid) {
          goToOffer();
        } else if (memorialAction) {
          navigate(
            routing.website.createMemorial
          );
        } else {
          const user = getAuth(app).currentUser;
          if (user) {
            navigate(
              routing.user.index.replace(
                ":id",
                  user.uid as string
              )
            );
          }
        }
      } else {
        offer ? signinWithNoRedirection(0, offer) :
          signinWithNoRedirection(0);
      }
    } catch (error) {
      if (alert && alert.createAlert) {
        alert.createAlert({
          type: "error", message: t("errorSignin")
        });
      }
    }
  };

  const signout = async () => {
    if (getAuth(app)) {
      await getAuth(app).signOut();
      window.location.href = routing.website.index;
    }
  };

  const resetPassword = async (email: string) => {
    if (getAuth(app)) {
      setLoading(true);
      try {
        await sendPasswordResetEmail(getAuth(app), email);
        if (alert && alert.createAlert) {
          alert.createAlert({
            type: "success",
            message: t("successResetPassword")
          });
        }
      } catch (error) {
        if (alert) {
          alert.defaultError();
        }
      }
      setLoading(false);
    }
  };

  const Template = ({children}: {children: ReactNode}) => {
    return(
      <AuthContext.Provider value={{
        signin,
        userData,
        signout,
        signinWithGoogle,
        resetPassword,
        loading,
        signinWithNoRedirection,
      }}>
        {children}
      </AuthContext.Provider>
    );
  };

  if (connected === false && !userData) {
    return(
      <Template>
        <UnAuthRoute />
      </Template>
    );
  }

  if (
    connected === true &&
    userData &&
    userData.type.includes("user")
  ) {
    return(
      <Template>
        <AuthRoute />
      </Template>
    );
  }

  return(
    <>
    </>
  );
};

export default AuthProvider;
