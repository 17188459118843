import React from "react";

// Ui
import {
  Box,
  Typography,
  List,
  CircularProgress,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";

// Hooks
import { useTranslation } from "react-i18next";
import {
  INotificationFront,
} from "../../../backend/type/frontDocument";
import { Notifications } from "./notifications";
import { backgroundOpacity, darkTurquoiseLight } from "../../../ui/color";

export const NotificationTooltip = ({
  notifComplete,
  sizeNotif,
  hasMore,
  getNotifications,
}: {
  notifComplete: Array<INotificationFront> | undefined;
    match?: boolean;
    sizeNotif: number;
    hasMore: boolean;
    getNotifications: () => void;

}) => {
  const {t} = useTranslation();

  return(
    <>
      <InfiniteScroll
        dataLength={(notifComplete && notifComplete.length) || 0}
        next={() => true}
        hasMore={hasMore}
        loader={<></>}
        scrollableTarget="container-notifications"
      >
        <Box sx={{
          width: "100%",
          height: "100%",
          backgroundColor: backgroundOpacity
        }}>
          <Box sx={{
            backgroundColor: darkTurquoiseLight,
            p: 2,
          }}>
            <Typography
              sx={{
                fontWeight: "bolder",
                fontSize: theme => theme.spacing(3),
                color: "white",
                textAlign: "left",
              }}
              component="h1"
            >
              {t("notifications")}
            </Typography>
          </Box>
          {notifComplete ? 
            <List sx={{m: 1}}>
              {notifComplete.map((el, i) => (
                <React.Fragment key={i}>
                  <Notifications notifications={el} />
                </React.Fragment>
              ))}
            </List> : <List>
              {new Array(sizeNotif).join(".").split(".").map((el, i) => (
                <React.Fragment key={i}>
                  <Notifications />
                </React.Fragment>
              ))}
            </List>}
        </Box>
      </InfiniteScroll>
    </>
  );
};