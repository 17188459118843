import { useContext } from "react";
import { PageContext } from "../pageProvider";

export const usePage = () => {
  const deathHandler = useContext(PageContext);

  if (deathHandler) {
    return deathHandler;
  }

  return undefined;
};