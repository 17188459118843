import React from "react";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import FormComponent from "../../../forms/formcomponent";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { IResFormWhereComeFrom } 
  from "../../../../views/website/creatememorial";

export const WhereComeFrom = ({
  form,
  onSubmit,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<FieldValues, any>;
  onSubmit: (data: IResFormWhereComeFrom) => void; 
}) => {
  const {t} = useTranslation();

  return(
    <Box sx={{
      display: "flex",
      width: "100%",
      justifyContent: "center"
    }}>
      <Box sx={{
        boxShadow: `0px 4px 12px ${grey[400]}`,
        borderRadius: theme => theme.spacing(1),
        width: {xs: "100%", md: "60%"},
        p: 2,
      }}>
        <Typography sx={{
          fontSize: theme => theme.spacing(3),
          fontWeight: 400,
          lineHeight: theme => theme.spacing(4),
          mb: 2,
        }}>
          {t("alreadyBought")}
        </Typography>

        <Box sx={{mb: 2}}>
          <FormComponent 
            watch={form.watch}
            control={form.control}
            formState={form.formState}
            arrayForms={[
              {
                type: "CheckBox",
                elementCheckBox : {
                  name: "yesBoughtCandle",
                  required: false,
                  label: "yesBoughtCandle"
                }
              },
              {
                type: "CheckBox",
                elementCheckBox : {
                  name: "noBoughtCandle",
                  required: false,
                  label: "noBoughtCandle"
                }
              },
            ]}
          />
        </Box>

        <LoadingButton 
          onClick={form.handleSubmit(onSubmit)}
          sx={{
            width: "100%"
          }}
          variant="contained"
        >
          {t("next")}
        </LoadingButton>
      </Box>
    </Box>
  );
};