import React, { ReactNode } from "react";
import { Box } from "@mui/material";
import { Carousel } from "../website/carouselTestimony";

export const TemplateAuth = ({
  content,
  notDisplayedBgImage
}: {
    content: ReactNode;
    notDisplayedBgImage?: boolean;
}) => {
  return(
    <>
      <Box sx={{
        minHeight: !notDisplayedBgImage ? "100vh" : "auto",
        width: "100%",
        display: "flex",
      }}>
        {content}

        {!notDisplayedBgImage && <Box 
          sx={{
            flex: "1 1 0px",
            display: {md: "block", xs: "none"},
            width: "50%",
            backgroundImage: `url(${require(
              "../../../media/img/auth-img.png"
            )})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
          }}
        >
          <Box p={6} sx={{
            height: "100%", 
            display: "flex",
            alignItems: "end"
          }}>
            <Carousel 
              withoutPicture={true}
              withoutCarouselMarker={true}
            />
          </Box>
        </Box>}
      </Box>
    </>
  );
};