import { collections, IStock } from "type-absenso";
import { collection, doc, DocumentReference, getDoc } from "firebase/firestore";
import { db } from "./config";

export const getStock = async ({
  id
}: {
    id: string;
}) => {
  const stockRef = collection(db, collections.stock);
  const snap = await getDoc(doc(stockRef, id));

  if (snap.exists()) {
    const data = snap.data() as IStock<DocumentReference>;

    return {...data, id: snap.id};
  } else {
    return undefined;
  }
};