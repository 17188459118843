import { Button } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { myAccountParameterFrom } 
  from "../../../components/forms/asset/user/myaccountparameter";
import FormComponent from "../../../components/forms/formcomponent";
import { useAuth } from "../../../provider/auth/hooks/useAuth";
import { useUser } from "../../../provider/auth/hooks/useUser";
import { gold } from "../../../ui/color";
import LayoutActionParameter from "../../layout/layoutActionParameter";

const MyAccount = () => {
  const {t} = useTranslation();
  const form = useForm();
  const auth = useAuth();
  const user = useUser();

  useEffect(() => {
    if (user) {
      form.setValue("email", user.email);
      form.setValue("firstName", user.firstName);
      form.setValue("name", user.name);
    }
  }, [user]);

  return(
    <LayoutActionParameter
      title={t("myAccount")}
    >
      <FormComponent
        watch={form.watch}
        control={form.control}
        formState={form.formState}
        arrayForms={myAccountParameterFrom}
      />

      <Button 
        onClick={() => auth && auth.signout()}
        variant="contained" 
        sx={{
          mt: 2,
          height: "fit-content",
          backgroundColor: gold
        }}>
        {t("signout")}
      </Button>
    </LayoutActionParameter>
  );
};

export default MyAccount;
