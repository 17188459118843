import { faBox, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckCircle } from "@mui/icons-material";
import { Box, ButtonBase, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { darkTurquoise, gold } from "../../../../../../ui/color";
import { FlowContentTemplate } from "../flowContentTemplate";

export const FlowWhereComeFrom = ({
  yesHandler,
  noHandler,
  previousAction,
}: {
    yesHandler: () => void;
    noHandler: () => void;
    previousAction: () => void;
}) => {
  const {t} = useTranslation();

  const [stateChoose, setStateChoose] = useState<"yes" | "no">();

  const ITEMS = [
    {
      icon: faBox,
      text: ["yes", "gotBox"],
      action: () => setStateChoose("yes"),
      stateChosen: "yes",
    },
    {
      icon: faCircleXmark,
      text: ["no", "dontHaveBox"],
      action: () => setStateChoose("no"),
      stateChosen: "no",
    }
  ];

  const nextHandler = () => {
    if (stateChoose === "yes") {
      yesHandler();
    } else if (stateChoose === "no") {
      noHandler();
    }
  };

  return(
    <FlowContentTemplate 
      title={t("thirdStep")}
      subtitle={t("titleWhereComeFrom")}
      displayedBtn={true}
      nextAction={nextHandler}
      previousAction={previousAction}
      disableNextBtn={stateChoose === undefined}
    >
      <Box sx={{
        display: "flex",
        alignItems: "stretch",
      }}>
        {ITEMS.map((it, i) => (
          <ButtonBase 
            onClick={it.action}
            key={i} 
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              flex: "1 1 350px",
              p: 2,
              width: "50%",
              borderRadius: theme => theme.spacing(1),
              border: `${
                it.stateChosen === stateChoose ? "1px" : "0.3px"
              } solid ${
                it.stateChosen === stateChoose ? gold : darkTurquoise
              }`,
              mr: i === 0 ? 1 : 0,
              ml: i === 1 ? 1 : 0,
            }}>
            {it.stateChosen === stateChoose && <CheckCircle sx={{
              position: "absolute",
              top: 3,
              right: 3,
              color: gold
            }} />}

            <Box sx={{pb: 1}}>
              <Box sx={{
                height: 50
              }}>
                <FontAwesomeIcon
                  color={darkTurquoise}
                  size="3x"
                  icon={it.icon}
                />
              </Box>
            </Box>

            <Box sx={{
              minHeight: theme => theme.spacing(4),
            }}>
              <Typography>
                <Typography component="span" sx={{
                  fontWeight: "bold"
                }}>
                  {t(it.text[0])}
                </Typography>

                {t(it.text[1])}
              </Typography>
            </Box>
          </ButtonBase>
        ))}
      </Box>
    </FlowContentTemplate>
  );
};