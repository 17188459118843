import React from "react";
import { Box, Typography } from "@mui/material";
import { gray, grayBlue } from "../../ui/color";
import { ReactNode } from "react";

const LayoutActionParameter = ({
  title,
  children,
  btn,
}: {
    title: string;
    children: ReactNode;
    btn?: ReactNode;
}) => {
  return(
    <Box sx={{
      width: "100%"
    }}>
      <Box sx={{
        borderBottom: `1px solid ${grayBlue}`,
        p: 2,
        width: "100%",
      }}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: theme => theme.spacing(2),
            lineHeight: theme => theme.spacing(3),
            letterSpacing: "0.4px",
          }}
        >
          {title}
        </Typography>
      </Box>

      <Box sx={{
        p: 2,
        maxHeight: theme => theme.spacing(50),
        minHeight: theme => theme.spacing(50),
        overflowY: "scroll"
      }}>
        {children}
      </Box>

      {btn && <Box sx={{
        p: 2,
        backgroundColor: gray
      }}>
        {btn}
      </Box>}
    </Box>
  );
};

export default LayoutActionParameter;
