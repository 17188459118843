import React from "react";

// Ui
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";

// Backend
import { SxProps, Theme } from "@mui/system";
import { usePlaceBouquet } from "../../hooks/usePlaceBouquet";
import { IDeathPage } from "../../../../../../backend/type/frontDocument";


export const BtnPlaceBouquet = ({
  death,
  sx,
  variant,
  action,
}: {
        death: IDeathPage;
        sx?: SxProps<Theme>,
        variant: string;
        action?: () => void;
    }) => {
  const {t} = useTranslation();
  const { addBouquet, loading } = usePlaceBouquet(death, action);

  return(
    <LoadingButton
      sx={sx}
      onClick={addBouquet}
      variant={variant as never}
      loading={loading}
    >
      {t("placeBouquet")}
    </LoadingButton>
  );
};