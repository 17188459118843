import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { offersData } from "type-absenso";
import { BtnOfferContent, TemplateOffer }
  from "../../../components/utils/order/offer/templateoffer";
import { useCheckIfPaymentHasBeenMadeByUser } from "../hooks/useCheckpayment";
import { Box } from "@mui/material";
import {
  FormDeceased,
  IDataFormDeceased
} from "../../../components/utils/deceased/formDeceased";
import { useCreateDeceased } from "./hooks/useCreateDeceased";
import { useOffer } from "./hooks/useOffer";
import { useAlert } from "../../../provider/error/hooks/useAlert";
import { useState } from "react";

const FunnelOffer1 = () => {
  const {t} = useTranslation();
  const form = useForm();
  const alert = useAlert();
  const {offer, sessionId} = useCheckIfPaymentHasBeenMadeByUser();
  const { subscribeToOffer } = useOffer(offersData[1].uid);
  const [loadingAction, setLoadingAction] = useState<boolean>(false);
  const { 
    actionHandler,
    transformInDataFrontDeath
  } = useCreateDeceased({
    offer, sessionId: sessionId || undefined
  });

  const submitHandler = async (deathData: IDataFormDeceased) => {
    setLoadingAction(true);
    try {
      const death = transformInDataFrontDeath(deathData);
      await subscribeToOffer({
        death
      });
    } catch (error) {
      alert && alert.createAlert && alert.createAlert({
        type: "error",
        message: t("errorRequiredFields")
      });
    }
    setLoadingAction(false);
  };

  return(
    <TemplateOffer 
      offer={offersData[1]} 
      titleAction={t("titleCreateDeceased")}
      subtitleAction={t("subtitleCreateDeceased")}
      action={form.handleSubmit(actionHandler as never)}
      loadingAction={loadingAction}
      loading={false}
      offerExist={true}
      titleBtn={t("btnSummaryOffer")}
      hideBtn={true}
      disableBtn={true}
    >
      <Box sx={{
        backgroundColor: "white",
        p: {md: 4, xs: 2},
        borderRadius: 1
      }}>
        <FormDeceased  form={form} />

        <Box sx={{mt: 4}}>
          <BtnOfferContent 
            action={form.handleSubmit(submitHandler as never)}
            titleBtn={t("btnSummaryOffer")}
            loading={loadingAction}
          />
        </Box>
      </Box>
    </TemplateOffer>
  );
};

export default FunnelOffer1;
