import { getAuth } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { app } from "../../../backend/config";
import LayoutActionParameter from "../../layout/layoutActionParameter";
import { getDeathWithAdmin } from "../../../backend/death";
import { useAlert } from "../../../provider/error/hooks/useAlert";
import { IDeathFront } from "../../../backend/type/frontDocument";
import { Box, Button, Typography } from "@mui/material";
import { backgroundOpacity, gold, grayLight } from "../../../ui/color";
import { UserPicture } from "../../../components/utils/user/userPicture";
import { formatDateDeceased }
  from "../../../components/utils/deceased/searchDeceased";
import { formatReligion }
  from "../../../components/utils/format/formatReligion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";
import { routing } from "../../../router";

const AdministredProfile = () => {
  const {t} = useTranslation();
  const user = getAuth(app).currentUser;
  const alert = useAlert();
  const navigate = useNavigate();

  const [deathData, setDeathData] = useState<Array<IDeathFront>>();

  useEffect(() => {
    (async() => {
      if (user) {
        try {
          const dataDeaths = await getDeathWithAdmin(user.uid);
          setDeathData(dataDeaths);
        } catch (error) {
          alert.defaultError();
        }
      }
    })();
  }, [user]);

  return(
    <>
      <LayoutActionParameter
        title={t("profileAdministredTitle")}
      >
        {deathData && deathData.map((el, i) => {
          const religion = formatReligion(el.religion);
          return (
            <Box key={i} sx={{
              backgroundColor: backgroundOpacity,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: 2,
              my: 2,
              mt: i === 0 ? i : 2,
              borderRadius: theme => theme.spacing(0.5)
            }}>
              <Box sx={{
                display: "flex",
                alignItems: "center"
              }}>
                <UserPicture 
                  death={el}
                  className="rounded_img"
                  sx={{
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    width: (theme: any) => theme.spacing(9),
                    // eslint-disable-next-line max-len
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    height: (theme: any) => theme.spacing(9),
                  }}
                  sxIcon={{
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    fontSize: (theme: any) => theme.spacing(9),
                  }}
                />

                <Box sx={{ml: 2}}>
                  <Typography sx={{
                    fontWeight: 400,
                    fontSize: theme => theme.spacing(2),
                    lineHeight: "150%"
                  }}>
                    {el.fullname}
                  </Typography>
                  <Typography sx={{
                    color: grayLight,
                    fontSize: theme => theme.spacing(1.5),
                    fontWeight: 400,
                    lineHeight: theme => theme.spacing(2.5)
                  }}>
                    {/* eslint-disable-next-line max-len */}
                    {formatDateDeceased(el.dateOfBirth, el.dateOfDeath)}
                  </Typography>
                  {religion && <Box sx={{
                    display: "flex",
                    alignItems: "center",
                  }}>
                    <FontAwesomeIcon
                      size="1x"
                      icon={religion.icon}
                      color={grayLight}
                    />
                    <Typography sx={{
                      ml: 1,
                      color: grayLight,
                      fontSize: theme => theme.spacing(1.5),
                      fontWeight: 400,
                      lineHeight: theme => theme.spacing(2.5)
                    }}>
                      {t(religion.text)}
                    </Typography>
                  </Box>}
                </Box>
              </Box>

              <Button 
                variant="outlined" 
                onClick={() => navigate(
                  routing.desceaded.index.replace(":id", el.id)
                )}
                sx={{
                  borderColor: gold,
                  color: gold,
                  height: "fit-content"
                }}>
                {t("profil")}
              </Button>
            </Box>
          );})}
      </LayoutActionParameter>
    </>
  );
};

export default AdministredProfile;
