/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { OfferUser } from "../../../components/utils/user/offerUser";
import InfiniteScroll from "react-infinite-scroll-component";
import { offersData } from "type-absenso";
import { backgroundOpacity, gold, grayDark } from "../../../ui/color";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { PageUserContext } from "../../../provider/user/pageProvider";
import { UserPicture } from "../../../components/utils/user/userPicture";
import { Memorie } from "../../../components/utils/deceased/page/post/memorie";
import { useNavigate } from "react-router-dom";
import { routing } from "../../../router";

const UserPosts = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const pageHandler = useContext(PageUserContext);

  if (pageHandler && !pageHandler.loading && pageHandler.userData) {
    return(
      <>
        <Box sx={{
          width: "100%",
          backgroundColor: "white",
          // eslint-disable-next-line max-len
          boxShadow: "0px 3px 25px -2px rgba(0, 0, 0, 0.14), 0px 2px 20px rgba(0, 0, 0, 0.02), 0px 1px 15px rgba(0, 0, 0, 0.03)",
          borderRadius: theme => theme.spacing(3),
          my: 2,
        }}>
          <Box sx={{
            p: 2,
            borderBottom: `1px solid ${backgroundOpacity}`
          }}>
            <Typography sx={{
              fontWeight: 600,
              fontSize: theme => theme.spacing(2),
              lineHeight: theme => theme.spacing(3),
              letterSpacing: "0.4px"
            }}>
              {t("titleCreateDeceased")}
            </Typography>
          </Box>
    
          <Box sx={{
            p: 2,
            display: "flex",
            overflowX: "scroll"
          }}>
            {offersData.slice(0, 3).map((el, i) => (
              <React.Fragment key={i}>
                <OfferUser offer={el} />
              </React.Fragment>
            ))}
          </Box>
        </Box>
    
        <Box sx={{
          display: "flex",
          flexDirection: "column",
        }}>
          <InfiniteScroll
            dataLength={(
              pageHandler.posts && pageHandler.posts.length
            ) || 0}
            loader={
              <Box sx={{
                height: "20vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}>
                <CircularProgress />
              </Box>
            }
            next={() => pageHandler.getLastPosts()}
            hasMore={pageHandler.hasMore}
          >
            {pageHandler.posts && pageHandler.posts.map((el, i) => (
              <React.Fragment key={i}>
                {el.death && <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    width: "100%",
                    my: 3,
                  }}
                >
                  <Box sx={{
                    backgroundColor: "white",
                    // eslint-disable-next-line max-len
                    boxShadow: "0px 3px 25px -2px rgba(0, 0, 0, 0.14), 0px 2px 20px rgba(0, 0, 0, 0.02), 0px 1px 15px rgba(0, 0, 0, 0.03)",
                    borderRadius: theme => theme.spacing(1),
                    width: theme => theme.spacing(20),
                    maxHeight: "250px",
                    display: {md: "flex", xs: "none"},
                    justifyContent: "space-between",
                    flexDirection: "column",
                    alignItems: "center",
                    p: 2,
                  }}>
                    <UserPicture 
                      death={el.death}
                      className="rounded_img"
                      sx={{
                        height: (theme: any) => theme.spacing(9),
                        width: (theme: any) => theme.spacing(9),
                        borderRadius: "50%",
                      }}
                      sxIcon={{
                        fontSize: (theme: any) => theme.spacing(9)
                      }}
                    />
                    <Box>
                      <Typography sx={{
                        textAlign: "center",
                        fontWeight: 400,
                        fontSize: theme => theme.spacing(1.5),
                        lineHeight: theme => theme.spacing(2.5),
                        color: grayDark,
                      }}>
                        {t("profileOf")}
                      </Typography>
                      <Typography sx={{
                        textAlign: "center",
                        fontWeight: 500,
                        fontSize: theme => theme.spacing(2),
                        lineHeight: theme => theme.spacing(2.5)
                      }}>
                        {`${el.death.firstName} ${el.death.name}`}
                      </Typography>
                    </Box>
    
                    <Button
                      onClick={() => navigate(
                        routing.desceaded.index.replace(":id", el.deceased.id)
                      )}
                      sx={{
                        color: gold,
                        borderColor: gold,
                        height: "auto"
                      }} 
                      variant="outlined"
                    >
                      {t("btnProfil")}
                    </Button>
                  </Box>
                  <Memorie 
                    post={el}
                    death={el.death}
                  />
                </Box>}
              </React.Fragment>
            ))}
          </InfiniteScroll>
        </Box>
      </>
    );
  }

  return<></>;
};

export default UserPosts;