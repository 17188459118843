import React, { ReactNode } from "react";
import {
  Box,
  AppBar,
  Container,
  Toolbar,
  ButtonBase,
  Drawer,
} from "@mui/material";
import logo from "../../../media/img/logo.png";
import Footer from "../../../views/layout/footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { routing } from "../../../router";
import { darkTurquoiseLight, gold, gray, grayBlue } from "../../../ui/color";
import { useTranslation } from "react-i18next";
import { LangManager } from "../website/langManager";
import { useAskAuth } from "../../../provider/auth/hooks/useAskAuth";

export const Navbar = ({
  children,
  left,
  right,
  mobileComponent,
  openMobile,
  mobileOnClose,
  unShowBottomBar,
}: {
    children: ReactNode,
    left?: ReactNode,
    right?: ReactNode,
    mobileComponent?: ReactNode,
    openMobile?: boolean,
    mobileOnClose?: () => void;
    unShowBottomBar?: boolean;
}) => {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const askAuthHandler = useAskAuth();

  const styleBoxHeader = (place: "center" | "left" | "right") => {
    return {
      width: "33%",
      display: "flex",
      alignItems: "center",
      justifyContent: place === "center" ? 
        "center" : place === "left" ? 
          "flex-start" : "flex-end",
    };
  };

  const scrollToId = (id: string) => {
    if (location.pathname !== routing.website.index) {
      navigate(routing.website.index);
    }
    
    const ref = document.getElementById(id);
    if (ref) {
      ref.scrollIntoView({
        behavior: "smooth"
      });
    }
  };

  const goToLinkFunnel = () => {
    if (askAuthHandler && askAuthHandler.userIsConnected()) {
      navigate(routing.order.linkProduct.funnel);
    } else {
      askAuthHandler.openHandler();
    }
  };

  const NavMock = () => {
    return(
      <>
        <Box sx={{
          position: "fixed",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          zIndex: 1000
        }}>
          <AppBar position="static" >
            <Toolbar disableGutters>
              <Container maxWidth="xl">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    height: "100%"
                  }}
                > 
                  <Box sx={styleBoxHeader("left")}>
                    {left || null}
                  </Box>

                  <Box sx={styleBoxHeader("center")}>
                    <Link to={routing.website.index}>
                      <Box sx={{width: {xs: "100px", md: "150px"}}}>
                        <img className="full_width" src={logo} alt="logo" />
                      </Box>
                    </Link>
                  </Box>

                  <Box sx={styleBoxHeader("right")}>
                    <>
                      {right || null}
                      <Box sx={{
                        borderLeft: `0.5px solid ${grayBlue}`,
                        display: {md: "block", xs: "none"}
                      }}>
                        <ButtonBase 
                          onClick={goToLinkFunnel}
                          sx={{
                            color: gold,
                            textTransform: "uppercase",
                            fontSize: theme => theme.spacing(1.5),
                            ml: 1
                          }}>
                          {t("accountPro")}
                        </ButtonBase>
                      </Box>
                    </>
                  </Box>
                </Box>
              </Container>
            </Toolbar>
            {!unShowBottomBar && <Toolbar
              sx={{
                backgroundColor: darkTurquoiseLight,
                height: theme => theme.spacing(6),
                minHeight: theme => theme.spacing(6) + "!important",
                display: {md: "flex", xs: "none"},
              }}
            >
              <Box sx={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                marginRight: "auto",
              }}/>
              <Box sx={{
                display: "flex",
                flex: 1,
                justifyContent: "center"
              }}>
                {[
                  {text: t("home"), action: () => scrollToId("home")},
                  {
                    text: t("presentation"),
                    action: () => scrollToId("presentation")
                  },
                  {text: t("testimony"), action: () => scrollToId("testimony")},
                  {text: t("offer"), action: () => scrollToId("offer")},
                  {text: t("contact"),action: () => scrollToId("contact")}
                ].map((el, i) => (
                  <ButtonBase
                    onClick={el.action}
                    sx={{px: 5}}
                    key={i}
                  >{el.text}</ButtonBase>
                ))}
              </Box>
              <Box sx={{
                display: "flex",
                flex: 1,
                justifyContent: "right",
                marginLeft: "auto"
              }}>
                <Box>
                  <LangManager />
                </Box>
              </Box>
            </Toolbar>}
          </AppBar>
        </Box>

        <Toolbar />
        {!unShowBottomBar && <Toolbar sx={{
          display: {md: "block", xs: "none"},
          height: theme => theme.spacing(6),
          minHeight: theme => theme.spacing(6) + "!important"
        }} 
        />}
      </>
    );
  };

  return(
    <>
      <NavMock />
      {mobileComponent && 
        <Drawer
          anchor="left"
          open={openMobile}
          onClose={mobileOnClose}
          PaperProps={{sx: {width: "70%", backgroundColor: gray}}}
        >
          <AppBar position="relative">
            <Toolbar disableGutters>
              <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%"
              }}>
                <img src={logo} alt="Absenso's logo" className="mid_width" />
              </Box>
            </Toolbar>
          </AppBar>
          {mobileComponent}
        </Drawer>
      }
      {children}

      <Footer />
    </>
  );
};