import React from "react";
import { useTranslation } from "react-i18next";
import LayoutActionParameter from "../../layout/layoutActionParameter";
import {
  Grid,
  Box,
  Typography
} from "@mui/material";
import { useState } from "react";
import { IDeathFront } from "../../../backend/type/frontDocument";
import { DeceasedItem, SearchDeceased }
  from "../../../components/utils/deceased/searchDeceased";
import {
  backgroundOpacity,
  blueFrame,
  darkTurquoise,
  gold,
  grayLight
} from "../../../ui/color";
import FormComponent from "../../../components/forms/formcomponent";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useMemo } from "react";
import { IDataFrontUpdateDeath, verifyLink } from "type-absenso";
import { useAlert } from "../../../provider/error/hooks/useAlert";
import { routing } from "../../../router";
import { postAuth } from "../../../backend/utils/postAuth";
import { updateDeathUrl } from "../../../backend/utils/url";
import { AxiosError } from "axios";
import { getDeathWithAdmin } from "../../../backend/death";
import { getAuth } from "firebase/auth";
import { app } from "../../../backend/config";

const ModifyLinkProfile = () => {
  const {t} = useTranslation();
  const { watch, control, formState } = useForm();
  const alert = useAlert();
  const user = getAuth(app).currentUser;

  const [selectedDeath, setSelectedDeath] = useState<IDeathFront>();
  const [loading, setLoading] = useState<boolean>();

  const linkVerified = useMemo(() => {
    return verifyLink(watch("personalLink") || "");
  }, [watch("personalLink")]);

  const fullLink = useMemo(() => {
    return `https://absenso.com/#${routing.desceaded.page.replace(
      ":text", ""
    )}${linkVerified}`;
  }, [linkVerified]);

  const onSubmit = async () => {
    if (linkVerified && selectedDeath) {
      setLoading(true);
      try {
        const dataToSend: IDataFrontUpdateDeath = {
          type: "link",
          idDeath: selectedDeath.id,
          newData: linkVerified
        };
        await postAuth(updateDeathUrl, dataToSend);
        if (alert && alert.createAlert) {
          alert.createAlert({
            type: "success",
            message: t("linkSuccess", {link: fullLink})
          });
        }
      } catch (error) {
        const errorRes = error as AxiosError;

        if (alert && alert.createAlert) {
          switch (
            errorRes &&
            errorRes.response &&
            errorRes.response.status
          ) {
          case 403:
            alert.createAlert({
              type: "error",
              message: t("linkExistError")
            });
            break;
          
          default:
            alert.defaultError();
            break;
          }
        }
      }
      setLoading(false);
    }
  };

  const searchDeathWhereIamAdmin = async (search: string) => {
    const death = await getDeathWithAdmin((user && user.uid) || "");

    return death.filter(d => {
      return d.fullname.toLowerCase().includes(search);
    });
  };

  return(
    <>
      <LayoutActionParameter
        title={t("modifyLinkProfileTitle")}
        btn={
          <LoadingButton 
            disabled={Boolean(linkVerified.length === 0 || !selectedDeath)}
            onClick={onSubmit}
            loading={loading}
            sx={{
              height: "fit-content",
              color: "white",
              backgroundColor: gold
            }}
            variant="contained"
          >
            {t("modifyLinkBtn")}
          </LoadingButton>
        }
      >
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Box sx={{
              width: "100%",
              p: selectedDeath ? 0 : 3,
              border: selectedDeath ? "inherit" : `1px solid ${blueFrame}`,
              borderRadius: theme => theme.spacing(0.5),
              backgroundColor: backgroundOpacity,
              mb: 1,
              display: "flex",
              justifyContent: "center"
            }}>
              {selectedDeath ? <DeceasedItem death={selectedDeath} /> :
                <Typography sx={{
                  color: blueFrame,
                  fontSize: theme => theme.spacing(2),
                  lineHeight: theme => theme.spacing(3)
                }}>
                  {t("notDeceasedChoose")}
                </Typography>}
            </Box>

            <SearchDeceased 
              backgroundColor={backgroundOpacity}
              paddingContainer={2}
              action={(death) => setSelectedDeath(death)}
              searchAction={searchDeathWhereIamAdmin}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography sx={{
              fontWeight: 700,
              fontSize: theme => theme.spacing(2),
              lineHeight: theme => theme.spacing(2.5),
              textTransform: "uppercase",
              mb: 2
            }}>
              {t("simulationChange")}
            </Typography>
            <Typography sx={{
              fontWeight: 400,
              fontSize: theme => theme.spacing(2),
              lineHeight: theme => theme.spacing(3),
              mb: 2
            }}>
              {t("newLinkWillBe")}
            </Typography>
            <Box sx={{
              mb: 2,
              border: `1px solid ${gold}`,
              backgroundColor: backgroundOpacity,
              p: 2,
              borderRadius: theme => theme.spacing(0.5)
            }}>
              <Typography sx={{
                color: grayLight
              }}>
                {`https://absenso.com/#${routing.desceaded.page.replace(
                  ":text", ""
                )}`} 
                <Typography component="span" sx={{
                  fontWeight: "bold",
                  color: darkTurquoise
                }}>
                  {linkVerified}
                </Typography>
              </Typography>
            </Box>

            <FormComponent 
              watch={watch}
              control={control}
              formState={formState}
              arrayForms={[{
                type: "TextField",
                elementTextField: {
                  id: "personalLink",
                  label: "personalLink",
                  type: "text",
                  name: "personalLink",
                  variant: "standard",
                }
              }]}
            />
          </Grid>
        </Grid>
      </LayoutActionParameter>
    </>
  );
};

export default ModifyLinkProfile;
