import { IForm } from "../../types";

export interface ICheckoutFormData {
  firstName: string;
  name: string;
  email: string;
  phone: string;
  deliveryAddress: string;
  deliveryCity: string;
  deliveryPostalCode: string;
  deliveryCountry: string;
  sameDeliveryAndBilling: boolean;
  billingAddress?: string;
  billingCity?: string;
  billingPostalCode?: string;
  billingCountry?: string;
}

export const checkoutForm: Array<IForm> = [
  {
    type: "TextField",
    elementTextField: {
      name: "firstName",
      id: "firstName",
      label: "firstName",
      type: "text",
      variant: "standard",
    }
  },
  {
    type: "TextField",
    elementTextField: {
      name: "name",
      id: "name",
      label: "name",
      type: "text",
      variant: "standard",
    }
  },
  {
    type: "TextField",
    elementTextField: {
      name: "email",
      id: "email",
      label: "email",
      type: "email",
      variant: "standard",
    }
  },
  {
    type: "TextField",
    elementTextField: {
      name: "phone",
      id: "phone",
      label: "phone",
      type: "text",
      variant: "standard",
    }
  },
  {
    type: "TextField",
    elementTextField: {
      name: "deliveryAddress",
      id: "deliveryAddress",
      label: "address",
      type: "text",
      variant: "standard",
    }
  },
  {
    type: "TextField",
    elementTextField: {
      name: "deliveryCity",
      id: "deliveryCity",
      label: "deliveryCity",
      type: "text",
      variant: "standard",
    }
  },
  {
    type: "TextField",
    elementTextField: {
      name: "deliveryPostalCode",
      id: "deliveryPostalCode",
      label: "deliveryPostalCode",
      type: "text",
      variant: "standard",
    }
  },
  {
    type: "TextField",
    elementTextField: {
      name: "deliveryCountry",
      id: "deliveryCountry",
      label: "deliveryCountry",
      type: "text",
      variant: "standard",
    }
  },
  {
    type: "CheckBox",
    elementCheckBox: {
      name: "sameDeliveryAndBilling",
      required: true,
      label: "sameDeliveryAndBillingQuestion",
    }
  },
  {
    type: "TextField",
    elementTextField: {
      name: "billingAddress",
      id: "billingAddress",
      label: "address",
      type: "text",
      variant: "standard",
    }
  },
  {
    type: "TextField",
    elementTextField: {
      name: "billingCity",
      id: "billingCity",
      label: "deliveryCity",
      type: "text",
      variant: "standard",
    }
  },
  {
    type: "TextField",
    elementTextField: {
      name: "billingPostalCode",
      id: "billingPostalCode",
      label: "deliveryPostalCode",
      type: "text",
      variant: "standard",
    }
  },
  {
    type: "TextField",
    elementTextField: {
      name: "billingCountry",
      id: "billingCountry",
      label: "deliveryCountry",
      type: "text",
      variant: "standard",
    }
  },
];