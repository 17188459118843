import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IDataFrontCloseOrder, offersData } from "type-absenso";
import { TemplateOffer }
  from "../../../components/utils/order/offer/templateoffer";
import { useCheckIfPaymentHasBeenMadeByUser } from "../hooks/useCheckpayment";
import { Box } from "@mui/material";
import { useState } from "react";
import { SelectDeceased }
  from "../../../components/utils/order/offer/selectDeceased";
import { IDeathFront, IFuneralCompanyFront }
  from "../../../backend/type/frontDocument";
import { DeliveryMethod, TChooseForm }
  from "../../../components/utils/order/offer/deliverymethod";
import { useForm } from "react-hook-form";
import { useAlert } from "../../../provider/error/hooks/useAlert";
import { closeOrderOffer } from "../../../backend/orderOffer";
import { ICheckoutFormData }
  from "../../../components/forms/asset/order/checkout";
import { createFormatSubRoute, routing } from "../../../router";
import { getAuth } from "firebase/auth";
import { app } from "../../../backend/config";

const FunnelOfferSupplement = () => {
  const { idSupplement } = useParams();
  const {t} = useTranslation();
  const form = useForm();
  const alert = useAlert();
  const navigate = useNavigate();
  const {offer, loading} = useCheckIfPaymentHasBeenMadeByUser();

  const currentOffer = offersData.find(od => od.uid === idSupplement);
  const currentUser = getAuth(app).currentUser;

  const [step, setStep] = useState<number>(0);
  const [death, setDeath] = useState<IDeathFront>();
  const [displayedDeliveryForm, setDisplayedDeliveryForm] = useState<
    TChooseForm
  >();
  const [funeralCompany, setFuneralCompany] = useState<
    IFuneralCompanyFront
  >();
  const [loadingAction, setLoadingAction] = useState<boolean>(false);

  const addressDeliveryHandler = async (
    data: ICheckoutFormData,
    dataToSend: IDataFrontCloseOrder
  ) => {
    await closeOrderOffer({...dataToSend, deliveryData: data});
  };

  const funeralDeliveryHandler = async (dataToSend: IDataFrontCloseOrder) => {
    if (funeralCompany) {
      await closeOrderOffer({...dataToSend, funeral: funeralCompany.id});
    }
  };

  const submit = async () => {
    try {
      setLoadingAction(true);
      if (offer && offer.id && death) {
        const dataToSend: IDataFrontCloseOrder = {
          idOrderOffer: offer.id,
          death: death.id
        };
        if (displayedDeliveryForm && displayedDeliveryForm === "address") {
          await form.handleSubmit(
            (data) => addressDeliveryHandler(
              data as never, dataToSend
            ) as never
          );
        } else if (
          displayedDeliveryForm && displayedDeliveryForm === "funeral"
        ) {
          await funeralDeliveryHandler(dataToSend);
        }

        if (currentUser) {
          navigate(createFormatSubRoute(
            routing.user.index, routing.user.indexPage
          ).replace(":id", currentUser.uid));
        }
      }
      setLoadingAction(false);
    } catch (error) {
      alert.defaultError();
    }
  };

  const stepsData = [
    {
      title: t("chooseDeceasedTitle"),
      subtitle: t("subtitleChooseDeceased"),
      loadingAction: false,
      titleBtn: t("btnValidateDeceased"),
      action: () => setStep(step + 1),
      component: <SelectDeceased onSelect={(d) => setDeath(d)} />,
      disableBtn: Boolean(!death)
    },
    {
      title: t("methodDelivery"),
      subtitle: t("subtitleMethodDelivery"),
      action: submit,
      loadingAction,
      titleBtn: t("btnDeliveryMethod"),
      disableBtn: Boolean(!displayedDeliveryForm || !funeralCompany),
      component: <DeliveryMethod 
        onSelectFuneralCompany={(fc) => setFuneralCompany(fc)}
        form={form}
        setDisplayedDeliveryForm={(d) => setDisplayedDeliveryForm(d)}
        finalAction={() => true}
        loadingAction={false}
      />
    }
  ];

  if (currentOffer) {
    return(
      <TemplateOffer
        offer={currentOffer}
        titleAction={stepsData[step].title}
        subtitleAction={stepsData[step].subtitle}
        loadingAction={stepsData[step].loadingAction}
        loading={loading}
        offerExist={Boolean(offer && offer.type === "started" && currentOffer)}
        titleBtn={stepsData[step].titleBtn}
        action={stepsData[step].action}
        disableBtn={stepsData[step].disableBtn}
      >
        <Box sx={{
          backgroundColor: "white",
          p: {md: 4, xs: 2},
          borderRadius: 1
        }}>
          {stepsData[step].component}
        </Box>
      </TemplateOffer>
    );
  }

  return <></>;
};

export default FunnelOfferSupplement;