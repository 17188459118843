import {
  collection,
  query,
  doc,
  orderBy,
  getDocs,
  DocumentReference,
  Timestamp,
  getDoc,
  updateDoc,
  limit,
  QueryDocumentSnapshot,
  DocumentData,
  Query,
  startAfter,
} from "firebase/firestore";
import React from "react";
import { collections, INotification, IUsers } from "type-absenso";
import { db } from "./config";
import { INotificationFront, INotificationWithId } from "./type/frontDocument";

export const viewedNotifications = async (idUser: string, id: string) => {
  const userRef = doc(db, collections.users, idUser);
  const notificationRef = doc(userRef, collections.notification, id);
  await updateDoc(notificationRef, {
    hasBeenSeen: true
  });
};

export const getNotifications = async (
  id: string,
  lastPost: QueryDocumentSnapshot<DocumentData> | undefined,
  setLastPost: React.Dispatch<React.SetStateAction<
    QueryDocumentSnapshot<DocumentData> | undefined
  >>,
  setHasMore: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const userRef = doc(db, collections.users, id);
  const notifRef = collection(userRef, collections.notification);
  const arrayData: Array<INotificationWithId> = [];

  let q: Query<DocumentData>;
  const limitQuery = 50;

  if (lastPost) {
    q = query(
      notifRef,
      orderBy("date", "desc"),
      startAfter(lastPost),
      limit(limitQuery)
    );
  } else {
    q = query(
      notifRef,
      orderBy("date", "desc"),
      limit(limitQuery)
    );
  }

  const snaps = await getDocs(q);

  if (snaps.docs && snaps.docs.length > 0) {
    setLastPost(snaps.docs[snaps.docs.length - 1]);
  }

  for await (const snap of snaps.docs) {
    const data = snap.data() as INotification<
      DocumentReference, Timestamp
    >;
    arrayData.push({...data, id: snap.id});
  }

  if (arrayData.length < limitQuery) {
    setHasMore(false);
  }

  return arrayData;
};

export const getAllDataOfNotifications = async (
  notifications: Array<INotificationWithId>,
) => {
  const arrayData: Array<INotificationFront> = [];
  for await (const notif of notifications) {
    const userRef = doc(db, collections.users, notif.userGiving.id);
    const snap = await getDoc(userRef);
    const data = snap.data() as IUsers<Timestamp> | undefined;

    if (data) {
      arrayData.push({
        ...notif,
        user: {...data, id: snap.id},
        idUser: snap.id,
      });
    }
  }

  return arrayData;
};