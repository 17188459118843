import { db } from "../backend/config";
import { collections, IDataFrontDeleteLikes, ILikes } from "type-absenso";
import {
  collection,
  query,
  where,
  doc,
  DocumentReference,
  Timestamp,
  addDoc,
  getDocs
} from "@firebase/firestore";
import { postAuth } from "./utils/postAuth";
import { deleteLikesUrl } from "./utils/url";
import React from "react";

export const getIsLiked = async (
  id: string,
  idUser: string | null,
) => {
  if (idUser) {
    const postRef = doc(db, collections.post, id);
    const likesRef = collection(postRef, collections.likes);
    const q = query(
      likesRef,
      where("user", "==", doc(db, collections.users, idUser))
    );
    const snaps = await getDocs(q);

    if (snaps.size > 0) {
      return {
        likeId: snaps.docs[0].id,
        liked: Boolean(snaps.size > 0)
      };
    } else {
      return undefined;
    }
  }
};

export const addLike = async (
  id: string,
  data: ILikes<DocumentReference, Timestamp>,
  liked: boolean | undefined,
  likeId: string | undefined,
  setLikeId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >
) => {
  const postRef = doc(db, collections.post, id);
  const likesRef = collection(postRef, collections.likes);
  if (liked && likeId) {
    const dataToSend: IDataFrontDeleteLikes = {
      idPost: id,
      idLikes: likeId
    };
    await postAuth(deleteLikesUrl, dataToSend);
  } else {
    const newDoc = await addDoc(likesRef, data);
    setLikeId(newDoc.id);
  }
};