import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ChooseOffer }
  from "../../components/utils/website/createMemorial/chooseOffer";
import { WhereComeFrom }
  from "../../components/utils/website/createMemorial/wherecomefrom";
import { routing } from "../../router";
import LayoutCreateMemorial from "../layout/layoutCreateMemorial";


export interface IResFormWhereComeFrom {
  yesBoughtCandle?: boolean;
  noBoughtCandle?: boolean;
}

export const CreateMemorial = () => {
  const [step, setStep] = useState<number>(0);
  const navigate = useNavigate();

  const formWhereComeFrom = useForm();

  const onSubmitFormWhereComeForm = (data: IResFormWhereComeFrom) => {
    if (data.yesBoughtCandle) {
      navigate({
        pathname: routing.order.linkProduct.funnel,
        search: "?memorial=create"
      });
    } else {
      setStep(step+1);
    }
  };

  const StepComponent = () => {
    switch (step) {
    case 0:
      return <WhereComeFrom
        onSubmit={onSubmitFormWhereComeForm}
        form={formWhereComeFrom}
      />;
    case 1:
      return <ChooseOffer />;
    default:
      return <></>;
    }
  };

  return(
    <LayoutCreateMemorial noBought={Boolean(step === 1)} step={step}>
      <StepComponent />
    </LayoutCreateMemorial>
  );
};