import { db } from "../backend/config";
import { collections, IKit } from "type-absenso";
import {
  doc, DocumentReference, getDoc,
} from "@firebase/firestore";
import { collection } from "firebase/firestore";
import { IKitFront } from "./type/frontDocument";

export const getKit = async ({
  id
}: {
    id: string
}) => {
  const kitRef = collection(db, collections.kit);
  const snap = await getDoc(doc(kitRef, id));
  if (snap.exists()) {
    const data = snap.data() as IKit<DocumentReference>;

    return {...data, id: snap.id} as IKitFront;
  } else {
    return undefined;
  }
};