import React from "react";
import { INotificationFront } from "../../../backend/type/frontDocument";

// Ui
import {
  Box,
  Skeleton,
  ListItemButton,
  Typography,
  ListItem
} from "@mui/material";
import { UserPicture } from "../user/userPicture";
import { gold } from "../../../ui/color";

// Router
import { routing } from "../../../router";
import { useNavigate } from "react-router-dom";

// I18n
import { useTranslation } from "react-i18next";
import { formatBigText } from "../format/formatBigText";
import { viewedNotifications } from "../../../backend/notification";
import { getAuth } from "firebase/auth";
import { app } from "../../../backend/config";
import { useContext } from "react";
import { NotificationsContext }
  from "../../../provider/notifications/notificationProvider";
import { useAlert } from "../../../provider/error/hooks/useAlert";

export const Notifications = ({
  notifications,
}: {
    notifications?: INotificationFront,
    match?: boolean,
}) => {
  const navigate = useNavigate();
  const notifHandler = useContext(NotificationsContext);
  const alert = useAlert();
  const {t} = useTranslation();
  const user = getAuth(app).currentUser;

  const handleNavigate = async (route: string) => {
    navigate(route);
    if (notifications && user && notifHandler) {
      try {
        notifHandler.readNotif(notifications.id);
        await viewedNotifications(user.uid, notifications.id);
      } catch (error) {
        if (alert) {
          alert.defaultError();
        }
      }
    }
  };

  const Template = ({
    text,
    link,
  }: {
    text: string,
    link: string,
  }) => {
    
    return(
      <>
        {notifications && 
        <>
          <ListItem sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            backgroundColor: "white",
            my: 1,
            borderRadius: theme => theme.spacing(0.5),
            p: 1.5,
            border: !notifications.hasBeenSeen ? `1px solid ${gold}` : "none",
            position: "relative"
          }}>
            {notifications.hasBeenSeen === false && <Box sx={{
              position: "absolute",
              top: -8,
              right: 0,
              backgroundColor: gold,
              p: 0.5,
              borderRadius: "40%"
            }}>
              <Typography 
                sx={{color: "white", textTransform: "uppercase"}}
                component="span"
              >
                {t("new")}
              </Typography>
            </Box>}
            <Box sx={{
              display: "flex",
              width: "80%"
            }}>
              <Box sx={{
                display: "flex",
                alignItems: "center"
              }}>
                <UserPicture 
                  user={notifications.user}
                  className="rounded_img"
                  sx={{
                    // eslint-disable-next-line max-len
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    width: (theme: any) => theme.spacing(5),
                    mr: 1
                  }}
                  sxIcon={{
                    // eslint-disable-next-line max-len
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    fontSize: (theme: any) => theme.spacing(5)
                  }}
                />
              </Box>

              <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "baseline",
                justifyContent: "center",
                ml: 0.3
              }}>
                <Typography component="span" sx={{
                  pb: 0.3,
                  fontWeight: "400",
                  fontSize: theme => theme.spacing(2),
                }}>
                  {`${notifications.user.firstName} ${notifications.user.name}`}
                </Typography>

                <Typography component="span">
                  {text}
                </Typography>
              </Box>
            </Box>

            <ListItemButton
              onClick={() => handleNavigate(link)}
              sx={{
                border: `1px solid ${gold}`,
                p: 1.3,
                borderRadius: theme => theme.spacing(0.5),
                display: "flex",
                justifyContent: "center",
                width: "20%",
                mx: 0.5
              }}>
              <Typography sx={{
                textTransform: "uppercase",
                color: gold
              }}>
                {t("btnNotifs")}
              </Typography>
            </ListItemButton>
          </ListItem>
        </>
        }
      </>
    );
  };

  if (
    notifications &&
    notifications.user &&
    notifications.type === "like"
  ) {
    return(
      <Template 
        text={t("likeText")}
        link={`${routing.desceaded.post.replace(":id", notifications.link)}`}
      />
    );
  }

  if (
    notifications &&
    notifications.user &&
    notifications.type === "comment" &&
    notifications.text
  ) {
    return(
      <Template 
        text={t("commentText", {
          comment: formatBigText(notifications.text || "", 40),
        })}
        link={`${routing.desceaded.post.replace(":id", notifications.link)}`}
      />
    );
  }

  if (
    notifications &&
    notifications.user &&
    notifications.type === "post"
  ) {
    return(
      <Template 
        text={t("postNotification", {
          deathname: notifications.text
        })}
        link={`${routing.desceaded.post.replace(":id", notifications.link)}`}
      />
    );
  }

  if (
    notifications &&
    notifications.user &&
    notifications.type === "follow"
  ) {
    return(
      <Template 
        text={t("followNotification", {
          deathname: notifications.text
        })}
        link={`${routing.desceaded.index.replace(":id", notifications.link)}`}
      />
    );
  }

  if (
    notifications &&
    notifications.user &&
    notifications.type === "addBouquet"
  ) {
    return(
      <Template
        link={`${routing.desceaded.index.replace(":id", notifications.link)}`}
        text={t("textAddbouquetNotif", {
          fullname: `
            ${notifications.user.firstName} ${notifications.user.name}
          `,
          deathname: notifications.text
        })}
      />
    );
  }

  return(
    <Box sx={{
      display: "flex",
      p: 1,
    }}>
      <Box sx={{
        width: "20%",
        display: "flex",
        alignItems: "center"
      }}>
        <Skeleton variant="circular" width={50} height={50} />
      </Box>
      <Box sx={{
        px: 1,
        width: "80%"
      }}>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Box>
    </Box>
  );
};