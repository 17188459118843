import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { 
  backgroundOpacity, 
  darkTurquoise,
  gold,
  grayBlue
} from "../../../ui/color";
import { useTranslation } from "react-i18next";
import { InfoOutlined, ShoppingCart } from "@mui/icons-material";
import { getAuth } from "@firebase/auth";
import { app } from "../../../backend/config";
import { useAlert } from "../../../provider/error/hooks/useAlert";
import { LoadingButton } from "@mui/lab";
import { useAskAuth } from "../../../provider/auth/hooks/useAskAuth";
import { useOffer } from "../../../views/order/offers/hooks/useOffer";
import { offersData } from "type-absenso";
import { useNavigate, useSearchParams } from "react-router-dom";

export const Offers = ({
  version,
  uid,
  price,
  items,
  withFuneral,
  minHeightVersion,
  minWidth,
  toggleMemorialFlow,
}: {
    uid: string;
    version: string;
    price: number;
    items: Array<string>;
    withFuneral?: boolean;
    minHeightVersion?: number;
    minWidth?: boolean;
    toggleMemorialFlow?: () => void;
}) => {
  const {t} = useTranslation();
  const alert = useAlert();
  const { subscribeToOffer } = useOffer(uid);
  const askAuth = useAskAuth();
  const user = getAuth(app).currentUser;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);

  const orderHandler = async () => {
    if (user) {
      const offer = offersData.find(od => od.uid === uid);
      
      if (offer) {
        navigate(offer.url);
      }
      /*setLoading(true);
      try {
        await subscribeToOffer({});
      } catch (error) {
        if (alert) {
          alert.defaultError();
        }
      }
      setLoading(false);*/
    } else {
      askAuth && askAuth.openHandler({offer: uid});
    }
  };

  const memorialFlowHandler = () => {
    if (toggleMemorialFlow) {
      setSearchParams({
        offer: uid,
        open: "true"},
      );
    } else {
      orderHandler();
    } 
  };

  return(
    <Box sx={{
      backgroundColor: backgroundOpacity,
      p: 3,
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: minWidth ? "380px" : {md: "380px", xs: "100%"},
      minWidth: minWidth ? "380px" : "inherit",
      mx: {md: 2, xs: 0},
      my: {md: 0, xs: 2}
    }}>
      <Box>
        <Typography sx={{
          fontWeight: 300,
          fontSize: theme => theme.spacing(2),
          lineHeight: theme => theme.spacing(4.5)
        }}>
          {t("version")}
        </Typography>
        <Typography sx={{
          fontWeight: 400,
          fontSize: theme => theme.spacing(4),
          lineHeight: theme => theme.spacing(5),
          color: darkTurquoise,
          minHeight: minHeightVersion ? 
            theme => theme.spacing(minHeightVersion) : "inherit"
        }}>
          {t(version)}
        </Typography>
        <Typography sx={{
          fontWeight: 400,
          fontSize: theme => theme.spacing(9),
          color: gold,
          lineHeight: theme => theme.spacing(10.5),
          mb: theme => theme.spacing(9)
        }}>
          {`${price}€`}
        </Typography>

        <Box sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mb: theme => theme.spacing(3)
        }}>
          <Box sx={{
            width: "70px",
            borderTop: `1px solid ${grayBlue}`
          }} />

          {withFuneral && <Box mb={3} sx={{
            backgroundColor: darkTurquoise,
            mt: 2,
            p: 1,
            borderRadius: theme => theme.spacing(0.5),
            display: "flex",
            alignItems: {xs: "center", md: "initial"}
          }}>
            <InfoOutlined sx={{color: "white", mr: 1}} />
            <Typography 
              sx={{
                color: "white",
                fontWeight: 400,
                fontSize: theme => theme.spacing(2),
                lineHeight: theme => theme.spacing(2.5)
              }}
              component="p"
            >
              {t("offerOnlyFuneral")}
            </Typography>
          </Box>}
        </Box>
      </Box>

      <Box sx={{height: "100%"}}>
        {items.map((el, i) => (
          <Typography key={i} component="p" mt={1.25}>
            {t(el)}
          </Typography>
        ))}
      </Box>

      <Box>
        <Box sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: theme => theme.spacing(3),
          mb: theme => theme.spacing(3)
        }}>
          <Box sx={{
            width: "100%",
            borderTop: `1px solid ${grayBlue}`
          }} />
        </Box>

        <Box>
          <LoadingButton 
            loading={loading}
            sx={{backgroundColor: gold, width: "100%"}}
            onClick={memorialFlowHandler}
            variant="contained"
          >
            <ShoppingCart sx={{mr: 1}} />
            {t("orderBtn")}
          </LoadingButton>
        </Box>

      </Box>
    </Box>
  );
};