import React from "react";

// Ui
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Timestamp } from "firebase/firestore";
import { formatMonth } from "../format/formatMonth";
import { useMediaQuery, useTheme } from "@mui/material";

export const DotDate = ({
  date
}: {
    date: Timestamp
}) => {
  const {t} = useTranslation();
  const month = formatMonth(
    date.toDate().getMonth()
  );
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.only("xs"));
  return(
    <Box sx={{
      display: {xs: "flex", md: "block"},
      alignItems: {xs: "center", md: "inherit"},
      py: {xs: 2, md: 0},
    }}>
      <img
        src={require("../../../media/img/dotabsenso.png")}
        alt="Mini logo"
      />

      <Typography sx={{
        pl: {xs: 1, md: 0},
        fontFamily: "Raleway",
        fontWeight: 500,
        fontSize: "24px",
        lineHeight: "20px",
      }}>
        {month && t(month).slice(0, 3)}
        {!match && <br />}
        <span style={{
          paddingLeft: match ? theme.spacing(0.5) : 0
        }}>
          {date.toDate().getFullYear()}
        </span>
      </Typography>
    </Box>
  );
};