import { FilterAlt } from "@mui/icons-material";
import React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { gold } from "../../../../ui/color";
import { IValueSortBy } from "../../../../backend/post";
import FormComponent from "../../../forms/formcomponent";
import { TemplateCard } from "./utils/templateCard";

export const SortCard = ({
  sortValueHandler
}: {
  sortValueHandler: (newValue: IValueSortBy) => void
}) => {
  const {t} = useTranslation();
  const form = useForm();

  useEffect(() => {
    if (form.watch("perDateOfPublication") && form.watch("perDateOfMemories")) {
      form.setValue("perDateOfPublication", undefined);
      sortValueHandler("dateOfRemembrance");
    }
  }, [form.watch("perDateOfMemories")]);

  useEffect(() => {
    if (form.watch("perDateOfMemories") && form.watch("perDateOfPublication")) {
      form.setValue("perDateOfMemories", undefined);
      sortValueHandler("dateOfPublication");
    }
  }, [form.watch("perDateOfPublication")]);

  useEffect(() => {
    form.setValue("perDateOfPublication", true);
  }, []);

  return(
    <TemplateCard
      title={t("sortPostTitle")}
      icon={<FilterAlt sx={{color: gold}} />}
    >
      <FormComponent 
        watch={form.watch}
        control={form.control}
        formState={form.formState}
        py={0.5}
        arrayForms={[
          {
            type: "CheckBox",
            elementCheckBox: {
              name: "perDateOfMemories",
              label: "perDateOfMemories",
              required: false,
              color: gold,
            }
          },
          {
            type: "CheckBox",
            elementCheckBox: {
              name: "perDateOfPublication",
              label: "perDateOfPublication",
              required: false,
              color: gold,
            }
          },
        ]}
      />
    </TemplateCard>
  );
};