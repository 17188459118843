import React from "react";

// Type
import { IForm } from "./types";

// Form
import { Control, FieldValues, FormState, UseFormWatch } from "react-hook-form";

// Components
import { Box, SxProps } from "@mui/material";
import AutoCompleteReactHookForm from "./autocomplete";
import TextFieldReactHook from "./textfield";
import DatePickerReactHooksForm from "./datepickerreacthookform";
import CheckBox from "./checkBox";
import { Theme } from "@emotion/react";

const FormComponent = ({
  arrayForms,
  control,
  py,
  watch,
  formState,
  sxContainer
}: {
    arrayForms: Array<IForm>,
    control: Control,
    watch: UseFormWatch<FieldValues>,
    formState: FormState<FieldValues>,
    py?: number,
    sxContainer?: SxProps<Theme>
}) => {
  return(
    <>
      {arrayForms.map((element, i) => (
        <Box
          key={i}
          sx={{
            width: "100%",
            ...sxContainer
          }}
          py={py !== undefined ? py : 1}>
          {
            element.type === "Autocomplete" && 
            element.elementAutoComplete &&
                <AutoCompleteReactHookForm
                  onChangeTextField={data => {
                    if (
                      element.elementAutoComplete &&
                      element.elementAutoComplete.onChangeTextField
                    ) {
                      element.elementAutoComplete.onChangeTextField(data);
                    }
                  }}
                  element={element.elementAutoComplete}
                  control={control}
                  valueForm={watch()}
                  error={Boolean(formState.errors[
                    element.elementAutoComplete.name
                  ])}
                />
          }
          {
            element.type === "TextField" &&
              element.elementTextField &&
              <TextFieldReactHook 
                element={element.elementTextField}
                control={control}
                error={Boolean(formState.errors[
                  element.elementTextField.name
                ])}
              />
          }
          {
            element.type === "DatePicker" &&
              element.elementDatePicker &&
              <DatePickerReactHooksForm 
                element={element.elementDatePicker}
                control={control}
                error={Boolean(formState.errors[
                  element.elementDatePicker.name
                ])}
              />
          }
          {
            element.type === "CheckBox" &&
              element.elementCheckBox &&
                <CheckBox 
                  name={element.elementCheckBox.name}
                  required={element.elementCheckBox.required}
                  label={element.elementCheckBox.label}
                  defaultChecked={true}
                  control={control}
                  color={element.elementCheckBox.color}
                />
          }
        </Box>
      ))}
    </>);
};

export default FormComponent;


