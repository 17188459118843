import { Box } from "@mui/material";
import React, { MutableRefObject, ReactNode } from "react";

export const UploadVideo = ({
  onVideo,
  children,
  mx,
  inputRef,
}: {
    onVideo: (video: File) => void;
    children: ReactNode;
    mx?: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    inputRef: MutableRefObject<any>;
}) => {

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      onVideo(files[0]);
    }
  };

  return(
    <Box mx={mx} sx={{
      width: "100%"
    }}>
      <input
        accept="video/mp4,video/x-m4v,video/*"
        type="file"
        id="uploadVideo"
        style={{display: "none"}}
        onChange={e => onChange(e)}
        ref={inputRef}
      />

      <label
        htmlFor="uploadVideo"
        className="full_width"
        style={{cursor: "pointer"}}
      >
        {children}
      </label>
    </Box>
  );
};