import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable
} from "firebase/storage";
import { uid } from "uid";
import { app } from "../config";

export const uploadFile = async ({
  file,
  collection,
}: {
    file: File;
    collection: string;
}) => {
  const storage = getStorage(app);
  const imgUserRef = ref(
    storage,
    `${collection}/${uid(32)}`
  );

  await uploadBytesResumable(
    imgUserRef,
    file,
  );

  const url = await getDownloadURL(imgUserRef);

  return url;
};