import axios from "axios";
import { getLang } from "../../i18n/i18n";

export const post = async (url: string, data: unknown) => {
  const lang = getLang();
  return axios.post(
    url,
    JSON.stringify(data),
    {
      headers : {
        "Content-Type": "application/json",
        mode: "cors",
        cache: "default",
        lang: lang,
      }
    }
  );
};