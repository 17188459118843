/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, ReactNode, useEffect, useState } from "react";

// Backend
import { getDeath } from "../../backend/death";

// Router
import { useParams, Params } from "react-router-dom";

// Hooks
import { useAlert } from "../error/hooks/useAlert";
import { FieldValues, useForm, UseFormReturn } from "react-hook-form";
import { IDeathPage } from "../../backend/type/frontDocument";
import { useMemo } from "react";
import { useUser } from "../auth/hooks/useUser";

interface IDataContext {
    setDeath: React.Dispatch<
      React.SetStateAction<IDeathPage | undefined>
    >;
    deathData: IDeathPage | undefined;
    loading: boolean | undefined;
    sortForm: UseFormReturn<FieldValues, any>;
    isAdmin: boolean | undefined;
    changeBouquetOwner: () => void;
}

export const PageContext = createContext<null | IDataContext>(null);

const PageProvider = ({children}: {children: ReactNode}) => {
  const id: Readonly<Params<string>> = useParams();
  const alert = useAlert();
  const user = useUser();

  const sortForm = useForm();

  const [death, setDeath] = useState<IDeathPage>();
  const [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    (async() => {
      setLoading(true);
      try {
        const data = await getDeath({id: id.id as string });
        if (data) {
          setDeath(data);
        }
      } catch (error) {
        if (alert) {
          alert.defaultError();
        }
      }
      setLoading(false);
    })();
  }, [id.id]);

  const isAdmin = useMemo(() => {
    if (user && death) {
      const filterAdmin = death.admin.filter(a => {
        if (a !== "absenso") {
          return a.id === user.id;
        }
      }).length;
      return Boolean(filterAdmin >= 1);
    }
  }, [death]);

  const changeBouquetOwner = () => {
    if (user && death) {
      setDeath({
        ...death,
        bouquet: {
          user,
          date: new Date(),
        }
      });
    }
  };

  return(
    <PageContext.Provider value={{
      setDeath,
      deathData: death,
      loading,
      sortForm,
      isAdmin,
      changeBouquetOwner,
    }}>
      {loading === false && children}
    </PageContext.Provider>
  );
};

export default PageProvider;
