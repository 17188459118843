import React from "react";

// Share component
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon
} from "react-share";

// Router
import { routing } from "../../../router";

// Ui
import { Box } from "@mui/material";

export const Share = ({
  id,
  type,
}: {
  id: string;
  type: "post" | "page"
}) => {
  const url = type === "post" ? `
    ${window.location.origin}/#${routing.desceaded.post.replace(":id", id)}
  ` : `
  ${window.location.origin}/#${routing.desceaded.index.replace(":id", id)}
`;

  const sizeBtn = "35px";

  const btnStyle = {
    width: "20%",
    paddingRight: "3px",
    paddingLeft: "3px",
  };

  return(
    <>
      <Box sx={{width: "100%", mt: 1}}>
        <>
          <FacebookShareButton style={btnStyle} url={url} >
            <FacebookIcon size={sizeBtn} />
          </FacebookShareButton>
          
          <WhatsappShareButton style={btnStyle} url={url}>
            <WhatsappIcon size={sizeBtn} />
          </WhatsappShareButton>

          <TwitterShareButton style={btnStyle} url={url}>
            <TwitterIcon size={sizeBtn} />
          </TwitterShareButton>

          <LinkedinShareButton style={btnStyle}url={url}>
            <LinkedinIcon size={sizeBtn} />
          </LinkedinShareButton>

          <EmailShareButton style={btnStyle}url={url}>
            <EmailIcon size={sizeBtn} />
          </EmailShareButton>
        </>
      </Box>
    </>
  );
};