/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState} from "react";

// Ui
import {
  Box,
  Typography,
  Stepper,
  StepLabel,
  Step,
  Button
} from "@mui/material";

// I18n
import { useTranslation } from "react-i18next";

// Form
import {
  IPasswordForm,
  IPersonalInfoForm,
  ISignupForm,
  ISignupFormFlow,
  passwordsForm,
  personalInfoForm,
  signupForm as signupFormAsset,
} from "../../components/forms/asset/auth/signup";

// Type
import { IDataFrontUserSignup } from "type-absenso/lib/api/user";
import { collections, offersData } from "type-absenso";

// Utils
import { useAlert } from "../../provider/error/hooks/useAlert";

// Backend
import { uploadFile } from "../../backend/utils/uploadFile";
import { post } from "../../backend/utils/post";
import { createUserUrl } from "../../backend/utils/url";
import { MyContainer } from "../../components/utils/myContainer";
import { backgroundOpacity, darkTurquoise, gold } from "../../ui/color";
import { useMemo } from "react";
import {
  CreateAccount
} from "../../components/utils/auth/signup/createaccount";
import {
  CreatePassword 
} from "../../components/utils/auth/signup/createpassword";
import { PersonalInfo } from "../../components/utils/auth/signup/personalinfo";
import {
  ChoosePicture
} from "../../components/utils/auth/signup/choosepicture";
import { Congrats } from "../../components/utils/auth/signup/congrats";
import { useAuth } from "../../provider/auth/hooks/useAuth";
import { FieldValues, useForm, UseFormSetError } from "react-hook-form";
import { TemplateAuth } from "../../components/utils/auth/templateauth";
import { useOffer } from "../order/offers/hooks/useOffer";
import { IForm } from "../../components/forms/types";
import FormComponent from "../../components/forms/formcomponent";
import { OtherMethod } from "../../components/utils/auth/othermethod";
import { useEffect } from "react";
import AlertProvider from "../../provider/error/alertprovider";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "../../components/utils/auth/signin/visibilityIcon";
import LoadingButton from "@mui/lab/LoadingButton";
import { ChevronRight } from "@mui/icons-material";

export interface ICreateEmailProps {
  data: {
    email: string,
    type?: "google",
    dataProvider?: ISignupForm,
  },
  errorHandler?: UseFormSetError<FieldValues>
}

const Signup = ({
  notDisplayedBgImage,
  methodAfterCreation,
  modalFlowView,
  submitFormFromOutside,
  setLoadingFlow,
  setSubmit,
  toggleUseSignin,
}: {
  notDisplayedBgImage?: boolean;
  methodAfterCreation?: (signupForm: ISignupForm) => void;
  modalFlowView?: boolean;
  submitFormFromOutside: number;
  setLoadingFlow?: (loading: boolean) => void;
  setSubmit?: (submit: number) => void;
  toggleUseSignin?: () => void;
}) => {
  const {t} = useTranslation();
  const alert = useAlert();
  const auth = useAuth();
  const navigate = useNavigate();
  const {offerUid} = useOffer();
  const formSignupFlow = useForm();

  const [loading, setLoading] = useState<boolean>();
  const [visibility, setVisibility] = useState<boolean>(false);
  const [visibilityVerify, setVisibilityVerify] = useState<boolean>(false);

  const [activeStep, setActiveStep] = useState<number>(0);
  const [signupForm, setSignupForm] = useState<ISignupForm>({});

  const handleClickShowPassword = () => setVisibility((show) => !show);
  const handleClickShowPasswordVerify = () => setVisibilityVerify(
    (show) => !show
  );

  const completeFormAsset: IForm[] = [
    ...signupFormAsset.filter(
      sfa => sfa.elementTextField && sfa.elementTextField.name === "email"
    ),
    ...personalInfoForm,
    ...passwordsForm(
      visibility ? "text" : "password",
      visibilityVerify ? "text" : "password",
      (
        <VisibilityIcon
          showPassword={handleClickShowPassword}
          visibility={visibility}
        />
      ),
      (
        <VisibilityIcon
          showPassword={handleClickShowPasswordVerify}
          visibility={visibilityVerify}
        />
      ),
    ),
  ];

  const completeForm = useMemo(() => {
    if (signupForm.uidGoogle) {
      return completeFormAsset.filter((cfa) => {
        if (cfa.elementTextField && cfa.elementTextField.name) {
          return cfa.elementTextField.name !== "password" && 
          cfa.elementTextField.name !== "verifyPassword";
        } else if (cfa) {
          return cfa;
        }
      });
    } else {
      return completeFormAsset;
    }

  }, [signupForm.uidGoogle]);

  const steps = [
    t("method"), t("password"), t("personalInfo"), t("profilPicture")
  ];

  const createEmail = (
    data: ICreateEmailProps["data"],
    errorHandler?: ICreateEmailProps["errorHandler"]
  ) => {
    switch (data.type) {
    case undefined:
      if (data.email.match(
        // eslint-disable-next-line max-len
        /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
        setSignupForm({...signupForm, email: data.email});
        setActiveStep(activeStep + 1);
      } else if (errorHandler) {
        errorHandler("email", {});
        return false;
      }
      break;
    case "google":
      const provider = data.dataProvider;
      setSignupForm({
        ...signupForm,
        email: data.email,
        firstName: provider && provider.firstName || undefined,
        name: provider && provider.name || undefined,
        phone: provider && provider.phone || undefined,
        profilePictures: provider && provider.profilePictures || undefined,
        uidGoogle: provider && provider.uidGoogle || undefined,
      });
      setActiveStep(activeStep + 2);
      break;
    
    default:
      break;
    }
  };

  const createpassword = (
    data: IPasswordForm,
    errorHandler: UseFormSetError<FieldValues>
  ) => {
    if (data.password && data.password.trim().length >= 6) {
      if (data.password === data.verifyPassword) {
        setSignupForm({...signupForm, password: data.password});
        setActiveStep(activeStep + 1);
      } else {
        for (const form of passwordsForm(
          visibility ? "text" : "password",
          visibilityVerify ? "text" : "password",
          (
            <VisibilityIcon
              showPassword={handleClickShowPassword}
              visibility={visibility}
            />
          ),
          (
            <VisibilityIcon
              showPassword={handleClickShowPasswordVerify}
              visibility={visibilityVerify}
            />
          ),
        )) {
          if (form.elementTextField && form.elementTextField.id) {
            errorHandler(form.elementTextField.id, {});
          }
        }
      }
    } else {
      alert && alert.createAlert && alert.createAlert({
        message: t("errorLenPassword"),
        type: "error"
      });
      return false;
    }
  };

  const addPersonalInfo = (data: IPersonalInfoForm) => {
    setSignupForm({
      ...signupForm,
      firstName: data.firstName,
      name: data.name,
      phone: data.phone,
      sexe: data.sexe,
      dateOfBirth: data.dateOfBirth
    });
    setActiveStep(activeStep + 1);
  };

  const createAccount = async (crop: File | undefined, form?: ISignupForm) => {
    let formData:ISignupForm | undefined = Object.keys(
      signupForm
    ).length >= 6 ? signupForm : form;
    
    if (form) {
      formData = {
        ...signupForm,
        ...form
      };
    }

    if (
      formData &&
      formData.dateOfBirth &&
      formData.email &&
      formData.firstName &&
      formData.name &&
      formData.phone &&
      formData.sexe
    ) {
      setLoading(true);
      try {
        const dataToSend: IDataFrontUserSignup = {
          email: formData.email,
          firstName: formData.firstName,
          name: formData.name,
          sexe: formData.sexe.value,
          dateOfBirth: formData.dateOfBirth,
          phone: formData.phone,
          password: formData.password,
          pictures: formData.profilePictures || "default",
          uidGoogle: formData.uidGoogle,
        };
    
        if (crop) {
          const url = await uploadFile({
            file: crop,
            collection: collections.users,
          });
        
          dataToSend.pictures = url;
        }
    
        await post(createUserUrl, dataToSend);

        if (methodAfterCreation) {
          methodAfterCreation(formData);
        } else {
          setActiveStep(activeStep + 1);
        }
      } catch (error) {
        if (alert) {
          alert.defaultError();
        }
      }
      setLoading(false);
    }
  };

  const signin = async () => {
    if (signupForm.email && signupForm.password && auth) {
      setLoading(true);
      await auth.signin(signupForm.email, signupForm.password);
    } else if (signupForm.uidGoogle) {
      if (offerUid) {
        const offer = offersData.find(od => od.uid === offerUid);
        if (offer) {
          navigate(offer.url);
        }
      }
      document.location.reload();
    }
  };

  const contentStep = useMemo(() => {
    switch (activeStep) { 
    case 0:
      return <CreateAccount action={createEmail} />;
    case 1:
      return <CreatePassword action={createpassword} />;
    case 2:
      return <AlertProvider>
        <PersonalInfo action={addPersonalInfo} currentData={signupForm} />
      </AlertProvider>;
    case 3:
      return <ChoosePicture
        action={createAccount}
        loadingAction={loading}
        currentData={signupForm}
      />;
    default:
      break;
    }
  }, [activeStep, loading]);

  // Features flow

  useEffect(() => {
    if (signupForm && signupForm.uidGoogle) {
      if (signupForm.email) {
        formSignupFlow.setValue("email", signupForm.email);
      }
      if (signupForm.phone) {
        formSignupFlow.setValue("phone", signupForm.phone);
      }
      if (signupForm.firstName) {
        formSignupFlow.setValue("firstName", signupForm.firstName);
      }
      if (signupForm.name) {
        formSignupFlow.setValue("name", signupForm.name);
      }
    }
  }, [signupForm]);

  const flowSignupHandler = async (data: ISignupFormFlow) => {
    if (data.agreeGeneral && data.agreePrivacyPolicy) {
      let resEmail: boolean | undefined = true;
      let resPassword: boolean | undefined = true;
      if (data.password && data.verifyPassword) {
        resEmail = createEmail(
          { email: data.email }, formSignupFlow.setError
        );
        resPassword = createpassword(
          data, formSignupFlow.setError
        );
      }
      console.log(resEmail, resPassword);
      if ((resEmail !== false && resPassword !== false) &&
       (data.password === data.verifyPassword)) {
        await createAccount(undefined, data);
      }
    } else {
      alert && alert.createAlert && alert.createAlert({
        type: "error",
        message: t("errorCheckBoxSignup")
      });
    }
  };

  // Launch from outside beause I miss a lot of time
  useEffect(() => {
    (async () => {
      if (submitFormFromOutside) {
        setLoadingFlow && setLoadingFlow(true);
        await formSignupFlow.handleSubmit(flowSignupHandler as never)();
        setLoadingFlow && setLoadingFlow(false);
      }
    })();
  }, [submitFormFromOutside]);


  if (modalFlowView) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: {
            xs: "center",
            md: "space-between",
          },
          height: "max-content",
        }}
      >
        <Box
          sx={{
            order: 2,
            display: {
              xs: "none",
              md: "flex",
            },
            flexDirection: "column",
            justifyContent: "space-between",
            alignSelf: "center",
            width: "45%",
            height: "max-content",
          }}
        >
          <OtherMethod
            goal="registration"
            action={createEmail}
            isLandscape={true}
          />
          <Box
            sx={{
              order: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant={"body2"}
              sx={{
                textAlign: "center",
                mt: 5,
                color: "text.secondary",
              }}
            >
              {t("alreadyHaveAccount")}
            </Typography>
            <Button
              onClick={toggleUseSignin}
              variant="text"
              sx={{
                mt: 2,
                textTransform: "uppercase",
                alignSelf: "center",
                color: gold,
              }}
            >
              {t("titleSignin")}
              <ChevronRight />
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: {
              xs: "100%",
              md: "48%",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <FormComponent
              watch={formSignupFlow.watch}
              control={formSignupFlow.control}
              formState={formSignupFlow.formState}
              sxContainer={{ pr: 1 }}
              py={0}
              arrayForms={completeForm.filter((cf) => {
                if (cf.elementTextField) {
                  return cf.elementTextField.name === "firstName";
                }
              })}
            />

            <FormComponent
              watch={formSignupFlow.watch}
              control={formSignupFlow.control}
              formState={formSignupFlow.formState}
              sxContainer={{ pl: 1 }}
              py={0}
              arrayForms={completeForm.filter((cf) => {
                if (cf.elementTextField) {
                  return cf.elementTextField.name === "name";
                }
              })}
            />
          </Box>

          <FormComponent
            watch={formSignupFlow.watch}
            control={formSignupFlow.control}
            formState={formSignupFlow.formState}
            arrayForms={completeFormAsset.filter((cf) => {
              if (cf.elementTextField) {
                return (
                  cf.elementTextField.name === "email" ||
                  cf.elementTextField.name === "password" ||
                  cf.elementTextField.name === "verifyPassword"
                );
              }
            })}
          />

          <FormComponent
            watch={formSignupFlow.watch}
            control={formSignupFlow.control}
            formState={formSignupFlow.formState}
            arrayForms={completeForm.filter((cf) => {
              if (cf.elementDatePicker) {
                return cf.elementDatePicker.name === "dateOfBirth";
              }
            })}
          />

          <Box
            sx={{
              display: "flex",
              width: "100%",
              py: 1,
            }}
          >
            <FormComponent
              watch={formSignupFlow.watch}
              control={formSignupFlow.control}
              formState={formSignupFlow.formState}
              sxContainer={{ pr: 1 }}
              py={0}
              arrayForms={completeForm.filter((cf) => {
                if (cf.elementAutoComplete) {
                  return cf.elementAutoComplete.name === "sexe";
                }
              })}
            />

            <FormComponent
              watch={formSignupFlow.watch}
              control={formSignupFlow.control}
              formState={formSignupFlow.formState}
              sxContainer={{ pl: 1 }}
              py={0}
              arrayForms={completeForm.filter((cf) => {
                if (cf.elementTextField) {
                  return cf.elementTextField.name === "phone";
                }
              })}
            />
          </Box>

          <FormComponent
            watch={formSignupFlow.watch}
            control={formSignupFlow.control}
            formState={formSignupFlow.formState}
            py={0}
            sxContainer={{ pt: 1 }}
            arrayForms={completeForm.filter((cf) => {
              if (cf.elementCheckBox) {
                return (
                  cf.elementCheckBox.name === "agreeGeneral" ||
                  cf.elementCheckBox.name === "agreePrivacyPolicy"
                );
              }
            })}
          />
          <LoadingButton
            onClick={
              setSubmit
                ? () => setSubmit(submitFormFromOutside + 1)
                : () => true
            }
            loading={loading}
            variant={"gold" as never}
            sx={{
              backgroundColor: gold,
              width: "100%",
              display: {
                xs: "none",
                md: "flex",
              },
              mt: 2,
            }}
          >
            {t("createAccountBtn")}
            <ChevronRight />
          </LoadingButton>
        </Box>
      </Box>
    );
  }

  return(
    <>
      <TemplateAuth 
        notDisplayedBgImage={notDisplayedBgImage}
        content={
          <Box sx={{
            width: "50%",
            flex: "1 1 0px",
          }}>
            {activeStep <= 3 ? <>
              {!notDisplayedBgImage && <Box sx={{
                backgroundColor: backgroundOpacity,
                py: 6
              }}>
                <MyContainer maxWidth="sm">
                  <Stepper 
                    activeStep={activeStep}
                    alternativeLabel
                  >
                    {steps.map((el) => (
                      <Step key={el} sx={{
                        "& .MuiStepIcon-root.Mui-active": {
                          color: gold
                        },
                        "& .MuiStepIcon-root.Mui-completed": {
                          color: gold
                        }
                      }}>
                        <StepLabel sx={{
                          "& .MuiStepLabel-label.Mui-active": {
                            fontWeight: "bold"
                          },
                          "& .MuiStepLabel-label.Mui-completed": {
                            fontWeight: "bold"
                          }
                        }}>{el}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </MyContainer>
              </Box>}
            
              <Box py={6}>
                <MyContainer
                  maxWidth="sm"
                  sx={{
                    minHeight: "70vh",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column"
                  }}
                >
                  <Typography
                    variant={"title" as never}
                    sx={{
                      color: darkTurquoise
                    }}
                  >
                    {t("createAccountTitle")}
                  </Typography>
  
                  {contentStep}
                </MyContainer>
              </Box>
            </> : 
              <Congrats action={signin} btnLoading={loading} />
            }
          </Box>
        }
      />
    </>
  );
};

export default Signup;
