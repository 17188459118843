import React from "react";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { DeceasedItem, SearchDeceased } from "../../deceased/searchDeceased";
import { IDeathFront } from "../../../../backend/type/frontDocument";
import { backgroundOpacity, gold } from "../../../../ui/color";
import { LoadingButton } from "@mui/lab";

export const SelectADeceased = ({
  handleSubmitLink,
  idKit,
  loading,
  death,
  handleSearch,
}: {
  handleSubmitLink: () => Promise<void>;
  idKit?: string;
  loading: boolean;
  death?: IDeathFront;
  handleSearch: (value: IDeathFront) => void;
}) => {
  const {t} = useTranslation();

  return(
    <Box sx={{
      display: "flex",
      width: "100%",
      justifyContent: "center"
    }}>
      <Box sx={{
        boxShadow: `0px 4px 12px ${grey[300]}`,
        borderRadius: theme => theme.spacing(1),
        width: {xs: "100%", md: "60%"},
        p: 2,
      }}>
        <Typography sx={{
          fontSize: theme => theme.spacing(3),
          fontWeight: 400,
          lineHeight: theme => theme.spacing(4),
          mb: 2,
        }}>
          {t("selectDeceased")}
        </Typography>

        <Typography>
          {t("selectDeceasedDescription", {
            idKit: idKit
          })}
        </Typography>

        <Box sx={{
          mt: 2
        }}>
          {death && <Box sx={{
            mb: 2,
            border: `0.1px solid ${gold}`,
            borderRadius: theme => theme.spacing(1)
          }}>
            <DeceasedItem death={death} />
          </Box>}

          <SearchDeceased
            action={handleSearch}
            paddingContainer={2}
            backgroundColor={backgroundOpacity}
          />
          
          <LoadingButton
            onClick={handleSubmitLink}
            loading={loading}
            variant="contained"
            sx={{
              width: "100%",
              mt: 2,
            }}
          >
            {t("linkKitWithDeceasedBtn")}
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};