import React, { useRef } from "react";
import {
  Grid,
  Typography,
  Box,
  List,
  ListItem,
  CircularProgress,
} from "@mui/material";
import {
  AlternateEmail,
  Phone, 
  Work,
  Facebook,
  Instagram,
  LinkedIn
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { 
  backgroundOpacity, borderOpacity, darkTurquoise, gold, grayBlue, grayDark 
} from "../../ui/color";
import { MyContainer } from "../../components/utils/myContainer";
import { contactForm } from "../../components/utils/website/assets/form";
import FormComponent from "../../components/forms/formcomponent";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Fade from "react-reveal/Fade";
import { Button } from "@mui/material";
import { Offers } from "../../components/utils/website/offers";
import { Carousel } from "../../components/utils/website/carouselTestimony";
import { useLocation, useSearchParams } from "react-router-dom";
import { IDataContact, offersData } from "type-absenso";
import { useEffect } from "react";
import { useMemo } from "react";
import { FlowContainer } 
  from "../../components/utils/website/createMemorial/flow/flowContainer";
import AlertProvider from "../../provider/error/alertprovider";
import { useState } from "react";
import { getAllFuneralCompanies } from "../../backend/funeralCompany";
import { IFuneralCompanyFront } from "../../backend/type/frontDocument";
import { grey } from "@mui/material/colors";
import { useAlert } from "../../provider/error/hooks/useAlert";
import { post } from "../../backend/utils/post";
import { contactUrl } from "../../backend/utils/url";

const spacing = 2;

const HomeWebsite = () => {
  const {t, i18n} = useTranslation();
  const {control, watch, formState, handleSubmit, reset} = useForm();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const offerRef = useRef<HTMLInputElement>();
  const [searchParams] = useSearchParams();
  const alert = useAlert();

  const [openMemorialFlow, setOpenMemorialFlow] = useState<boolean>(
    Boolean(searchParams.get("open") === "true")
  );
  const [funeralCompanies, setFuneralCompanies] = useState<
    IFuneralCompanyFront[]
  >();
  const [currentFuneral, setCurrentFuneral] = useState<{
    start: number;
    end: number;
  }>({start: 0, end: 2});
  const [loadingContact, setLoadingContact] = useState<boolean>(false);

  const imgBox = useMemo(() => {
    if (i18n.language === "fr") {
      return require("../../media/img/img-box1-2-fr.png");
    } else {
      return require("../../media/img/img-box1-2-nl.png");
    }
  }, [i18n.language]);

  useEffect(() => {
    if (query.get("id") === "offer" && offerRef) {
      window.scrollTo({
        behavior: "smooth",
        top: offerRef.current && offerRef.current.offsetTop
      });
    }
  }, [query]);

  const openMemorialHandler = () => {
    setOpenMemorialFlow(!openMemorialFlow);
  };

  useEffect(() => {
    (async() => {
      const funeralCompaniesRes = await getAllFuneralCompanies();
      setFuneralCompanies(funeralCompaniesRes);
    })();
  }, []);

  useEffect(() => {
    if (funeralCompanies) {
      setTimeout(() => {
        const newEnd = currentFuneral.end + 2;
        const newSlice = funeralCompanies.slice(currentFuneral.end, newEnd);
        if (newSlice.length > 0) {
          setCurrentFuneral({
            start: currentFuneral.end,
            end: newEnd
          });
        } else {
          setCurrentFuneral({
            start: 0,
            end: 2
          });
        }
      }, 3000);
    }
  }, [funeralCompanies, currentFuneral]);

  const scrollToId = (id: string) => {
    const ref = document.getElementById(id);
    if (ref) {
      ref.scrollIntoView({
        behavior: "smooth"
      });
    }
  };

  const contactHandler = async (data: IDataContact) => {
    try {
      setLoadingContact(true);
      await post(contactUrl, data);
      reset();
      alert && alert.createAlert && alert.createAlert({
        type: "success",
        message: t("contactSent")
      });
      setLoadingContact(false);
    } catch (error) {
      alert.defaultError();
    }
  };
  
  return(
    <>
      {openMemorialFlow && 
        <AlertProvider>
          <FlowContainer
            open={true}
            onClose={openMemorialHandler}
            step={searchParams.get("step") ? parseInt(
              searchParams.get("step") as string
            ) : undefined}
          />
        </AlertProvider>
      }

      <Box id="home" sx={{
        width: "100%",
        // eslint-disable-next-line max-len
        backgroundImage: `
          linear-gradient(
            rgba(0, 0, 0, 0.5), 
            rgba(0, 0, 0, 0.5)
          ),
          url(${require("../../media/img/img-box1.png")})
        `,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}>
        <MyContainer>
          <Fade>
            <Grid container spacing={spacing} sx={{
              width: "100%",
              minHeight: "95vh",
              display: "flex",
              alignItems: "center",
              py: {md: 0, xs: 10}
            }}>
              {/* First box */}
              <Grid item md={6} xs={12}>
                <Typography 
                  variant={"titleWebsite" as never}
                  sx={{ 
                    pb: 2, 
                    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    textAlign: "inherit"
                  }}
                >
                  {t("titleWebsite")}
                </Typography>

                <Typography 
                  sx={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                  variant={"subtitle" as never}
                  pb={2}
                >
                  {t("titleOl1Box")}
                </Typography>

                {[
                  {text: "1ol1box"},
                  {text: "2ol1box"},
                  {text: "3ol1box"}
                ].map((el, i) => (
                  <Box key={i} py={1} sx={{ display: "flex" }}>
                    <Box sx={{
                      height: "30px",
                      marginRight: theme => theme.spacing(1),
                      width: "30px",
                      minWidth: "30px",
                      borderRadius: "50%",
                      backgroundColor: gold,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }} >
                      <Typography sx={{
                        color: "white",
                        fontWeight: "bold"
                      }}>
                        {i+1}
                      </Typography>
                    </Box>
                    <Typography
                      component="span"
                      sx={{color: "white", fontWeight: 400}}
                    >
                      {t(el.text)}
                    </Typography>
                  </Box>
                ))}

                <Button 
                  sx={{mt: 4, width: {xs: "100%", md: "auto"}}}
                  variant={"gold" as never}
                  onClick={openMemorialHandler}
                >
                  {t("tryitfree")}
                </Button>
              </Grid>
              <Grid item md={6} xs={12} sx={{
                display: {md: "block", xs: "none"}
              }}>
                <Box sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  width: "100%",
                  height: "100%"
                }}>
                  <img 
                    className="img-box1-website"
                    src={imgBox} 
                    alt="Absenso screen" 
                  />
                </Box>
              </Grid>
              {/* First box */}
            </Grid>
          </Fade>
        </MyContainer>
      </Box>

      {/* Second box */}
      <Box id="presentation" sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        py: 12,
      }}>
        <MyContainer>
          <Fade>
            <Box sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
              <Box sx={{
                width: {md: "40%", xs: "80%"},
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                pb: {md: 12, xs: 6}
              }}>
                <Typography py={1} variant={"pageTitle" as never}>
                  {t("presentation")}
                </Typography>
                <Typography variant={"pageSubtitle" as never}>
                  {t("subBox2Page")}
                </Typography>
              </Box>
            </Box>
            <Grid container spacing={spacing}>
              {[
                // eslint-disable-next-line max-len
                {title: t("title1p4box"), p: t("1p4box"), pic: require("../../media/img/screensWebsite.png")},
                // eslint-disable-next-line max-len
                {title: t("title2p4box"), p: t("2p4box"), pic: require("../../media/img/mid-phone-mock.png")},
                // eslint-disable-next-line max-len
                {title: t("title3p4box"), p: t("3p4box"), pic: require("../../media/img/candle-mock.png")}
              ].map((el, i) => (
                <Grid key={i} item md={4} xs={12}>
                  <Box sx={{
                    mx: {md: 2, xs: 0},
                    backgroundColor: backgroundOpacity,
                    p: 3,
                    borderRadius: theme => theme.spacing(1),
                    border: `1px solid ${borderOpacity}`,
                    height: "650px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexDirection: "column"
                  }}>
                    <Typography 
                      variant={"titleWebsite" as never}
                      sx={{ color: gold }}
                    >
                      {el.title}
                    </Typography>

                    <Typography
                      sx={{
                        color: grayDark,
                        fontWeight: 400,
                        fontSize: "12px",
                        textAlign: "center"
                      }}
                    >
                      {el.p}
                    </Typography>
                  
                    <Box sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                      <img 
                        className="img-card-box-2"
                        src={el.pic}
                        alt="website mock"
                      />
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Fade>
        </MyContainer>
      </Box>
      {/* Second box */}

      {/* Third box */}
      <Box id="testimony" sx={{
        backgroundColor: backgroundOpacity,
      }}>
        <MyContainer>
          <Fade>
            <Box sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}>
              <Box sx={{
                width: {md: "40%", xs: "80%"},
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                py: {md: 12, xs: 6}
              }}>
                <Typography py={1} variant={"pageTitle" as never}>
                  {t("testimony")}
                </Typography>
                <Typography variant={"pageSubtitle" as never}>
                  {t("subtextTestimony")}
                </Typography>
              </Box>

              <Box sx={{mb: 6}}>
                <Carousel />
              </Box>
            </Box>
          </Fade>
        </MyContainer>
      </Box>
      {/* Third box */}

      {/* Fourth box */}
      <Box ref={offerRef} id="offer" sx={{
        minWidth: "100%",
      }}>
        <MyContainer>
          <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
          }}>
            <Box sx={{
              width: {md: "40%", xs: "80%"},
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              py: {md: 12, xs: 6},
            }}>
              <Typography py={1} variant={"pageTitle" as never}>
                {t("ouroofers")}
              </Typography>
              <Typography variant={"pageSubtitle" as never}>
                {t("subtextbox4")}
              </Typography>
            </Box>

            <Box sx={{
              display: "flex",
              flexDirection: {xs: "column", md: "row"},
              mb: 12
            }}>
              {offersData.slice(0, 3).map((el, i) => (
                <React.Fragment key={i}>
                  <Offers
                    toggleMemorialFlow={openMemorialHandler} 
                    uid={el.uid}
                    version={el.version}
                    price={el.price}
                    items={el.items}
                  />
                </React.Fragment>
              ))}
            </Box>

              
            {/*<Box sx={{
              width: {md: "40%", xs: "80%"},
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              py: {md: 12, xs: 6}
            }}>
              <Typography py={1} variant={"pageTitle" as never}>
                {t("ouroofers")}
              </Typography>
              <Typography variant={"pageSubtitle" as never}>
                {t("subtextbox4")}
              </Typography>
            </Box>

            <Box sx={{
              display: "flex",
              flexDirection: {xs: "column", md: "row"},
              mb: 12
            }}>
              {offersData.slice(2, 5).map((el, i) => (
                <React.Fragment key={i}>
                  <Offers 
                    minHeightVersion={10}
                    uid={el.uid}
                    version={el.version}
                    price={el.price}
                    items={el.items}
                    withFuneral={true}
                  />
                </React.Fragment>
              ))}
              </Box>*/}
          </Box>

        </MyContainer>
      </Box>
      {/* Fourth box */}

      <Box sx={{
        width: "100%",
        pb: 12,
      }}>
        <MyContainer>
          <Fade>
            <Box sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center"
            }}>
              <Box sx={{
                width: {md: "40%", xs: "80%"},
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                pb: {md: 12, xs: 6}
              }}>
                <Typography py={1} variant={"pageTitle" as never}>
                  {t("ourPartner")}
                </Typography>
                <Typography variant={"pageSubtitle" as never}>
                  {t("ourPartnerSubtitle")}
                </Typography>
              </Box>
              <Box sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: {xs: "column", md: "row"},
                width: "100%",
              }}>
                {funeralCompanies ? funeralCompanies.slice(
                  currentFuneral.start, currentFuneral.end
                ).map((el, i) => (
                  <React.Fragment key={i}>
                    { el.pictures && <Fade><Box sx={{
                      mx: {xs: 0, md: 2},
                      boxShadow: `0px 4px 12px ${grey[400]}`,
                      borderRadius: theme => theme.spacing(1),
                      width: { xs: "100%", md: 400 },
                      mt: { xs: i !==0 ? 2 : 0, md: "inherit" }
                    }}>
                      <Box sx={{
                        display: "flex",
                        justifyContent: "center"
                      }}>
                        <Box sx={{
                          width: 200,
                          p: 4
                        }}>
                          <img 
                            className="full_width_height"
                            src={el.pictures}
                            alt={`${el.companyName}`}
                          />
                        </Box>
                      </Box>

                      <Box sx={{
                        p: 2,
                        backgroundColor: backgroundOpacity,
                        maxHeight: 120,
                        height: 120,
                        borderBottomRightRadius: theme => theme.spacing(1),
                        borderBottomLeftRadius: theme => theme.spacing(1),
                      }}>
                        <Typography sx={{
                          color: darkTurquoise,
                          fontWeight: "bold",
                          fontSize: theme => theme.spacing(2),
                          lineHeight: theme => theme.spacing(3)
                        }}>
                          {el.companyName}
                        </Typography>

                        <Typography component="div" sx={{
                          color: grey[400],
                          fontSize: theme => theme.spacing(1.75),
                          fontWeight: 500
                        }}>
                          <Typography>{`${el.headOffice}`}</Typography>
                          {/* eslint-disable-next-line max-len */}
                          <Typography>{`${el.postalCodeHeadOffice} ${el.cityHeadOffice}`}</Typography>
                          <Typography>{`${el.countryHeadOffice}`}</Typography>
                        </Typography>

                        {/* <Box sx={{mt: 2}}>
                          <FontAwesomeIcon
                            icon={faQuoteRight}
                            color={darkTurquoise}
                          />
                          <Typography 
                            component="div"
                            sx={{
                              overflowWrap: "break-word",
                              color: darkTurquoise,
                            }}>
                            {`${el.description}`}
                          </Typography>
                          </Box> */}
                      </Box>
                    </Box>
                    </Fade> }
                  </React.Fragment>
                )) : <CircularProgress />}
              </Box>
            </Box>
          </Fade>
        </MyContainer>

      </Box>

      {/* Fifth box */}
      <Box sx={{
        width: "100%",
        backgroundColor: backgroundOpacity,
        py: 12
      }}>
        <MyContainer>
          <Fade>
            <Grid container spacing={2} sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: {xs: 6, md: 0}
            }}>
              <Grid item md={6} order={{md: 1, xs: 2}} xs={12} p={2}>
                <Typography
                  component="h4"
                  sx={{
                    mb: 2,
                    fontWeight: 500,
                    fontSize: theme => theme.spacing(4.5),
                    lineHeight: theme => theme.spacing(6),
                    textAlign: {md: "left", xs: "center"}
                  }}
                >
                  {t("titleWhatYouReceive")}
                </Typography>
                <Typography component="p" sx={{
                  textAlign: {md: "left", xs: "center"}
                }}>
                  {t("6boxText")}
                </Typography>
                <Box sx={{
                  display: "flex",
                  justifyContent: {xs: "center", md: "initial"},
                  width: "100%"
                }}>
                  <Button 
                    variant="contained" 
                    onClick={() => scrollToId("offer")}
                    sx={{
                      backgroundColor: gold,
                      mt: theme => theme.spacing(4)
                    }}
                  >
                    {t("discoverOffer")}
                  </Button>
                </Box>
              </Grid>
              <Grid item md={6} xs={12} order={{md: 2, xs: 1}} sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}>
                <img 
                  className="img-what-receive"
                  src={require("../../media/img/whatYouReceive.png")} 
                  alt="Mock offers absenso"
                />
              </Grid>
            </Grid>
          </Fade>
        </MyContainer>
      </Box>
      {/* Fifth box */}

      {/* Sixth box */}
      <Box id="contact" sx={{
        backgroundColor: darkTurquoise
      }}>
        <Fade>
          <MyContainer>
            <Grid container sx={{
              width: "100%",
              py: 12,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
              <Grid item md={6} xs={12} sx={{
                mb: {md: 0, xs: 2}
              }}>
                <Box sx={{
                  mb: {md: 6, xs: 6}
                }}>
                  <Typography component="h1" sx={{color: "white"}}>
                    {t("titleContact")}
                  </Typography>
                  <Typography component="p" sx={{color: grayBlue}}>
                    {t("subtitleContact")}
                  </Typography>
                </Box>

                <List>
                  {[
                    // eslint-disable-next-line max-len
                    {text: "Av. Reine astrid 92 – 1310 La Hulpe", icon: <Work sx={{color: "white"}} />},
                    // eslint-disable-next-line max-len
                    {text: "32 475 60 04 16", icon: <Phone sx={{color: "white"}} />},
                    // eslint-disable-next-line max-len
                    {text: "contact@absenso.com", icon: <AlternateEmail sx={{color: "white"}} />}
                  ].map((el, i) => (
                    <ListItem key={i} sx={{padding: 0}}>
                      <Box mr={1}>
                        {el.icon}
                      </Box>
                      <Typography sx={{color: "white"}}>
                        {el.text}
                      </Typography>
                    </ListItem>
                  ))}
                </List>

                <Box sx={{ display: "flex", mb: 6}}>
                  {[
                    {item: <Facebook sx={{color: "white"}} />},
                    {item: <Instagram sx={{color: "white"}}  />},
                    {item: <LinkedIn sx={{color: "white"}}  />}
                  ].map((el, i) => (
                    <Box key={i} sx={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                      backgroundColor: gold,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mr: 1
                    }}>
                      {el.item}
                    </Box>
                  ))}
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box sx={{
                  backgroundColor: "white",
                  p: 2,
                  borderRadius: 1,
                }}>
                  <FormComponent 
                    watch={watch}
                    control={control}
                    formState={formState}
                    arrayForms={contactForm}
                  />
                  <LoadingButton 
                    onClick={handleSubmit(contactHandler as never)}
                    variant="contained"
                    loading={loadingContact}
                    sx={{width: "100%", mt: 2, backgroundColor: gold}}
                  >
                    {t("send")}
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </MyContainer>
        </Fade>
      </Box>
      {/* Sixth box */}
    </>
  );
};

export default HomeWebsite;