import { Box, Typography } from "@mui/material";
import React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { grayDark } from "../../../../../../ui/color";
import FormComponent from "../../../../../forms/formcomponent";
import {
  ISelectKitParams,
} from "../../../../order/linkProduct/selectAKit";
import { FlowContentTemplate } from "../flowContentTemplate";

interface IParamsSelectKit extends ISelectKitParams{
    previous: () => void;
}

export const FlowSelectKit = ({
  formKit,
  handleSubmitKit,
  loading,
  previous,
}: IParamsSelectKit) => {
  const {t} = useTranslation();

  const disableBtn = useMemo(() => {
    return formKit.watch().kitId === undefined;
  }, [formKit.watch()]);

  return(
    <FlowContentTemplate
      title={t("fourthStep")}
      subtitle={t("wichBox")}
      displayedBtn={true}
      previousAction={previous}
      nextAction={formKit.handleSubmit(handleSubmitKit as never)}
      loadingNext={loading}
      disableNextBtn={disableBtn}
    >
      <Box sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}>
        <Box sx={{
          width: "70%",
          mb: 2
        }}>
          <Typography sx={{
            color: grayDark,
            textAlign: "center",
          }}>
            {t("explanationKitId")}
          </Typography>
        </Box>
        <FormComponent 
          watch={formKit.watch}
          control={formKit.control}
          formState={formKit.formState}
          arrayForms={[{
            type: "TextField",
            elementTextField: {
              name: "kitId",
              id: "kitId",
              type: "text",
              label: "kitId",
              variant: "standard"
            }
          }]}
        />
      </Box>
    </FlowContentTemplate>
  );
};