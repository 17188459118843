import {
  addDoc,
  collection, 
  DocumentReference,
  getDocs,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { collections, IDataFrontDeleteFollow, IFollow } from "type-absenso";
import { db } from "./config";
import { postAuth } from "./utils/postAuth";
import { deleteFollowUrl } from "./utils/url";

export const addFollow = async (
  data: IFollow<DocumentReference, Timestamp>,
  exist?: boolean,
  idFollow?: string,
) => {
  if (!exist) {
    const followRef = collection(db, collections.follow);
    const doc = await addDoc(followRef, data);

    return doc.id;
  } else {
    if (idFollow) {
      const dataDelete: IDataFrontDeleteFollow = {
        idFollow, 
      };
      await postAuth(deleteFollowUrl, dataDelete);

      return undefined;
    }

    return undefined;
  }
};

export const getStatusFollow = async (
  userGiving: DocumentReference,
  death: DocumentReference,
) => {
  const followRef = collection(db, collections.follow);
  const q = query(
    followRef,
    where("userGiving", "==", userGiving),
    where("death", "==", death)
  );
  const snaps = await getDocs(q);

  return {
    exist: Boolean(snaps.size > 0),
    id:  snaps.docs && snaps.docs[0] && snaps.docs[0].id
  };

};