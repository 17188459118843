/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { PageUserContext } from "../../provider/user/pageProvider";
import { UserPicture } from "../../components/utils/user/userPicture";
import { useTranslation } from "react-i18next";
import { gold, } from "../../ui/color";
import { Outlet, useNavigate } from "react-router-dom";
import { routing } from "../../router";

const Page = () => {
  const pageHandler = useContext(PageUserContext);
  const {t} = useTranslation();
  const navigate = useNavigate();

  if (pageHandler && !pageHandler.loading && pageHandler.userData) {
    const user = pageHandler.userData;
    return(
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        py: 0
      }}>
        <Box sx={{
          width: "100%",
          backgroundColor: "white",
          // eslint-disable-next-line max-len
          boxShadow: "0px 3px 25px -2px rgba(0, 0, 0, 0.14), 0px 2px 20px rgba(0, 0, 0, 0.02), 0px 1px 15px rgba(0, 0, 0, 0.03)",
          borderRadius: "0px 0px 25px 25px",
          p: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column"
        }}>
          <UserPicture 
            user={{...pageHandler.userData, id: ""}}
            className="rounded_img"
            sx={{
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              height: (theme: any) => theme.spacing(15),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              width: (theme: any) => theme.spacing(15)
            }}
            sxIcon={{
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              fontSize: (theme: any) => theme.spacing(15),
            }}
          />

          <Box sx={{
            mt: 1,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column"
          }}>
            <Typography sx={{
              fontWeight: 300,
              fontSize: theme => theme.spacing(5),
              lineHeight: theme => theme.spacing(6),
              textAlign: "center"
            }}>
              {`${user.firstName} ${user.name}`}
            </Typography>
            <Box sx={{
              display: "flex",
              flexDirection: {md: "row", xs: "column"},
              mt: 1
            }}>
              <Button
                variant="contained"
                onClick={() => navigate(routing.user.parameter.index)}
                sx={{
                  backgroundColor: gold,
                  height: "auto",
                  mr: {md: 1, xs: 0},
                  mb: {md: 0, xs: 1}
                }}>
                {t("parameters")}
              </Button>
              <Button
                onClick={() => navigate(
                  // eslint-disable-next-line max-len
                  `${routing.user.parameter.index}/${routing.user.parameter.adminProfil}`
                )}
                variant="contained"
                sx={{
                  height: "auto",
                  backgroundColor: gold
                }}
              >
                {t("titleAdminProfiles")}
              </Button>
            </Box>
          </Box>
        </Box>

        <Outlet />
      </Box>
    );
  }
  
  return(
    <></>
  );
};

export default Page;
