import React from "react";

// Ui
import { Typography } from "@mui/material";

// I18n
import { useTranslation } from "react-i18next";

const PaymentFailed = () => {
  const {t} = useTranslation();
  return(
    <>
      <Typography>{t("paymentFailed")}</Typography>
    </>
  );
};

export default PaymentFailed;