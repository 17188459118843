/* eslint-disable @typescript-eslint/no-explicit-any */
import { collections, IDeath, IFuneralCompany } from "type-absenso";
import { db } from "./config";
import {
  doc,
  DocumentReference,
  getDoc,
  Timestamp,
  collection,
  query,
  where,
  limit,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { IDeathFront } from "./type/frontDocument";
import { getLastBouquet } from "./bouquet";

export const getDeathWithAdmin = async (idUser: string) => {
  const deathRef = collection(db, collections.death);
  const dataDeath: Array<IDeathFront> = [];

  const q = query(
    deathRef,
    where("admin", "array-contains", doc(db, collections.users, idUser)),
    orderBy("fullname")
  );

  const snaps = await getDocs(q);
  for await (const snap of snaps.docs) {
    const data = snap.data() as IDeath<DocumentReference, Timestamp>;
    dataDeath.push({
      ...data,
      id: snap.id,
    });
  }

  return dataDeath;
};

export const getDeath = async ({
  id
}: {
    id: string;
}) => {
  const deathRef = doc(db, collections.death, id);
  const snap = await getDoc(deathRef);

  if (snap.exists()) {
    const data = snap.data() as IDeath<DocumentReference, Timestamp>;
    const bouquet = await getLastBouquet(id);

    if (data.funeral) {
      const snapFuneral = await getDoc(doc(db, data.funeral.path));
      const funeralData = snapFuneral.data() as IFuneralCompany<
        Timestamp
      > | undefined;
      
      return {...data, id: snap.id, bouquet, funeralData};
    }
    return {...data, id: snap.id, bouquet};
  } else {
    return undefined;
  }
};

export const searchDeath = async (search: string) => {
  const dataArray: Array<IDeathFront> = [];
  const deathRef = collection(db, collections.death);
  const qName = query(
    deathRef,
    where("nameSearch", ">=", search.toLowerCase()),
    where("nameSearch", "<=", search.toLowerCase()+"~"),
    orderBy("nameSearch"),
    limit(10)
  );

  const qFirstName = query(
    deathRef,
    where("firstNameSearch", ">=", search.toLowerCase()),
    where("firstNameSearch", "<=", search.toLowerCase()+"~"),
    orderBy("firstNameSearch"),
    limit(10)
  );

  const qFullName = query(
    deathRef,
    where("fullnameSearch", ">=", search.toLowerCase()),
    where("fullnameSearch", "<=", search.toLowerCase()+"~"),
    orderBy("fullnameSearch"),
    limit(10)
  );

  const snapsFirstName = await getDocs(qFirstName);
  const snapsName = await getDocs(qName);
  const snapsFullName = await getDocs(qFullName);

  for await (const snapFullName of snapsFullName.docs) {
    const data = snapFullName.data() as IDeath<DocumentReference, Timestamp>;
    dataArray.push({
      ...data,
      id: snapFullName.id,
    });
  }

  for await (const snapFirstName of snapsFirstName.docs) {
    const data = snapFirstName.data() as IDeath<DocumentReference, Timestamp>;
    dataArray.push({
      ...data,
      id: snapFirstName.id,
    });
  }

  for await (const snapName of snapsName.docs) {
    const data = snapName.data() as IDeath<DocumentReference, Timestamp>;
    dataArray.push({
      ...data,
      id: snapName.id,
    });
  }

  const sortData = (data: Array<IDeathFront>) => {
    const idArray: Array<string> = [];
    const newData: Array<IDeathFront> = [];

    for (const datum of data) {
      if (!idArray.includes(datum.id)) {
        idArray.push(datum.id);
        newData.push(datum);
      }
    }

    return newData.sort((a, b) => (a.firstName > b.firstName) ? 1 : -1);
  };
  
  return sortData(dataArray);
};

export const getDeathWithLink = async(text: string) => {
  const deathRef = collection(db, collections.death);
  const q = query(
    deathRef,
    where("link", "==", text.toLowerCase()),
    limit(1)
  );
  const snaps = await getDocs(q);

  if (snaps.docs && snaps.docs.length > 0) {
    return snaps.docs[snaps.docs.length - 1].id;
  }

  return undefined;
};
