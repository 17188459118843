import React from "react";
import { FlowContentTemplate } from "../flowContentTemplate";
import Signup from "../../../../../../views/auth/signup";
import Signin from "../../../../../../views/auth/signin";
import { ISignupForm } from "../../../../../forms/asset/auth/signup";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import { gold } from "../../../../../../ui/color";
import { ChevronRight } from "@mui/icons-material";

export const FlowCreateAccount = ({
  methodAfterCreation,
  isLandscape,
}: {
  methodAfterCreation: (signupForm: ISignupForm) => void,
  isLandscape?: boolean;
}) => {
  const {t} = useTranslation();

  const [submit, setSubmit] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>();
  const [useSignin, setUseSignin] = useState<boolean>(false);

  const toggleUseSignin = () => {
    setUseSignin(!useSignin);
  };

  return(
    <FlowContentTemplate
      title={t("firstlyStep")}
      subtitle={useSignin ? t("titleSignin"):t("createAccount")}
      nextAction={() => setSubmit(submit + 1)}
      loadingNext={loading}
    >
      {useSignin ? (
        <>
          <Signin modalFlowView={true} toggleSignin={toggleUseSignin}/>
        </>
      ) : (
        <>
          <Signup
            modalFlowView={true}
            notDisplayedBgImage={true}
            methodAfterCreation={methodAfterCreation}
            submitFormFromOutside={submit}
            setLoadingFlow={(loading: boolean) => setLoading(loading)}
            setSubmit={(submit: number) => setSubmit(submit)}
            toggleUseSignin={toggleUseSignin}
          />
          <Box sx={{
            width: "100%",
            pt: 2,
            pb: 1,
            display: isLandscape ? {
              xs: "flex",
              md: "none",
            } : "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
            <LoadingButton
              onClick={() => setSubmit(submit+1)}
              loading={loading}
              variant={"gold" as never}
              sx={{
                backgroundColor: gold,
                width: "100%",
              }}>
              {t("createAccountBtn")}
              <ChevronRight />
            </LoadingButton>
            <Box>
              <Typography variant={"body2"} sx={{
                textAlign: "center",
                mt: 5,
                color: "text.secondary"
              }}>
                {t("alreadyHaveAccount")}
              </Typography>
              <Button
                onClick={toggleUseSignin}
                variant="text"
                sx={{
                  mt: 2, 
                  textTransform: "uppercase", 
                  alignSelf: "center",
                  color: gold
                }}
              >
                {t("titleSignin")}
                <ChevronRight />
              </Button>
            </Box>
          </Box>
        </>
      )}
    </FlowContentTemplate>
  );
};