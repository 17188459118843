import { getAuth } from "@firebase/auth";
import { Timestamp } from "firebase/firestore";
import React from "react";
import { useEffect } from "react";
import { createContext } from "react";
import { useState } from "react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { IUsers } from "type-absenso";
import { app } from "../../backend/config";
import { getUserPosts } from "../../backend/post";
import { IPostFrontWithDeceased } from "../../backend/type/frontDocument";
import { getUser } from "../../backend/user";
import { useUser } from "../auth/hooks/useUser";
import { useAlert } from "../error/hooks/useAlert";
import { IUserFront } from "../../backend/type/frontDocument";

export const PageUserContext = createContext<null | {
    userData: IUsers<Timestamp> | undefined;
    loading: boolean;
    loadingPosts: boolean;
    posts: Array<IPostFrontWithDeceased> | undefined;
    getLastPosts: () => Promise<void>;
    hasMore: boolean;
      }>(null);

const PageProvider = ({
  children
}: {
    children: ReactNode
}) => {
  const { id } = useParams<string>();
  const localUser = getAuth(app).currentUser;
  const isMe = Boolean(id === (localUser && localUser.uid));
  const user = useUser();
  const alert = useAlert();
  const {t} = useTranslation();

  const [userData, setUserData] = useState<IUserFront>();
  const [posts, setPosts] = useState<Array<IPostFrontWithDeceased>>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPosts, setLoadingPosts] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);

  useEffect(() => {
    if (user && isMe) {
      setUserData(user);
    } else {
      (async() => {
        setLoading(true);
        if (id) {
          try {
            const userSnap = await getUser({
              uid: id,
            });

            if (userSnap && userSnap.type.includes("user")) {
              setUserData(userSnap);
            }
          } catch (error) {
            if (alert) {
              alert.defaultError();
            }
          }
          setLoading(false);
        }
      })();
    }
  }, [isMe]);

  const getLastPosts = async () => {
    if (id && userData) {
      const userPosts = await getUserPosts(
        (posts && posts[posts.length - 1]) || undefined,
        id,
        userData.id,
        setHasMore,
      );
      
      if (userPosts) {
        if (posts) {
          setPosts(posts.concat(userPosts));
        } else {
          setPosts(userPosts);
        }
      }
    }
  };

  useEffect(() => {
    if (userData && id) {
      (async() => {
        setLoadingPosts(true);
        try {
          await getLastPosts();
        } catch (error) {
          if (alert) {
            alert.defaultError();
          }
        }
        setLoadingPosts(false);
      })();
    }
  }, [userData, id]);

  return(
    <PageUserContext.Provider value={{
      userData,
      loading,
      loadingPosts,
      posts,
      getLastPosts,
      hasMore,
    }}>
      {children}
    </PageUserContext.Provider>
  );
};

export default PageProvider;

