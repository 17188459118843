import React from "react";
import { ButtonBase, Typography, Box, CircularProgress } from "@mui/material";
import { gold } from "../../../../../ui/color";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export const ItemBtnProfile = ({icon, text, action, loading}: {
  icon: ReactNode;
  text: string;
  action: () => void;
  loading?: boolean;
}) => {
  const {t} = useTranslation();
  return(
    <Box sx={{textAlign: "center"}}>
      <ButtonBase
        onClick={action}
        sx={{
          height: "50px",
          width: "50px",
          borderRadius: "50%",
          border: `1px solid ${gold}`,
          backgroundColor: "white",
        }}
      >
        {loading ? <>
          <CircularProgress size="15px" sx={{color: gold}} />
        </> : <>{icon}</>}
      </ButtonBase>
      <Typography sx={{
        fontSize: theme => theme.spacing(1.5),
        fontWeight: 500,
        lineHeight: theme => theme.spacing(2),
        mt: 0.5
      }}>
        {t(text)}
      </Typography>
    </Box>
  );
};