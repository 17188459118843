import { useContext } from "react";
import { useTranslation } from "react-i18next";

// Context
import { AlertContext } from "../alertprovider";
import { IAlert } from "../types/alert";

export const useAlert = () => {
  const alertHandler = useContext(AlertContext);
  const {t} = useTranslation();

  const defaultError = () => {
    if (alertHandler) {
      alertHandler.createAlert({
        type: "error",
        message: t("errorStandard")
      });
    }
  };

  const createAlert = (data: IAlert) => {
    alertHandler && alertHandler.createAlert && alertHandler.createAlert({
      type: data.type,
      message: data.message,
    });
  };

  return {...alertHandler, defaultError, createAlert};
};