import React from "react";
import { getLang } from "../../i18n/i18n";

const PrivacyPolicy = () => {
  const lang = getLang();
  return <>
    <iframe
      width="100%"
      style={{
        height: "100vh"
      }}
      src={require(`../../media/pdf/${lang}_privacy_policy.pdf`)}
    />
  </>;
};

export default PrivacyPolicy;