import { getAuth } from "firebase/auth";
import { doc } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { collections } from "type-absenso";
import { app, db } from "../../../../../backend/config";
import { getStatusFollow } from "../../../../../backend/follow";
import { useAlert } from "../../../../../provider/error/hooks/useAlert";
import { Timestamp, DocumentReference } from "@firebase/firestore";
import { IFollow } from "type-absenso";
import { addFollow } from "../../../../../backend/follow";

export const useFollow = (
  idDeath: string,
) => {
  const user = getAuth(app).currentUser;
  const alert = useAlert();

  const [loading, setLoading] = useState<boolean>();
  const [follow, setFollow] = useState<{
      id?: string,
      followed: boolean,
  }>();

  useEffect(() => {
    if (user) {
      (async() => {
        try {
          setLoading(true);
          const status = await getStatusFollow(
            doc(db, collections.users, user.uid),
            doc(db, collections.death, idDeath)
          );
          setLoading(false);
          setFollow({
            id: status.id,
            followed: status.exist
          });
        } catch (error) {
          if (alert) {
            alert.defaultError();
          }
        }
      })();
    }
  }, [idDeath]);

  const followHandler = async () => {
    if (user) {
      try {
        setLoading(true);
        const data: IFollow<DocumentReference, Timestamp> = {
          userGiving:  doc(db, collections.users, user.uid),
          death: doc(db, collections.death, idDeath),
          date: Timestamp.now()
        };

        const resFollow = await addFollow(
          data, 
          follow && follow.followed,
          follow && follow.id
        );

        if (resFollow) {
          setFollow({followed: true, id: resFollow});
        } else {
          setFollow({followed: false, id: undefined});
        }
        setLoading(false);
      } catch (error) {
        alert.defaultError();
      }
    }
  };

  const followed = useMemo(() => !loading && follow && follow.followed, [
    loading, follow
  ]);

  return { loading, followHandler, follow, followed };
};