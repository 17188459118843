import React, { ReactNode, useState } from "react";

// Ui
import {
  Box, ButtonBase, Button, IconButton, Typography
} from "@mui/material";
import { Navbar } from "../../components/utils/layout/navbar";
import { Menu } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { gold } from "../../ui/color";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { routing } from "../../router";
import { SearchDeceased } from "../../components/utils/deceased/searchDeceased";
import { LangManager } from "../../components/utils/website/langManager";
import AlertProvider from "../../provider/error/alertprovider";
import {
  FlowContainer
} from "../../components/utils/website/createMemorial/flow/flowContainer";

const LayoutUnAuth = ({children}: {children: ReactNode}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [openMemorialFlow, setOpenMemorialFlow] = useState<boolean>(
    Boolean(searchParams.get("open") === "true")
  );

  const menuHandler = () => setOpenMenu(!openMenu);

  const scrollToId = async (id: string) => {
    if (location.pathname !== routing.website.index) {
      navigate(routing.website.index);
      setOpenMenu(!openMenu);
    }
    
    const ref = document.getElementById(id);
    if (ref) {
      ref.scrollIntoView({
        behavior: "smooth"
      });
    }
  };

  const openMemorialHandler = () => {
    setOpenMemorialFlow(!openMemorialFlow);
  };

  return(
    <>
      {openMemorialFlow && 
        <AlertProvider>
          <FlowContainer
            open={true}
            onClose={openMemorialHandler}
            step={searchParams.get("step") ? parseInt(
              searchParams.get("step") as string
            ) : undefined}
          />
        </AlertProvider>
      }
      <Navbar
        mobileOnClose={() => setOpenMenu(!openMenu)}
        mobileComponent={<Box>
          {[
            {text: t("home"), action: () => scrollToId("home")},
            {
              text: t("presentation"),
              action: () => scrollToId("presentation")
            },
            {text: t("testimony"), action: () => scrollToId("testimony")},
            {text: t("offer"), action: () => scrollToId("offer")},
            {text: t("contact"),action: () => scrollToId("contact")}
          ].map((el, i) => (
            <ButtonBase 
              onClick={el.action}
              key={i} 
              sx={{
                display: "flex",
                justifyContent: "left",
                width: "100%",
                p: 2,
              }}>
              <Typography sx={{
                fontSize:theme => theme.spacing(2),
                lineHeight:theme => theme.spacing(2),
                fontWeight: 500,
              }}>{el.text}</Typography>
            </ButtonBase>
          ))}

          <Box px={2}>
            <LangManager />
          </Box>

          <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            pt: 2,
            pb: 2
          }}>
            <Box sx={{width: "90%"}}>
              <SearchDeceased afterChoose={() => setOpenMenu(!openMenu)} />
            </Box>
          </Box>

          <Box 
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              width: "100%"
            }}>
            <Button 
              variant="contained" 
              sx={{
                width: "90%",
                backgroundColor: gold,
                my: 1
              }}
              onClick={openMemorialHandler}
            >
              {t("tryitfree")}
            </Button>
            <Button 
              onClick={() => {
                navigate(routing.auth.signin);
                setOpenMenu(!openMenu);
              }}
              sx={{
                width: "90%",
                backgroundColor: gold,
                my: 1
              }}
              variant="contained"
            >
              {t("connectTitle")}
            </Button>
          </Box>
        </Box>}
        openMobile={openMenu}
        right={
          <>
            <Box sx={{
              display: {md: "flex", xs: "none"}
            }} >
              <Button 
                sx={{
                  backgroundColor: gold,
                  height: theme => theme.spacing(3.5),
                  fontSize: theme => theme.spacing(1.3),
                  fontWeight: "bold",
                  padding: "revert",
                  mr: 1
                }}
                variant="contained"
                onClick={openMemorialHandler}
              >
                {t("tryitfree")}
              </Button>
              <Button 
                variant="contained" 
                onClick={() => navigate(routing.auth.signin)}
                sx={{
                  backgroundColor: gold,
                  height: theme => theme.spacing(3.5),
                  fontSize: theme => theme.spacing(1.3),
                  padding: "revert",
                  fontWeight: "bold",
                  mr: 1
                }}>{
                  t("connectTitle")}
              </Button>
            </Box>

            <Box sx={{
              display: {md: "none", xs: "initial"},
              justifyContent: "center",
              alignItems: "center"
            }}>
              <IconButton onClick={menuHandler}>
                <Menu sx={{
                  color: "white"
                }} />
              </IconButton>
            </Box>
          </>
        }
        left={
          <Box sx={{
            display: {md: "flex", xs: "none"},
            alignItems: "center",
            width: "100%"
          }}>
            <SearchDeceased />
          </Box>
        }
      >
        <Box sx={{
          minHeight: "100vh",
          borderRadius: "50%"
        }}>
          {children}
        </Box>
      </Navbar>
    </>
  );
};

export default LayoutUnAuth;