import React from "react";
import { useTranslation } from "react-i18next";
import { OtherMethod } from "../othermethod";
import { LayoutFlow } from "./layoutflow";
import { useForm } from "react-hook-form";
import FormComponent from "../../../forms/formcomponent";
import { signupForm } from "../../../forms/asset/auth/signup";
import { ICreateEmailProps } from "../../../../views/auth/signup";

export const CreateAccount = ({action}: {
  action: (
    data: ICreateEmailProps["data"],
    errorHandler?: ICreateEmailProps["errorHandler"]
  ) => void
}) => {
  const {t} = useTranslation();
  const {watch, control, formState, handleSubmit, setError} = useForm();
  return(
    <>
      <LayoutFlow
        titleStep={t("stepOneSignup")}
        actionBtn={handleSubmit(
          data => action(data as {email: string}, setError) as never)}
        subtitleStep={t("subtitleStepOne")}
        titleBtn={t("nextStep")}
        loadingBtn={false}
        content={<>
          <OtherMethod goal="registration" action={action} />

          <FormComponent 
            watch={watch}
            formState={formState}
            arrayForms={signupForm.filter(
              el => {
                if (el.elementTextField) {
                  return el.elementTextField.name === "email";
                }
              }
            )}
            control={control}
          />
        </>}
      />
    </>
  );
};