import React, { useState, useEffect, useMemo } from "react";
import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import ImageCropper from "../imagecropper";
import FormComponent from "../../forms/formcomponent";
import { FuneralCompanyItem } from "./funeralCompanyItem";
import { SearchFuneralCompany } from "./searchFuneralCompany";
import { createDeceasedForm } from "../../forms/asset/deceased/createdeceased";
import { IAssetAutoCompleteField } from "../../forms/types";
import { searchAddress } from "../../../backend/adress";
import { IFuneralCompanyFront } from "../../../backend/type/frontDocument";
import { getDetailsAddress } from "../../../backend/adress";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { religionTypes } from "type-absenso";

export interface IDataFormDeceased {
  address: IAssetAutoCompleteField<string>;
  biography?: string;
  dateOfBirth: Date;
  dateOfDeath: Date; 
  firstName: string;
  funeralCompany?: string;
  hash: string;
  latitude: number;
  longitude: number;
  name: string;
  religion?: IAssetAutoCompleteField<religionTypes>;
  crop?: File;
  modalFlowView?: boolean;
}

export const FormDeceased = ({
  form,
  notDisplayedTitle,
  backgroundInputFuneralCompany,
  modalFlowView,
}: {
  form: UseFormReturn<FieldValues, unknown>;
  notDisplayedTitle?: boolean;
  backgroundInputFuneralCompany?: string;
  modalFlowView?: boolean;
}) => {
  const {t} = useTranslation();

  const [loadingAddress, setLoadingAddress] = useState<boolean>(true);
  const [optionsAddress, setOptionsAddress] = useState<
    Array<IAssetAutoCompleteField<string>>
  >([]);

  const [funeralCompany, setFuneralCompany] = useState<
    IFuneralCompanyFront
  >();
  const [urlCrop, setUrlCrop] = useState<string>();

  const addressField: IAssetAutoCompleteField<
    string
  >  = useMemo(() => form.watch("address"), [form.watch("address")]);

  const onChangeAddress = async (text: string) => {
    setLoadingAddress(true);
    const resAddress = await searchAddress(text);
    if (resAddress) {
      setOptionsAddress(resAddress);
    }
    setLoadingAddress(false);
  };

  useEffect(() => {
    form.setValue(
      "address", {
        value: "ChIJl5lTNN3Qw0cR47yU6yfd16Q",
        label: "Avenue Reine Astrid 92, La Hulpe, Belgium"
      });
    form.setValue("latitude", 50.72886331);
    form.setValue("longitude", "4.4665861");
    form.setValue("hash", "u150tdumnc");
    form.setValue("dateOfBirth", new Date());
    form.setValue("dateOfDeath", new Date());
  }, []);

  useEffect(() => {
    if (addressField) {
      (async() => {
        const details = await getDetailsAddress(addressField.value);
        if (details) {
          form.setValue("latitude", details.lat);
          form.setValue("longitude", details.lng);
          form.setValue("hash", details.hash);
        }
      })();
    }
  }, [addressField]);

  const deceasedForm = createDeceasedForm({
    onChangeAddress,
    optionsAddress,
    loadingAddress
  });

  const onCrop = (crop: File) => {
    form.setValue("crop", crop);
  };

  const onSelectFuneralCompany = (company: IFuneralCompanyFront) => {
    setFuneralCompany(company);
    form.setValue("funeralCompany", company.id);
  };

  if (modalFlowView) {
    return (
      <Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: {
              xs: "center",
              md: "space-between",
            },
          }}
        >
          <Box
            sx={{
              width: {
                xs: "100%",
                md: "48%",
              },
            }}
          >
            <Typography
              variant={"subtitleOffer" as never}
              sx={{
                fontWeight: 700,
                textTransform: "uppercase",
              }}
            >
              {t("profilePicture")}
            </Typography>
            <Box py={2}>
              <ImageCropper
                onCrop={onCrop}
                urlCrop={urlCrop}
                setUrlCrop={setUrlCrop}
                required={false}
                imgStyle={{
                  width: "176px",
                  height: "176px",
                  borderRadius: "50%",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: {
                xs: "100%",
                md: "48%",
              },
            }}
          >
            {!notDisplayedTitle && (
              <Typography
                variant={"titleOffer" as never}
                sx={{
                  pb: 2,
                }}
              >
                {t("titleCreateFormDeceased")}
              </Typography>
            )}
            <Typography
              variant={"subtitleOffer" as never}
              sx={{
                fontWeight: 700,
                textTransform: "uppercase",
              }}
            >
              {t("baseInformation")}
            </Typography>
            <FormComponent
              control={form.control}
              formState={form.formState}
              watch={form.watch}
              arrayForms={deceasedForm.slice(0, 3)}
            />

            <Box mt={1}>
              {funeralCompany && (
                <Box pb={1}>
                  <FuneralCompanyItem
                    selected={true}
                    company={funeralCompany}
                  />
                </Box>
              )}
              <SearchFuneralCompany
                onSelect={onSelectFuneralCompany}
                backgroundInput={backgroundInputFuneralCompany}
              />
            </Box>
            <Box>
              <Typography
                variant={"subtitleOffer" as never}
                sx={{
                  fontWeight: 700,
                  textTransform: "uppercase",
                  pt: 2
                }}
              >
                {t("dateTitle")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                  justifyContent: {
                    xs: "center",
                    md: "space-between",
                  },
                }}
              >
                <FormComponent
                  sxContainer={{
                    width: {
                      xs: "100%",
                      md: "47%",
                    },
                  }}
                  control={form.control}
                  formState={form.formState}
                  watch={form.watch}
                  arrayForms={deceasedForm.slice(3, 4)}
                />
                <FormComponent
                  sxContainer={{
                    width: {
                      xs: "100%",
                      md: "47%",
                    },
                  }}
                  control={form.control}
                  formState={form.formState}
                  watch={form.watch}
                  arrayForms={deceasedForm.slice(4, 5)}
                />
              </Box>
              <Typography
                variant={"subtitleOffer" as never}
                sx={{
                  fontWeight: 700,
                  textTransform: "uppercase",
                  pt: 2,
                }}
              >
                {t("lastAddress")}
              </Typography>
              <FormComponent
                control={form.control}
                formState={form.formState}
                watch={form.watch}
                arrayForms={deceasedForm.slice(5, 8)}
              />
              <Typography
                variant={"subtitleOffer" as never}
                sx={{
                  fontWeight: 700,
                  textTransform: "uppercase",
                  pt: 2,
                }}
              >
                {t("religionTitle")}
              </Typography>
              <FormComponent
                control={form.control}
                formState={form.formState}
                watch={form.watch}
                arrayForms={deceasedForm.slice(8, 9)}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  return(
    <>
      {!notDisplayedTitle && <Typography variant={"titleOffer" as never} sx={{
        pb: 2
      }}>
        {t("titleCreateFormDeceased")}
      </Typography>}
      <Typography variant={"subtitleOffer" as never} sx={{
        fontWeight: 700,
        textTransform: "uppercase"
      }}>
        {t("profilePicture")}
      </Typography>
      <Box py={2}>
        <ImageCropper
          onCrop={onCrop}
          urlCrop={urlCrop}
          setUrlCrop={setUrlCrop}
          required={false}
          imgStyle={{
            width: "176px",
            height: "176px",
            borderRadius: "50%"
          }}
        />
      </Box>
      <Typography variant={"subtitleOffer" as never} sx={{
        fontWeight: 700,
        textTransform: "uppercase"
      }}>
        {t("baseInformation")}
      </Typography>
      <FormComponent
        control={form.control}
        formState={form.formState}
        watch={form.watch}
        arrayForms={deceasedForm.slice(0, 3)}
      />

      <Box mt={1}>
        {funeralCompany && <Box pb={1}>
          <FuneralCompanyItem
            selected={true}
            company={funeralCompany}
          />
        </Box>}
        <SearchFuneralCompany
          onSelect={onSelectFuneralCompany}
          backgroundInput={backgroundInputFuneralCompany}
        />
      </Box>

      <Typography variant={"subtitleOffer" as never} sx={{
        fontWeight: 700,
        textTransform: "uppercase",
        pt: 2,
      }}>
        {t("dateTitle")}
      </Typography>
      <FormComponent
        control={form.control}
        formState={form.formState}
        watch={form.watch}
        arrayForms={deceasedForm.slice(3, 5)}
      />
      <Typography variant={"subtitleOffer" as never} sx={{
        fontWeight: 700,
        textTransform: "uppercase",
        pt: 2,
      }}>
        {t("lastAddress")}
      </Typography>
      <FormComponent
        control={form.control}
        formState={form.formState}
        watch={form.watch}
        arrayForms={deceasedForm.slice(5, 8)}
      />
      <Typography variant={"subtitleOffer" as never} sx={{
        fontWeight: 700,
        textTransform: "uppercase",
        pt: 2,
      }}>
        {t("religionTitle")}
      </Typography>
      <FormComponent
        control={form.control}
        formState={form.formState}
        watch={form.watch}
        arrayForms={deceasedForm.slice(8, 9)}
      />
    </>
  );
};