import React, { useState } from "react";
import { FieldValues, useForm, UseFormSetError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IPasswordForm, passwordsForm } from "../../../forms/asset/auth/signup";
import FormComponent from "../../../forms/formcomponent";
import { LayoutFlow } from "./layoutflow";
import VisibilityIcon from "../signin/visibilityIcon";

export const CreatePassword = ({action}: {
    action: (
      data: IPasswordForm,
      errorHandler: UseFormSetError<FieldValues>
      ) => void
}) => {
  const [visibility, setVisibility] = useState<boolean>(false);
  const [visibilityVerify, setVisibilityVerify] = useState<boolean>(false);
  const {t} = useTranslation();
  const {control, watch, formState, handleSubmit, setError} = useForm();

  const handleClickShowPassword = () => setVisibility((show) => !show);
  const handleClickShowPasswordVerify = () => setVisibilityVerify(
    (show) => !show
  );

  return(
    <>
      <LayoutFlow 
        titleBtn={t("nextStep")}
        titleStep={t("step2Signup")}
        subtitleStep={t("subtitleStepTwo")}
        actionBtn={handleSubmit(
          data => action(data as IPasswordForm, setError) as never
        )}
        loadingBtn={false}
        content={
          <>
            <FormComponent 
              watch={watch}
              formState={formState}
              control={control}
              arrayForms={passwordsForm(
                visibility ? "text" : "password",
                visibilityVerify ? "text" : "password",
                (
                  <VisibilityIcon
                    showPassword={handleClickShowPassword}
                    visibility={visibility}
                  />
                ),
                (
                  <VisibilityIcon
                    showPassword={handleClickShowPasswordVerify}
                    visibility={visibilityVerify}
                  />
                ),
              )}
            />
          </>
        }
      />
    </>
  );
};