import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface IPropsCardTestimony {
    username: string;
    userImage: string;
    starsImage: string;
    familyImage: string;
    text: string;
    withoutPicture?: boolean;
}

export const CardTestimony = ({
  username,
  userImage,
  starsImage,
  familyImage,
  text,
  withoutPicture,
}: IPropsCardTestimony) => {
  const {t} = useTranslation();
  return(
    <Grid 
      container 
      sx={{
        width: "100%",
        backgroundColor: "white",
        borderRadius: 1,
        overflow: {md: "hidden", xs: "initial"},
      }}>
      <Grid item xs={12} md={
        withoutPicture ? 12 : 6
      } px={3} order={{md: 1, xs: 2}} sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        p: 2
      }}>
        <Typography sx={{
          fontWeight: 400,
          fontSize: {md: "24px", xs: "14px"}
        }}>{t(text)}</Typography>
        <Box sx={{
          borderTop: "1px solid #DCDCDC",
          width: "100%",
          my: 2
        }} />

        <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%"
        }}>
          <Box sx={{
            display: "flex",
            alignItems: "center"
          }}>
            <img
              src={userImage}
              className="img-small-card-box-3"
              alt="J. Lacroix's picture"
            />
            <Typography pl={1} sx={{
              letterSpacing: "0.03em",
              lineHeight: "16px",
              fontSize: {md: "20px", xs: "13px"},
              fontWeight: 400
            }}>{username}</Typography>
          </Box>
          <img
            src={starsImage}
            className="stars-box-3"
            alt="stars's picture"
          />
        </Box>
      </Grid>
      <Grid item md={6} xs={12} order={{md: 2, xs: 1}} sx={{
        marginBottom: "-6px",
        display: withoutPicture ? "none" : "block"
      }}>
        <img
          className="img-box3-card"
          src={familyImage}
          alt="absenso testimony"
        />
      </Grid>
    </Grid>
  );
};