import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Typography
} from "@mui/material";
import { Google } from "@mui/icons-material";
import { gold, grayLight } from "../../../ui/color";
import { useAuth } from "../../../provider/auth/hooks/useAuth";
import { ISignupForm } from "../../forms/asset/auth/signup";
import { goalSigninGoogle } from "../../../provider/auth/authprovider";
import { getUser } from "../../../backend/user";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../../provider/error/hooks/useAlert";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

export const OtherMethod = ({
  action,
  goal,
  isLandscape,
}: {
  goal: goalSigninGoogle;
  action: ({
    email,
  }: {
    email: string;
    dataProvider: ISignupForm;
    type: "google";
  }) => void;
  isLandscape?: boolean;
}) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();
  const alert = useAlert();

  const signupGoogle = async () => {
    if (auth) {
      const user = await auth.signinWithGoogle(goal);
      const firebaseAuth = getAuth();
      const userInDb = await getUser({ uid: user.uid });
      if (!userInDb) {
        alert && alert.createAlert({
          message: t("errorGoogle"),
          type: "error",
        });
        navigate("/auth/signup");
        signOut(firebaseAuth);
      }
      if (user.email) {
        const dataProvider: ISignupForm = {};
        if (user.providerData && user.providerData.length > 0) {
          const data = user.providerData[0];
          const displayName = data.displayName;
          const nameSplit = displayName && displayName.split(" ");
          dataProvider.firstName = nameSplit ? nameSplit[0] : undefined;
          dataProvider.name =
            nameSplit && nameSplit.length > 0 ? nameSplit[1] : undefined;
          dataProvider.phone = data.phoneNumber || undefined;
          dataProvider.profilePictures = data.photoURL || undefined;
          dataProvider.uidGoogle = user.uid;
        }
        action({
          email: user.email,
          dataProvider,
          type: "google",
        });
      }
    }
  };

  if (isLandscape)
    return (
      <Box
        sx={{
          order: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Button
          onClick={signupGoogle}
          sx={{
            my: 2,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            order: 2,
          }}
          variant={"outlinedGold" as never}
        >
          <Google
            sx={{ color: gold, marginRight: (theme) => theme.spacing(1) }}
          />
          <Box>
            {goal === "registration"
              ? t("googleRegistration")
              : t("googleConnexion")}
          </Box>
        </Button>

        <Box
          mt={4}
          mb={1}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            order: 1,
          }}
        >
          <Box sx={{ borderTop: `1px solid ${grayLight}`, width: "10%" }} />
          <Box
            sx={{
              width: "80%",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Typography
              sx={{
                textTransform: "uppercase",
                color: grayLight,
              }}
            >
              {goal === "registration"
                ? t("orCreateAccountWithGoogle")
                : t("orConnectWithGoogle")}
            </Typography>
          </Box>
          <Box sx={{ borderTop: `1px solid ${grayLight}`, width: "10%" }} />
        </Box>
      </Box>
    );

  return (
    <Box>
      <Button
        onClick={signupGoogle}
        sx={{
          my: 2,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        variant={"outlinedGold" as never}
      >
        <Google
          sx={{ color: gold, marginRight: (theme) => theme.spacing(1) }}
        />
        <Box>
          {goal === "registration"
            ? t("googleRegistration")
            : t("googleConnexion")}
        </Box>
      </Button>

      <Box
        mt={4}
        mb={1}
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          order: 1,
        }}
      >
        <Box sx={{ borderTop: `1px solid ${grayLight}`, width: "45%" }} />
        <Box
          sx={{
            width: "10%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Typography
            sx={{
              textTransform: "uppercase",
              color: grayLight,
            }}
          >
            {t("or")}
          </Typography>
        </Box>
        <Box sx={{ borderTop: `1px solid ${grayLight}`, width: "45%" }} />
      </Box>
    </Box>
  );
};