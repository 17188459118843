import { db } from "./config";
import { getDoc, doc, collection, getDocs } from "@firebase/firestore";
import { collections, IUsers, usersTypes } from "type-absenso";
import { query, Timestamp, where, limit } from "firebase/firestore";
import { IUserFront } from "./type/frontDocument";

export const getUser = async ({
  uid,
}: {
    uid: string;
}) => {
  const userRef = doc(db, collections.users, uid);
  const snap = await getDoc(userRef);
  const data = snap.data() as IUsers<Timestamp> | undefined;
  
  if (data) {
    return {...data, id: snap.id};
  }

  return undefined;
};

export const searchUser = async(search: string) => {
  const arrayData: Array<IUserFront> = [];
  const userRef = collection(db, collections.users);
  const q = query(
    userRef,
    where("email", ">=", search),
    where("email", "<=", search+"\uf8ff"),
    limit(10)
  );

  const snaps = await getDocs(q);

  for await (const snap of snaps.docs) {
    const data = snap.data() as IUsers<Timestamp>;
    const acceptedType: Array<usersTypes> = ["funeral", "user"];
    if (data.type.some(t => acceptedType.includes(t))) {
      arrayData.push({...data, id: snap.id});
    }
  }
  return arrayData;
};

export const getUsers = async (users: Array<string>) => {
  const arrayUser: Array<IUserFront> = [];
  for await (const path of users) {
    const snap = await getDoc(doc(db, path));
    const data = snap.data() as IUsers<Timestamp> | undefined;

    if (data) {
      const acceptedType: Array<usersTypes> = ["funeral", "user"];
      if (data.type.some(t => acceptedType.includes(t))) {
        arrayUser.push({...data, id: snap.id});
      }
    }
  }

  return arrayUser;
};
