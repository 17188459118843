import React from "react";
import { offersData } from "type-absenso";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { Offers } from "../offers";

export const ChooseOffer = () => {
  const {t} = useTranslation();
  return(
    <Box sx={{
      display: "flex",
      width: "100%",
      justifyContent: "center",
      mb: 2,
    }}>
      <Box sx={{
        boxShadow: `0px 4px 12px ${grey[400]}`,
        borderRadius: theme => theme.spacing(1),
        width: {xs: "100%", md: "60%"},
        p: 2,
      }}>
        <Typography sx={{
          fontSize: theme => theme.spacing(3),
          fontWeight: 400,
          lineHeight: theme => theme.spacing(4),
          mb: 2,
        }}>
          {t("chooseHisOffer")}
        </Typography>
        <Box sx={{
          display: "flex",
          overflowX: "scroll"
        }}>
          {offersData.slice(0,3).map((el, i) => (
            <React.Fragment key={i}>
              <Offers 
                minWidth={true}
                uid={el.uid}
                version={el.version}
                price={el.price}
                items={el.items}
              />
            </React.Fragment>
          ))}
        </Box>
      </Box>
    </Box>
  );
};