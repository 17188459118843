import React from "react";

// Router
import { HashRouter } from "react-router-dom";

// Ui
import { CssBaseline, ThemeProvider } from "@mui/material";
import { customTheme } from "./ui/theme";

// Provider
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AlertProvider from "./provider/error/alertprovider";
import AuthProvider from "./provider/auth/authprovider";
import I18nProvider from "./provider/i18n/i18nProvider";
import { LoadScript } from "@react-google-maps/api";

// Translations
import frLocale from "date-fns/locale/fr";
import nlLocale from "date-fns/locale/nl";
import { useTranslation } from "react-i18next";

const App = () => {
  const {i18n} = useTranslation();
  const [locale] = React.useState(
    i18n.language === "nl" ?
      nlLocale : frLocale
  );

  return (
    <LoadScript 
      loadingElement={<></>}
      googleMapsApiKey={
      process.env.REACT_APP_API_KEY_GOOGLE as string
      }>
      <LocalizationProvider 
        adapterLocale={locale}
        dateAdapter={AdapterDateFns} >
        <ThemeProvider theme={customTheme}>
          <CssBaseline />
          <HashRouter>
            <I18nProvider>
              <AlertProvider>
                <AuthProvider />
              </AlertProvider>
            </I18nProvider>
          </HashRouter>
        </ThemeProvider>
      </LocalizationProvider>
    </LoadScript>
  );
};

export default App;
