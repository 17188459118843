import React from "react";

// Google
import { GoogleMap, Marker } from "@react-google-maps/api";
import { mapStyles } from "./mapstyles";

export const Gmap = ({
  geoLocation,
  style,
}: {
    geoLocation: {
        lat: number;
        lng: number;
    },
    style?: React.CSSProperties;
}) => {
  return(
    <>
      <GoogleMap
        options={{
          styles: mapStyles
        }}
        mapContainerStyle={style}
        center={{
          ...geoLocation,
          lng: geoLocation.lng + 0.005,
        }}
        zoom={15}
      >
        <Marker
          position={geoLocation}
          icon={require("../../../../../media/img/virtualTomb.svg")}
        />
      </GoogleMap>
    </>
  );
};