import React from "react";
import {
  Box,
  Typography
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FormComponent from "../../../forms/formcomponent";
import { useTranslation } from "react-i18next";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { grey } from "@mui/material/colors";

export interface ISelectKitParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formKit: UseFormReturn<FieldValues, any>;
  handleSubmitKit: (data:  {kitId: string}) => Promise<void>;
  loading: boolean;
}

export const SelectAKit = ({
  formKit,
  handleSubmitKit,
  loading,
}: ISelectKitParams) => {
  const {t} = useTranslation();
  return(
    <Box sx={{
      display: "flex",
      width: "100%",
      justifyContent: "center"
    }}>
      <Box sx={{
        boxShadow: `0px 4px 12px ${grey[300]}`,
        borderRadius: theme => theme.spacing(1),
        width: {xs: "100%", md: "60%"},
        p: 2,
      }}>
        <Typography sx={{
          fontSize: theme => theme.spacing(3),
          fontWeight: 400,
          lineHeight: theme => theme.spacing(4),
          mb: 2,
        }}>
          {t("selectYourKit")}
        </Typography>

        <Typography sx={{
          fontSize: theme => theme.spacing(2),
          lineHeight: theme => theme.spacing(3),
          mb : 2
        }}>
          {t("selectYourKitDescription")}
        </Typography>

        <Box sx={{mb: 2}}>
          <FormComponent 
            watch={formKit.watch}
            control={formKit.control}
            formState={formKit.formState}
            arrayForms={[{
              type: "TextField",
              elementTextField: {
                name: "kitId",
                id: "kitId",
                type: "text",
                label: "kitId",
                variant: "standard"
              }
            }]}
          />
        </Box>

        <LoadingButton 
          onClick={formKit.handleSubmit(handleSubmitKit as never)}
          loading={loading}
          variant="contained"
          sx={{
            width: "100%",
          }}>
          {t("verifyKitBtn")}
        </LoadingButton>
      </Box>
    </Box>
  );
};