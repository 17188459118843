import React, { ReactNode, useEffect } from "react";
import { useContext } from "react";
import { createContext } from "react";

// I18n
import { useTranslation } from "react-i18next";
import { getLang, TLangAvailable, STORAGE_LANG } from "../../i18n/i18n";
import { useSearchParams } from "react-router-dom";

const I18nContext = createContext<
  null | 
  {
    getLang: () => TLangAvailable;
    setLang: (nextLng: TLangAvailable) => void;
      }
      >(null);

export const useI18n = () => {
  const lngProvider = useContext(I18nContext);

  return lngProvider;
};

const I18nProvider = ({children}: {children: ReactNode}) => {
  const [searchParams] = useSearchParams();
  const lang = searchParams.get("lang") as TLangAvailable | null;
  const {i18n} = useTranslation();

  const setLang = (nextLng: TLangAvailable) => {
    localStorage.setItem(STORAGE_LANG, lang ? lang : nextLng);
    window.location.reload();
  };

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  useEffect(() => {
    getLang();
  }, [i18n]);

  return(
    <I18nContext.Provider value={{getLang, setLang}}>
      {children}
    </I18nContext.Provider>
  );
};

export default I18nProvider;
