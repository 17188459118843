import React from "react";
import { CardTestimony, IPropsCardTestimony } from "./cardTestimony";
import { Box, ButtonBase } from "@mui/material";
import { darkTurquoise, gold } from "../../../ui/color";
import { useState } from "react";
import { useEffect } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Fade from "react-reveal/Fade";

export const allTestimony: Array<IPropsCardTestimony> = [
  {
    username: "J. Lacroix",
    userImage: require("../../../media/img/jLacroix.png"),
    starsImage: require("../../../media/img/stars.png"),
    familyImage: require("../../../media/img/testimony1.jpg"),
    text: "textMemory"
  },
  {
    username: "A. Humbeeck",
    userImage: require("../../../media/img/humbeeck.png"),
    starsImage: require("../../../media/img/stars.png"),
    familyImage: require("../../../media/img/testimony2.jpg"),
    text: "textMemory1"
  },
  {
    username: "G. Defay",
    userImage: require("../../../media/img/defay.png"),
    starsImage: require("../../../media/img/stars.png"),
    familyImage: require("../../../media/img/testimony3.jpg"),
    text: "textMemory2"
  },
  {
    username: "M. Chérif",
    userImage: require("../../../media/img/cherif.png"),
    starsImage: require("../../../media/img/stars.png"),
    familyImage: require("../../../media/img/testimony4.jpg"),
    text: "textMemory3"
  }
];

export const Carousel = ({
  withoutPicture,
  withoutCarouselMarker
}: {
  withoutPicture?: boolean;
  withoutCarouselMarker?: boolean
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [lastChange, setLastChange] = useState<Date>();

  useEffect(() => {
    setTimeout(() => {
      if (lastChange === undefined || (
        lastChange !== undefined && Math.abs(
          new Date().getTime() - lastChange.getTime()
        ) > 2999
      )) {
        if (currentIndex+1 === allTestimony.length) {
          setCurrentIndex(0);
        } else {
          setCurrentIndex(currentIndex + 1); 
        }

        setLastChange(new Date());
      }
    }, 3000);
  }, [currentIndex]);


  const handleChangeIndex = (index: number) => {
    setCurrentIndex(index);
    setLastChange(new Date());
  };

  return(
    <Box sx={{
      display: "flex", 
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
    }}>
      {allTestimony[currentIndex] && <Fade>
        <CardTestimony 
          username={allTestimony[currentIndex].username}
          userImage={allTestimony[currentIndex].userImage}
          starsImage={allTestimony[currentIndex].starsImage}
          familyImage={allTestimony[currentIndex].familyImage}
          text={allTestimony[currentIndex].text}
          withoutPicture={withoutPicture}
        />
      </Fade>}
    
      {!withoutCarouselMarker && <Box 
        sx={{display: "flex", my: {md: 6, xs: 3}}}
      >
        {Array.from(Array(allTestimony.length).keys()).map((_, i) => (
          <ButtonBase 
            onClick={() => handleChangeIndex(i)}
            sx={{
              cursor: "pointer",
              height: theme => theme.spacing(1.5),
              width: theme => theme.spacing(1.5),
              opacity: i == currentIndex ? 1 : 0.6,
              borderRadius: "50%",
              backgroundColor: i == currentIndex ? gold : darkTurquoise,
              mx: 1
            }}
            key={i}
          />
        ))}
      </Box>}
    </Box>
  );
};