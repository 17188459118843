export const darkTurquoise = "#003848";
export const darkTurquoiseLight = "#074B5F";
export const darkTurquoiseRgb = "rgba(0, 22, 28, 0.05)";
export const backgroundOpacity = "#EFF5F7";
export const borderOpacity = "#E6E6E6";
export const grayBlue = "#DCDCDC";
export const gray = "#F7F9FC";
export const grayDark = "#6F6F6F";
export const grayLight = "#989898";
export const gold = "#A17846";
export const gold05 = "#DAB88B";
export const blueFrame = "#19718A";