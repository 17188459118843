import React, { useState } from "react";
import { Autocomplete, Box, Button, Typography } from "@mui/material";
import { IUserFront } from "../../../backend/type/frontDocument";
import { IAssetAutoCompleteField } from "../../forms/types";
import { useTranslation } from "react-i18next";
import { Search } from "@mui/icons-material";
import { grayDark, darkTurquoise } from "../../../ui/color";
import { searchUser } from "../../../backend/user";
import { UserPicture } from "./userPicture";

export const UserItem = ({
  user,
  actionHandler,
}: {
  user: IUserFront;
  actionHandler?: (user: IUserFront) => void;
}) => {
  const {t} = useTranslation();
  return(
    <>
      <Box sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        p: 1
      }}>
        <Box sx={{
          display: "flex",
          alignItems: "center"
        }}>
          <UserPicture 
            user={user}
            className="img-options"
            sx={{}}
            sxIcon={{
              color: darkTurquoise,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              fontSize: (theme: any) => theme.spacing(5)
            }}
          />

          <Box sx={{pl: 1}}>
            <Typography>
              {`${user.firstName} ${user.name}`}
            </Typography>
            <Typography variant={"subtitleSearch" as never}>
              {user.email}
            </Typography>
          </Box>
        </Box>

        {actionHandler && <Button
          onClick={() => actionHandler(user)}
          sx={{ maxHeight: theme => theme.spacing(5) }}
          variant={"outlinedGold" as never}
        >
          {t("choose")}
        </Button>}
      </Box>
    </>
  );
};

export const SearchUser = ({
  backgroundColor,
  paddingContainer,
  actionHandler,
}: {
  backgroundColor?: string;
  paddingContainer?: number;
  actionHandler?: (user: IUserFront) => void;
}) => {
  const [valueTextfield, setValueTextfield] = useState<string>();
  const [loading, setLoading] = useState<boolean>();
  const [options, setOptions] = useState<Array<IAssetAutoCompleteField<
    IUserFront
  >>>([]);

  const {t} = useTranslation();

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueTextfield(e.target.value);
    setLoading(true);
    if (e.target.value) {
      const user = await searchUser(e.target.value);
      const newOptions: Array<
        IAssetAutoCompleteField<IUserFront>
      > = user.map(el => {
        return {
          value: el,
          label: el.firstName,
          image: el.pictures
        };
      });
      setOptions(newOptions);
    }
    setLoading(false);
  };

  return(
    <>
      <label className="full_width">
        <Autocomplete
          loading={loading}
          disablePortal={true}
          freeSolo={true}
          sx={{
            display: "inline-block",
            width: "100%",
            "& input": {
              width: "100%",
              fontFamily: "Raleway",
              color: (theme) =>
                theme.palette.getContrastText(theme.palette.background.paper),
            },
          }}
          id="search-user"
          options={options}
          renderOption={(_, option) => {
            const newOpt = option as unknown as IAssetAutoCompleteField<
              IUserFront
            >;

            return <React.Fragment key={newOpt.value.id}>
              <UserItem 
                user={newOpt.value}
                actionHandler={actionHandler}
              />
            </React.Fragment>;
          }}
          componentsProps={{
            paper: {
              sx: {
                borderRadius: "1px",
                marginTop: "2px",
                backgroundColor: "#FFFFFF",
              }
            }
          }}
          renderInput={(params) => (
            <Box 
              ref={params.InputProps.ref}
              className="wrapper-search-user"
              sx={{
                backgroundColor: backgroundColor || "inherit",
                p: paddingContainer || 1,
              }}
            >
              <input
                placeholder={t("searchUser")}
                {...params.inputProps}
                className="input-search-deceased"
                style={{backgroundColor: backgroundColor || ""}}
                onChange={(e) => {
                  handleChange(e);
                }}
                value={valueTextfield || ""}
                type="text"
              />

              <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderLeft: `1px solid ${grayDark}`
              }}>
                <Search sx={{color: grayDark, ml: 1}} />  
              </Box>            
            </Box>
          )}
        />
      </label>
    </>
  );
};