import React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../../../provider/error/hooks/useAlert";
import {
  IPersonalInfoForm,
  ISignupForm,
  personalInfoForm
} from "../../../forms/asset/auth/signup";
import FormComponent from "../../../forms/formcomponent";
import { LayoutFlow } from "./layoutflow";

export const PersonalInfo = ({action, currentData}: {
    action: (data: IPersonalInfoForm) => void;
    currentData: ISignupForm;
}) => {
  const {t} = useTranslation();
  const alert = useAlert();
  const {control, watch, formState, handleSubmit, setValue} = useForm();

  const setDataInField = (
    field: string,
    fieldName: string,
  ) => {
    const formatCurrentData = currentData as never;
    if (formatCurrentData[field]) {
      setValue(fieldName, formatCurrentData[field]);
    }
  };

  useEffect(() => {
    if (currentData) {
      [
        {field: "firstName", fieldName: "firstName"},
        {field: "name", fieldName: "name"},
        {field: "phone", fieldName: "phone"}
      ].forEach(el => {
        setDataInField(el.field, el.fieldName);
      });
    }
  }, [currentData]);

  const actionHandler = (data: IPersonalInfoForm) => {
    if (
      data.agreeGeneral === true &&
      data.agreePrivacyPolicy === true
    ) {
      action(data);
    } else {
      alert && alert.createAlert && alert.createAlert({
        type: "error",
        message: t("errorCheckBoxSignup")
      });
    }
  };

  return(
    <>
      <LayoutFlow 
        titleBtn={t("nextStep")}
        titleStep={t("step3Signup")}
        subtitleStep={t("subtitleStepThree")}
        actionBtn={handleSubmit(actionHandler as never)}
        loadingBtn={false}
        content={
          <>
            <FormComponent 
              watch={watch}
              control={control}
              formState={formState}
              arrayForms={personalInfoForm}
            />
          </>
        }
      />
    </>
  );
};