import { collection, getDocs } from "firebase/firestore";
import { collections, IProduct } from "type-absenso";
import { db } from "../backend/config";

export const getProducts = async () => {
  const arrayProduct: Array<IProduct> = [];
  const snaps = await getDocs(collection(db, collections.product));

  for await (const snap of snaps.docs) {
    const data = snap.data() as IProduct;
    arrayProduct.push({...data, id: snap.id});
  }

  return arrayProduct;
};