import React from "react";
import { Box } from "@mui/material";
import { religionTypes } from "type-absenso";
import { IAssetAutoCompleteField, IForm } from "../../types";
import { Church, Mosque, Synagogue } from "@mui/icons-material";

interface ICreateDeceasedForm {
    onChangeAddress: (text: string) => void;
    optionsAddress: Array<IAssetAutoCompleteField<string>>;
    loadingAddress: boolean;
}

const religionForm: Array<IAssetAutoCompleteField<religionTypes>> = [
  {label: "jewish", value: "jewish"},
  {label: "muslim", value: "muslim"},
  {label: "catholic", value: "catholic"}
];

export const createDeceasedForm: (data: ICreateDeceasedForm) => Array<IForm> = (
  data: ICreateDeceasedForm
) => [
  {
    type: "TextField",
    elementTextField: {
      name: "firstName",
      id: "firstName",
      label: "firstName",
      type: "text",
      variant: "standard",
    }
  },
  {
    type: "TextField",
    elementTextField: {
      name: "name",
      id: "name",
      label: "name",
      type: "text",
      variant: "standard"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      name: "biography",
      id: "biography",
      label: "biography",
      type: "text",
      rows: 4,
      multiline: true,
      variant: "standard",
      required: false,
    }
  },
  {
    type: "DatePicker",
    elementDatePicker: {
      name: "dateOfBirth",
      label: "dateOfBirth",
      maxDate: new Date(),
    }
  },
  {
    type: "DatePicker",
    elementDatePicker: {
      name: "dateOfDeath",
      label: "dateOfDeath",
      maxDate: new Date(),
    }
  },
  {
    type: "Autocomplete",
    elementAutoComplete: {
      name: "address",
      id: "address",
      label: "address",
      variant: "standard",
      options: data.optionsAddress,
      onChangeTextField: (text: string) => data.onChangeAddress(text),
      freeSolo: true,
      loading: data.loadingAddress
    }
  },
  {
    type: "TextField",
    elementTextField: {
      name: "latitude",
      id: "latitude",
      label: "latitude",
      type: "number",
      variant: "standard",
      disabled: true,
    }
  },
  {
    type: "TextField",
    elementTextField: {
      name: "longitude",
      id: "longitude",
      label: "longitude",
      type: "number",
      variant: "standard",
      disabled: true,
    }
  },
  {
    type: "Autocomplete",
    elementAutoComplete: {
      options: religionForm,
      name: "religion",
      id: "religion",
      label: "religion",
      variant: "standard",
      required: false,
      addComponentInOption: (option) => {
        const religion = option.label as religionTypes;

        const IconReligion = () => {
          switch (religion) {
          case "catholic":
            return <Church />;
          case "jewish":
            return <Synagogue />;
          case "muslim":
            return <Mosque />;
          default:
            return <></>;
          }
        };

        return <Box mr={1}>
          <IconReligion />
        </Box>;
      }
    }
  }
];

export interface IUpdateProfileDeathForm {
  firstName: string;
  name: string;
  biography: string;
  dateOfBirth: Date;
  dateOfDeath: Date;
}

export const updateProfileDeceasedForm: Array<IForm> = createDeceasedForm({
  onChangeAddress: () => true,
  optionsAddress: [],
  loadingAddress: true,
} as ICreateDeceasedForm).filter(f => {
  return f.elementTextField && f.elementTextField.name === "firstName" ||
    f.elementTextField && f.elementTextField.name === "name" ||
    f.elementTextField && f.elementTextField.name === "biography" ||
    f.elementDatePicker && f.elementDatePicker.name === "dateOfBirth" ||
    f.elementDatePicker && f.elementDatePicker.name === "dateOfDeath";
}) as unknown as Array<IForm>;


export interface IUpdateGeoLocationDeathForm {
  address: IAssetAutoCompleteField<string>;
  hash: string;
  latitude: number; 
  longitude: number;
}

export const updateGeoLocationDeceasedForm: (
  data: ICreateDeceasedForm
  ) => Array<IForm> = (data) => {
    return createDeceasedForm(data).filter(f => {
      return (
        f.elementAutoComplete && f.elementAutoComplete.name === "address" ||
      f.elementTextField && f.elementTextField.name === "latitude" ||
    f.elementTextField && f.elementTextField.name === "longitude"
      );
    });
  };