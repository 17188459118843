import React from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getDeathWithLink } from "../../backend/death";
import { useAlert } from "../../provider/error/hooks/useAlert";
import { routing } from "../../router";

const PageCustomUrl = () => {
  const {text} = useParams<string>();
  const alert = useAlert();
  const navigate = useNavigate();

  useEffect(() => {
    if (text) {
      (async() => {
        try {
          const id = await getDeathWithLink(text);

          if (id) {
            navigate(routing.desceaded.index.replace(":id", id));
          }
        } catch (error) {
          if (alert) {
            alert.defaultError();
          }
        }
      })();
    }
  }, [text]);


  return(<></>);
};

export default PageCustomUrl;
