import { IForm } from "../../types";

export const resetPasswordForm: Array<IForm> = [
  {
    type: "TextField",
    elementTextField: {
      name: "email",
      id: "email",
      type: "email",
      label: "email",
      variant: "standard",
    }
  },
];