/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Autocomplete, Box, Typography, Button } from "@mui/material";
import { CheckCircleRounded, Search } from "@mui/icons-material";
import { darkTurquoise, gold, grayDark } from "../../../ui/color";
import { IAssetAutoCompleteField } from "../../forms/types";
import { Timestamp } from "firebase/firestore";
import { useState } from "react";
import { searchDeath } from "../../../backend/death";
import { IDeathFront } from "../../../backend/type/frontDocument";
import { useTranslation } from "react-i18next";
import { formatReligion } from "../format/formatReligion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { routing } from "../../../router";
import { UserPicture } from "../user/userPicture";

export const formatDateDeceased = (birth: Timestamp, death: Timestamp) => {
  if (birth) {
    // eslint-disable-next-line max-len
    return `${birth.toDate().toLocaleDateString("eu-FR")} - ${death.toDate().toLocaleDateString("eu-FR")}`;
  }};

export const DeceasedItem = ({
  death,
  actionHandler,
  checked,
}: {
  death: IDeathFront;
  checked?: boolean;
  actionHandler?: (
    id: string, value: IDeathFront
  ) => void;
}) => {
  const {t} = useTranslation();

  const religion = formatReligion(death.religion);

  return(
    <Box p={1} key={death.id} sx={{
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      border: checked ? `1px solid ${gold}` : "inherit",
      borderRadius: checked ? theme => theme.spacing(0.5) : "inherit"
    }}>
      <Box sx={{display: "flex", alignItems: "center"}}>
        <UserPicture 
          death={death}
          className="img-options"
          sx={{}}
          sxIcon={{
            color: darkTurquoise,
            fontSize: (theme: any) => theme.spacing(5)
          }}
        />
        <Box pl={1} sx={{
          display: "flex",
          flexDirection: "column"
        }}>
          <Typography>{death.fullname}</Typography>
          <Typography variant={"subtitleSearch" as never}>
            {formatDateDeceased(
              death.dateOfBirth, death.dateOfDeath
            )}
          </Typography>
          {religion && <Box sx={{
            display: "flex",
            alignItems: "center",
          }}>
            <FontAwesomeIcon
              icon={religion.icon}
              style={{color: grayDark, fontSize: "12px"}}
            />
            <Typography 
              variant={"subtitleSearch" as never}
              sx={{
                ml: 0.5
              }}>{t(religion.text)}</Typography>
          </Box>}
        </Box>
      </Box>

      {actionHandler && <Box>
        <Button 
          onClick={() => actionHandler(death.id, death)}
          sx={{
            maxHeight: theme => theme.spacing(5)
          }} variant={"outlinedGold" as never}>
          {t("choose")}
        </Button>
      </Box>}

      {checked && <CheckCircleRounded sx={{
        color: gold,
        fontSize: theme => theme.spacing(3)
      }} />}
    </Box>
  );
};

export const SearchDeceased = ({
  afterChoose,
  backgroundColor,
  paddingContainer,
  action,
  cleanAfterAction,
  searchAction,
}: {
  afterChoose?: () => void;
  backgroundColor?: string;
  paddingContainer?: number;
  action?: (value: IDeathFront) => void;
  cleanAfterAction?: boolean;
  searchAction?: (search: string) => Promise<Array<IDeathFront>>;
}) => {
  const [valueTextfield, setValueTextfield] = useState<string>();
  const [loading, setLoading] = useState<boolean>();
  const [options, setOptions] = useState<Array<IAssetAutoCompleteField<
    IDeathFront
  >>>([]);
  const navigate = useNavigate();

  const {t} = useTranslation();

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueTextfield(e.target.value);
    setLoading(true);
    if (e.target.value) {
      const death = searchAction ? 
        await searchAction(e.target.value) : await searchDeath(e.target.value);
      const newOptions: Array<
        IAssetAutoCompleteField<IDeathFront>
        > = death.map(el => {
          return {
            value: el,
            label: el.firstName,
            image: el.profilePicture
          };
        });
      setOptions(newOptions);
    }
    setLoading(false);
  };

  const actionHandler = (id: string, value: IDeathFront) => {
    if (!action) {
      navigate(routing.desceaded.index.replace(":id", id));
      afterChoose && afterChoose();
    } else {
      action(value);
    }

    if (cleanAfterAction) {
      setOptions([]);
    }
  };

  return(
    <>
      <label className="full_width">
        <Autocomplete
          loading={loading}
          disablePortal={true}
          freeSolo={true}
          sx={{
            display: "inline-block",
            width: "100%",
            "& input": {
              width: "100%",
              fontFamily: "Raleway",
              color: (theme) =>
                theme.palette.getContrastText(theme.palette.background.paper),
            },
          }}
          id="search-deceased"
          options={options}
          renderOption={(_, option) => {
            const newOpt = option as unknown as IAssetAutoCompleteField<
              IDeathFront
            >;

            return <React.Fragment key={newOpt.value.id}>
              <DeceasedItem 
                death={newOpt.value}
                actionHandler={actionHandler}
              />
            </React.Fragment>;
          }}
          componentsProps={{
            paper: {
              sx: {
                borderRadius: "1px",
                marginTop: "2px",
                backgroundColor: "#FFFFFF",
              }
            }
          }}
          renderInput={(params) => (
            <Box 
              ref={params.InputProps.ref}
              className="wrapper-search-deceased"
              sx={{
                backgroundColor: backgroundColor || "",
                p: paddingContainer || 1,
              }}
            >
              <input
                placeholder={t("searchDeceased")}
                {...params.inputProps}
                className="input-search-deceased"
                style={{backgroundColor: backgroundColor || ""}}
                onChange={(e) => {
                  handleChange(e);
                }}
                value={valueTextfield || ""}
                type="text"
              />

              <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderLeft: `1px solid ${grayDark}`
              }}>
                <Search sx={{color: grayDark, ml: 1}} />  
              </Box>            
            </Box>
          )}
        />
      </label>
    </>
  );
};