import React, { useEffect } from "react";
import { usePage } from "../../provider/deceased/hooks/usePage";
import { Profile } from "../../components/utils/deceased/page/profile";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from "@mui/material";
import { Stats } from "../../components/utils/deceased/page/stats";
import { Map } from "../../components/utils/deceased/page/map";
import { FuneralCompanyCard }
  from "../../components/utils/deceased/page/funeralCompanyCard";
import { CreatorMemories }
  from "../../components/utils/deceased/page/creatorMemories";
import { SortCard } from "../../components/utils/deceased/page/sortCard";
import { useTranslation } from "react-i18next";
import { TimelinePost }
  from "../../components/utils/deceased/page/timelinePost";
import { usePosts } from "../../components/utils/deceased/page/hooks/usePosts";
import { useMemo } from "react";
import { Dialog } from "@mui/material";
import { darkTurquoise, gold } from "../../ui/color";
import { LoadingButton } from "@mui/lab";
import { useOffer } from "../order/offers/hooks/useOffer";
import { offersData } from "type-absenso";
import { useState } from "react";
import { useAskAuth } from "../../provider/auth/hooks/useAskAuth";
import { useNavigate } from "react-router-dom";

const ProfilDeceased = () => {
  const page = usePage();
  const {t} = useTranslation();
  const { subscribeToOffer } = useOffer(offersData[1].uid);
  const navigate = useNavigate();

  const {
    posts,
    loading,
    sortValue,
    hasMore,
    getData,
    addPost,
    changeSortValue,
  } = usePosts(page && page.deathData && page.deathData.id);

  const [loadingSubscribe, setLoadingSubscribe] = useState<boolean>();
  const askAuth = useAskAuth();

  useEffect(() => {
    if (!posts && sortValue) {
      (async() => {
        await getData();
      })();
    }
  }, [posts, sortValue]);

  const mustBlurred = useMemo(() => {
    const diffInDay = (date1: Date, date2: Date) => {
      const oneDay = 24 * 60 * 60 * 1000;
      const differenceMs = Math.abs(date1.valueOf() - date2.valueOf());
      const diff = Math.round(differenceMs / oneDay);
      return diff;
    };

    if (page && page.deathData) {
      if (
        page.deathData.type !== "paid" &&
        diffInDay(new Date(), page.deathData.createAt.toDate()) > 14
      ) {
        return true;
      }

      return false;
    }
  }, [page && page.deathData]);

  const upgradeOfferHandler = async () => {
    setLoadingSubscribe(true);
    if (askAuth.userIsConnected() && page && page.deathData) {
      await subscribeToOffer({
        idDeathAlreadyExist: page.deathData.id
      });
    } else {
      askAuth.openHandler();
    }
    setLoadingSubscribe(false);
  };

  const returnPreviousPage = () => {
    navigate(-1);
  };

  if (page && page.deathData) {
    return(
      <Box my={{md: 2, xs: 0}} sx={{
        filter: mustBlurred ? "blur(8px)" : "inherit"
      }}>

        {
          mustBlurred && <Dialog
            open={true}
            fullWidth={true}
          >
            <DialogTitle>
              <Typography sx={{
                color: darkTurquoise
              }}>
                {t("titleDelayDepassed")}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Box sx={{
                my: 3
              }}>
                <Profile
                  modalView={true}
                  death={page.deathData}
                  isAdmin={false}
                  bouquetAction={page.changeBouquetOwner}
                />
              </Box>
            </DialogContent>
            <DialogActions sx={{
              justifyContent: "space-between"
            }}>
              <Button
                variant="outlined"
                onClick={returnPreviousPage}
                sx={{
                  height: "auto",
                  m: 1,
                  alignSelf: "left"
                }}
              >
                {t("previous")}
              </Button>
              <LoadingButton
                variant="contained"
                loading={loadingSubscribe}
                onClick={upgradeOfferHandler}
                sx={{
                  backgroundColor: gold,
                  height: "auto",
                  m: 1
                }}
              >
                {t("subscribeToOffer")}
              </LoadingButton>
            </DialogActions>
          </Dialog>
        }

        <Profile
          death={page.deathData}
          isAdmin={page.isAdmin || false}
          bouquetAction={page.changeBouquetOwner}
        />

        <Grid container spacing={{xs: 0, md: 2}} sx={{mt: {md: 1, xs: 0}}}>
          <Grid item md={4} xs={12}>
            <Stats death={page.deathData} />

            <Box my={{xs: 0, md: 2}}>
              <FuneralCompanyCard death={page.deathData} />
            </Box>

            <Box my={{xs: 2, md: 0}}>
              <SortCard sortValueHandler={changeSortValue} />
            </Box>
          </Grid>
          <Grid item md={8} xs={12}>
            <Map
              canModify={page.deathData.type === "paid" && page.isAdmin}
              death={page.deathData}
              actionBouquet={page.changeBouquetOwner}
            />
            <Box my={2}>
              <CreatorMemories addPost={addPost} death={page.deathData} />
            </Box>
          </Grid>
        </Grid>

        <Box mt={2}>
          <Typography sx={{
            textAlign: "center",
            fontWeight: 500,
            fontSize: theme => theme.spacing(4),
            lineHeight: theme => theme.spacing(6),
            mb: 1
          }}>
            {t("sharedMemories")}
          </Typography>
          <Typography sx={{
            textAlign: "center",
            px: {xs: 2, md: 0}
          }}>
            {t("subtitleMemorialTimeline")}
          </Typography>

          <TimelinePost 
            death={page.deathData}
            posts={posts}
            getData={getData}
            hasMore={hasMore}
            loading={loading}
          />
        </Box>
      </Box>
    );
  }

  return(
    <Box sx={{
      width: "100%",
      height: "90vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
      <CircularProgress />
    </Box>
  );
};

export default ProfilDeceased;