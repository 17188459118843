import { IForm } from "../../types";
import { ReactNode } from "react";

export const signinForm:(
  type: string,
  icon: ReactNode
) => IForm[] = (type, icon) => [
  {
    type: "TextField",
    elementTextField: {
      name: "email",
      id: "email",
      type: "email",
      label: "email",
      variant: "standard",
    }
  },
  {
    type: "TextField",
    elementTextField: {
      name: "password",
      id: "password",
      type,
      label: "password",
      variant: "standard",
      InputProps: {
        endAdornment: (icon),
      }
    }
  }
];