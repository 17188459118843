import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import React, { ReactNode } from "react";
import { useState } from "react";
import { darkTurquoise, gold } from "../../../../../ui/color";
import { EditBtn } from "../../editBtn";

export const EditDeceased = ({
  displayed,
  title,
  content,
  actionTitle,
  actionLoading,
  actionHandler,
}: {
    displayed: boolean;
    title: string;
    content: ReactNode;
    actionTitle: string;
    actionLoading: boolean;
    actionHandler: () => void;
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const clickHandler = async () => {
    await actionHandler();
    setOpen(!open);
  };

  return<>
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      fullWidth
    >
      <DialogTitle>
        <Typography sx={{
          color: darkTurquoise
        }}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={actionLoading}
          onClick={clickHandler}
          variant="contained"
          sx={{
            backgroundColor: gold,
            height: "auto"
          }}
        >
          {actionTitle}
        </LoadingButton>
      </DialogActions>

    </Dialog>
    <EditBtn isAdmin={displayed} onClick={() => setOpen(!open)} />
  </>;
};