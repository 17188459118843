import React, { useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { darkTurquoise, darkTurquoiseLight } from "../../ui/color";
import { MyContainer } from "../../components/utils/myContainer";
import { getProducts } from "../../backend/product";
import { useState } from "react";
import { IProduct } from "type-absenso";

const Shop = () => {
  const {t} = useTranslation();

  const [products, setProducts] = useState<Array<IProduct>>();

  useEffect(() => {
    (async() => {
      setProducts(await getProducts());
    })();
  }, []);

  return(
    <Box>
      <Box sx={{
        backgroundImage: `linear-gradient(
          315.64deg, rgba(0, 0, 0, 0.7) 24.73%, rgba(0, 0, 0, 0) 67.05%),
        url(${require("../../media/img/img-box1.png")})`,
        backgroundPositionX: "center",
        backgroundPositionY: "center",
        backgroundSize: "cover",
        minHeight: "300px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
      }} >
        <Typography 
          sx={{
            color: "white",
            fontWeight: "600",
            fontSize: theme => theme.spacing(14)
          }}
          variant={"title" as never}
        >
          {t("shopTitle")}
        </Typography>

        <Box sx={{
          display: "flex",
          pt: 6
        }}>
          {[t("candle"), t("packages")].map((el, i) => (
            <Box key={i} px={2} py={1} sx={{
              backgroundColor: darkTurquoise,
              borderRadius: theme => theme.spacing(2.5),
              ml: i === 1 ? 2 : 0,
            }}>
              <Typography sx={{color: "white"}}>
                {el}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <Box sx={{
        backgroundColor: darkTurquoiseLight,
        height: "100vh"
      }}>
        <MyContainer>
          <Grid container spacing={2} sx={{
            display: "flex",
            alignItems: "stretch"
          }}>
            <Grid item md={4}>
              <Box sx={{
                backgroundColor: darkTurquoise,
                borderRadius: theme => theme.spacing(1),
                p: 2
              }}>
                <Typography sx={{
                  fontSize: theme => theme.spacing(5),
                  fontWeight: 600,
                  color: "white",
                  pb: 2
                }}>
                  {t("candles")}
                </Typography>
                <Typography sx={{color: "white"}}>
                  {/* eslint-disable-next-line max-len */}
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi, alias? Tenetur consectetur enim quasi error rerum quibusdam, asperiores non incidunt commodi dolore eius magnam ullam maiores quod. Eligendi, ducimus ad! Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi, alias? Tenetur consectetur enim quasi error rerum quibusdam, asperiores non incidunt commodi dolore eius magnam ullam maiores quod. Eligendi, ducimus ad!
                </Typography>
              </Box>
            </Grid>
            <Grid item md={8}>
              <Box sx={{overflowX: "scroll"}}>
                {products && products.map((el, i) => (
                  <Box 
                    key={i}
                    sx={{
                      height: "250px"
                    }}
                  >
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </MyContainer>
      </Box>
    </Box>
  );
};

export default Shop;
