import React, { useEffect, useState } from "react";

// Router
import { useParams } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { app } from "../../backend/config";
import { getPost } from "../../backend/post";
import { IPostFront } from "../../backend/type/frontDocument";
import { useAlert } from "../../provider/error/hooks/useAlert";
import { Box } from "@mui/material";
import { Memorie } from "../../components/utils/deceased/page/post/memorie";

const Post = () => {
  const {id} = useParams<string>();
  const alert = useAlert();

  const [post, setPost] = useState<
    IPostFront
  >();

  useEffect(() => {
    (async() => {
      const user = getAuth(app).currentUser;

      if (id) {
        try {
          const data = await getPost(id, (user && user.uid) || null);

          if (data) {
            setPost(data);
          }
        } catch (error) {
          if (alert) {
            alert.defaultError();
          }
        }
      }
    })();
  }, [id]);

  if (post) {
    return(
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        py: 4,
      }}>
        <Memorie post={post} openComment={true} />
      </Box>
    );
  }

  return <></>;
};

export default Post;
