import { DocumentReference, Timestamp } from "firebase/firestore";
import { t } from "i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  IDataFrontCreateOffers,
  IDataReceivePaymentOrder,
  offersData,
  router
} from "type-absenso";
import { postAuth } from "../../../../backend/utils/postAuth";
import { subscribeToOfferUrl } from "../../../../backend/utils/url";
import { useCreateDeceased } from "./useCreateDeceased";
import { IDataFormDeceased }
  from "../../../../components/utils/deceased/formDeceased";

export interface IParamsSubscribeOffer extends Omit<
  IDataFrontCreateOffers<DocumentReference, Timestamp
>, "offers"> {
  deathForm?: IDataFormDeceased;
}

export const useOffer = (uid?: string) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const offerUid = uid || params.get("offer");
  const offer = offersData.find(od => od.uid === offerUid);
  const { actionHandler } = useCreateDeceased({});

  const subscribeToOffer = async (data: IParamsSubscribeOffer) => {
    if (offer && offer.price > 0) {
      const dataToSend: IDataFrontCreateOffers<DocumentReference, Timestamp> = {
        offers: [
          {uid: offer.uid, name: t(offer.version)}
        ],
        death: data.death,
        deliveryData: data.deliveryData,
        funeral: data.funeral,
        idDeathAlreadyExist: data.idDeathAlreadyExist,
      };
      const response = await postAuth(subscribeToOfferUrl, dataToSend);
      if (response && response.data) {
        const data = response.data as IDataReceivePaymentOrder;
        const openWindow = window.open(data.url, "_blank");
        if (openWindow) {
          openWindow.focus();
        }
      }
    } else if (offer && offer.price === 0 && data.deathForm) {
      await actionHandler(data.deathForm);
    } else {
      navigate(router.offer.funnelOffer0);
    }
  };

  return {subscribeToOffer, offerUid};
};