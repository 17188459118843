import {
  Favorite,
  FormatQuote,
  Insights,
  LocalFireDepartment,
  LocalFlorist,
} from "@mui/icons-material";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { IDeathFront } from "../../../../backend/type/frontDocument";
import { darkTurquoise, gold } from "../../../../ui/color";
import { TemplateCard } from "./utils/templateCard";

export const Stats = ({death}: {death: IDeathFront}) => {
  const {t} = useTranslation();
  return(
    <>
      <TemplateCard
        title={t("statistics")}
        icon={<Insights sx={{color: gold}} />}
      >
        <List>
          {[
            {
              icon: <LocalFireDepartment />,
              text: t("productScanned", {
                nbScanned: death.numberOfMemories
              })
            },
            {
              icon: <Favorite />,
              text: t("thoughtSend", {
                nbThoughtSend: death.numberOfThoughtsSend,
                deathname: death.fullname,
              })
            },
            {
              icon: <LocalFlorist />,
              text: t("flowerBouquetSend", {
                nbFlowerBouquet: death.numberOfBouquetsLaid,
                deathname: death.fullname
              })
            },
            {
              icon: <FormatQuote />,
              text: t("memoriesSend", {
                nbMemories: death.numberOfTestimoniesLeft,
              })
            }
          ].map((el, i) => (
            <ListItem key={i} sx={{p: 0, my: 1}}>
              <ListItemIcon sx={{
                minWidth: "auto",
                color: darkTurquoise,
                mr: 2,
              }}>
                {el.icon}
              </ListItemIcon>
              <ListItemText sx={{color: darkTurquoise}}>{el.text}</ListItemText>
            </ListItem>
          ))}
        </List>
      </TemplateCard>
    </>
  );
};