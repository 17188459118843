import { Box } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { offersData } from "type-absenso";
import { FormDeceased } from "../../../components/utils/deceased/formDeceased";
import { BtnOfferContent, TemplateOffer }
  from "../../../components/utils/order/offer/templateoffer";
import { useCreateDeceased } from "./hooks/useCreateDeceased";

const FunnelOffer0 = () => {
  const {t} = useTranslation();
  const form = useForm();
  const { actionHandler, loadingAction } = useCreateDeceased({});

  return(
    <TemplateOffer
      offer={offersData[0]}
      titleAction={t("titleCreateDeceased")}
      subtitleAction={t("subtitleCreateDeceased")}
      offerExist={true}
      loading={false}
      loadingAction={loadingAction}
      action={form.handleSubmit(actionHandler as never)}
      titleBtn={t("btnSummaryOffer")}
      disableBtn={true}
      hideBtn={true}
    >
      <Box sx={{
        backgroundColor: "white",
        p: {md: 4, xs: 2},
        borderRadius: 1
      }}>
        <FormDeceased 
          form={form}
        />

        <Box sx={{mt: 4}}>
          <BtnOfferContent 
            action={form.handleSubmit(actionHandler as never)}
            titleBtn={t("btnSummaryOffer")}
            loading={loadingAction}
          />
        </Box>
      </Box>
    </TemplateOffer>
  );
};

export default FunnelOffer0;
