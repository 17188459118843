import { IForm } from "../../types";

export const myAccountParameterFrom: Array<IForm> = [
  {
    type: "TextField",
    elementTextField: {
      name: "email",
      id: "email",
      type: "email",
      label: "email",
      variant: "standard",
      disabled: true,
    }
  },
  {
    type: "TextField",
    elementTextField: {
      name: "firstName",
      id: "firstName",
      type: "text",
      label: "firstName",
      variant: "standard",
      disabled: true,
    }
  },
  {
    type: "TextField",
    elementTextField: {
      name: "name",
      id: "name",
      type: "text",
      label: "name",
      variant: "standard",
      disabled: true,
    }
  },
];