import React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  IFuneralCompanyFront
} from "../../../../../../backend/type/frontDocument";
import {
  DeliveryMethod,
  IDeliveryParams,
}from "../../../../order/offer/deliverymethod";
import { FlowContentTemplate } from "../flowContentTemplate";

interface IFlowDeliveryParams extends IDeliveryParams{
    nextAction: () => void;
    previousAction: () => void;
    steps: number;
    funeralCompany: IFuneralCompanyFront | undefined;
    loading: boolean;
}

export const FlowSelectDelivery = ({
  setDisplayedDeliveryForm,
  form,
  onSelectFuneralCompany,
  finalAction,
  loadingAction,
  disableBtn,
  getFromOutsideFormChoose,
  nextAction,
  currentReceptionFromOutside,
  displayedFormFromExternal,
  previousAction,
  steps,
  funeralCompany,
  loading,
}: IFlowDeliveryParams) => {
  const {t} = useTranslation();
  
  const disableNextBtn = useMemo(() => {
    if (steps === 3.2) {
      if (currentReceptionFromOutside === 1) {
        const exist = Object.keys(form.watch()).filter((f) => {
          return form.watch()[f] !== undefined;
        }).length;
  
        if (form.watch().sameDeliveryAndBilling) {
          return exist !== Object.keys(form.watch()).length -  4;
        } else {
          return exist !== Object.keys(form.watch()).length;
        }
      } else if (currentReceptionFromOutside === 0) {
        return funeralCompany === undefined;
      }
    }
  }, [steps, form.watch(), currentReceptionFromOutside]);

  return(
    <FlowContentTemplate
      nextAction={nextAction}
      title={t("fifthStep")}
      subtitle={t("whereDeliveryTitle")}
      displayedBtn
      previousAction={previousAction}
      disableNextBtn={disableNextBtn}
      loadingNext={loading}
    >
      <DeliveryMethod 
        onSelectFuneralCompany={onSelectFuneralCompany}
        form={form}
        loadingAction={loadingAction}
        finalAction={finalAction}
        disableBtn={disableBtn}
        setDisplayedDeliveryForm={setDisplayedDeliveryForm}
        modalView
        getFromOutsideFormChoose={getFromOutsideFormChoose}
        currentReceptionFromOutside={currentReceptionFromOutside}
        displayedFormFromExternal={displayedFormFromExternal}
      />
    </FlowContentTemplate>
  );
};