const startParameter = "/user/parameter";

export const routing = {
  auth: {
    signin: "/auth/signin",
    signup: "/auth/signup",
    forgotPassword: "/auth/reset/password",
  },
  desceaded: {
    index: "/memorial/page/:id",
    post: "/memorial/post/:id",
    page: "/page/:text"
  },
  scan: {
    kit: "/scan/kit/:id",
    product: "/scan/product/:id",
  },
  order: {
    index: "/order/products",
    linkProduct: {
      funnel: "/link/product/funnel",
    }
  },
  user: {
    index: "/user/:id",
    indexPage: "",
    parameter: {
      index: "parameter",
      adminProfil: "administered/profiles",
      addAdmin: "add/admin",
      modifyLinkProfil: "modify/link/profiles",
      myAccount: "my/account"
    },
    parameterHome: `${startParameter}/home`,
    parameterAmdin: `${startParameter}/admin`,
    parameterAdminId: `${startParameter}/admin/:id`
  },
  shop: {
    index: "/shop"
  },
  website: {
    index: "/",
    createMemorial: "/create/memorial",
    generalConditionOfUse: "/generalconditionuse",
    privacyPolicy: "/privacypolicy",
    qrcode: "/qrcode/:uid"
  }
};

export const createFormatSubRoute = (url1: string, url2: string) => {
  return (`${url1}/`).concat(url2);
};
