/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

// Ui
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";

// Form
import { Controller } from "react-hook-form";
import { IAssetDatePicker } from "./types/";

// Hooks
import { useTranslation } from "react-i18next";
import { gold } from "../../ui/color";

const DatePickerReactHooksForm = ({
  element, control, error
}: IAssetDatePicker) => {
  const {t} = useTranslation();

  const sxDefault = {
    svg: {color: gold},
    input: {
      color: gold,
      borderBottom: "1px solid #ffffff"
    },
    label: {color: gold},
    "& .MuiFilledInput-root": {
      backgroundColor: element.variant === "filled" ? 
        "white" : "inherit",
    },
    "& label.Mui-focused": {
      color: gold,
    },
    "& .MuiInput-root:before": {
      borderBottomColor: gold,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: gold,
    },
    "&:hover .MuiInput-root:before": {
      borderBottomColor: gold,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: gold,
      },
      "&:hover fieldset": {
        borderColor: gold,
      },
      "&.Mui-focused fieldset": {
        borderColor: gold,
      },
    }
  };

  const goodSx = !element.desactivateColor ? sxDefault : {};

  return(
    <Controller
      name={element.name}
      control={control}
      rules={{required: element.required !== undefined ? 
        element.required : true}}
      render={({ field : {onChange, value } }) => (
        <>
          <DesktopDatePicker
            minDate={element.minDate}
            maxDate={element.maxDate}
            label={t(element.label)}
            disabled={element.disabled}
            inputFormat="dd/MM/yyyy"
            value={value ? value : null}
            onChange={onChange}
            renderInput={(params: any) => 
              <TextField
                id={element.name}
                {...params}
                variant={element.variant || "standard"}
                sx={{
                  width: "100%",
                  ...goodSx
                }}
                error={error}
              />
            }
          />
        </>
      )}
    />
  );
};

export default DatePickerReactHooksForm;

