import React from "react";
import { TemplateTitle } from "./deliverymethod";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DeceasedItem, SearchDeceased } from "../../deceased/searchDeceased";
import { backgroundOpacity } from "../../../../ui/color";
import { useState } from "react";
import { IDeathFront } from "../../../../backend/type/frontDocument";
import { useEffect } from "react";

export const SelectDeceased = ({
  onSelect
}: {
  onSelect: (death: IDeathFront) => void;
}) => {
  const {t} = useTranslation();

  const [death, setDeath] = useState<IDeathFront>();

  useEffect(() => {
    if (death) {
      onSelect(death);
    }
  }, [death]);

  return(
    <Box>
      <TemplateTitle
        step={t("firstStep")}
        title={t("titleWichDeceased")}
      />

      {death && <Box mb={2}>
        <DeceasedItem death={death} checked={true} />
      </Box>}

      <SearchDeceased
        backgroundColor={backgroundOpacity}
        paddingContainer={2}
        action={(value) => setDeath(value)}
        cleanAfterAction={true}
      />
    </Box>
  );
};