import {
  doc,
  collection,
  DocumentReference,
  Timestamp,
  addDoc,
  query,
  orderBy,
  getDocs,
  getDoc,
  limit
} from "firebase/firestore";
import { db } from "./config";
import { collections, IBouquets } from "type-absenso";
import { IBouquetFront } from "./type/frontDocument";

export const addBouquet = async (
  idDeath: string, idUser: string
) => {
  const deathRef = doc(db, collections.death, idDeath);
  const bouquetRef = collection(deathRef, collections.bouquets);

  const data: IBouquets<DocumentReference, Timestamp> = {
    userGiving: doc(db, collections.users, idUser),
    date: Timestamp.now()
  };

  await addDoc(bouquetRef, data);

  return data;
};

export const getLastBouquet: (idDeath: string) => Promise<
  IBouquetFront | undefined
> = async(idDeath: string) => {
  const deathRef = doc(db, collections.death, idDeath);
  const bouquetRef = collection(deathRef, collections.bouquets);
  const q = query(
    bouquetRef,
    orderBy("date", "desc"),
    limit(1)
  );
  const snaps = await getDocs(q);
  const lastSnap = snaps.docs.length > 0 && snaps.docs[snaps.docs.length-1];

  if (lastSnap) {
    const data = lastSnap.data() as IBouquets<DocumentReference, Timestamp>;
    const userSnap = await getDoc(doc(db, data.userGiving.path));

    return {user: {
      ...userSnap.data(),
      id: userSnap.id
    },
    date: data.date.toDate(),
    } as IBouquetFront;
  }

  return undefined;

};