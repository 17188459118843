import { db } from "../backend/config";
import { collections, IComments, IUsers } from "type-absenso";
import {
  addDoc,
  collection,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  orderBy,
  query,
  Timestamp
} from "firebase/firestore";
import { ICommentsFront } from "./type/frontDocument";

export const addComments = async (
  id: string,
  data: IComments<DocumentReference, Timestamp>,
) => {
  const postRef = doc(db, collections.post, id);
  const commentRef = collection(postRef, collections.comments);

  return await addDoc(commentRef, data);
};

export const getComments = async (
  id: string
) => {
  const postRef = doc(db, collections.post, id);
  const commentRef = collection(postRef, collections.comments);

  const q = query(
    commentRef,
    orderBy("date", "desc")
  );

  const snaps = await getDocs(q);
   
  const emptyArray: Array<ICommentsFront> = [];
  if (snaps.docs && snaps.docs.length > 0) {
    for await (const snap of snaps.docs) {
      const data = snap.data() as IComments<DocumentReference, Timestamp>;
      const docRef = doc(db, data.user.path);
      const user = await getDoc(docRef);
      const dataUser = user.data() as IUsers<Timestamp> | undefined;

      if (dataUser) {
        emptyArray.push({
          ...data,
          id: snap.id,
          user: {...dataUser, id: user.id}
        });
      }
    }
  }

  return emptyArray;

};