import React from "react";
import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import { FormDeceased } from "../../deceased/formDeceased";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { IDataFormDeceased } from "../../deceased/formDeceased";

export const CreateDeceasedFunnelProduct = (
  {
    form,
    handleDeceased,
    loading,
  }: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        form: UseFormReturn<FieldValues, any>;
        handleDeceased: (data: IDataFormDeceased) => void;
        loading: boolean;
    }
) => {
  const {t} = useTranslation();
  return(
    <Box sx={{
      display: "flex",
      width: "100%",
      justifyContent: "center",
      mb: 2
    }}>
      <Box sx={{
        boxShadow: `0px 4px 12px ${grey[300]}`,
        borderRadius: theme => theme.spacing(1),
        width: {xs: "100%", md: "60%"},
        p: 2,
      }}>
        <Box sx={{py: 2}}>
          <FormDeceased form={form} />
        </Box>

        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={form.handleSubmit(handleDeceased as never)}
          sx={{
            width: "100%",
          }}
        >
          {t("btnSummaryOffer")}
        </LoadingButton>
      </Box>
    </Box>
  );
};