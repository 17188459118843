import React from "react";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { offersData } from "type-absenso";
import {
  FormDeceased,
  IDataFormDeceased
} from "../../../components/utils/deceased/formDeceased";
import { TemplateOffer }
  from "../../../components/utils/order/offer/templateoffer";
import { useCheckIfPaymentHasBeenMadeByUser } from "../hooks/useCheckpayment";
import { useCreateDeceased } from "./hooks/useCreateDeceased";
import { useState } from "react";
import { DeliveryMethod, TChooseForm }
  from "../../../components/utils/order/offer/deliverymethod";
import { IFuneralCompanyFront } from "../../../backend/type/frontDocument";
import { ICheckoutFormData }
  from "../../../components/forms/asset/order/checkout";
import { useAlert } from "../../../provider/error/hooks/useAlert";
import { BtnOfferContent }
  from "../../../components/utils/order/offer/templateoffer";
import { useEffect } from "react";
import { useOffer } from "./hooks/useOffer";

const FunnelOffer2 = () => {
  const form = useForm();
  const formAddress = useForm();
  const {t} = useTranslation();
  const alert = useAlert();
  const { subscribeToOffer } = useOffer(offersData[2].uid);
  const {offer, loading, sessionId} = useCheckIfPaymentHasBeenMadeByUser();
  const { transformInDataFrontDeath } = useCreateDeceased({
    offer, sessionId: sessionId || undefined
  });

  const [loadingAction, setLoadingAction] = useState<boolean>(false);
  const [dataForm, setDataForm] = useState<IDataFormDeceased>();
  const [displayedDeliveryForm, setDisplayedDeliveryForm] = useState<
    TChooseForm
  >();
  const [funeralCompany, setFuneralCompany] = useState<IFuneralCompanyFront>();
  const [step, setStep] = useState<0 | 1>(0);

  const deceasedFormHandler = (data: IDataFormDeceased) => {
    setDataForm(data);
    setStep(1);
  };

  const addressDeliveryHandler = async (data: ICheckoutFormData) => {
    if (dataForm) {
      const death = transformInDataFrontDeath(dataForm);
      await subscribeToOffer({
        death,
        deliveryData: data
      });
    }
  };

  const funeralDeliveryHandler = async () => {
    if (dataForm && funeralCompany) {
      const death = transformInDataFrontDeath(dataForm);
      await subscribeToOffer({
        death,
        funeral: funeralCompany.id
      });
    }
  };

  const disableBtnHandler = () => {
    if (funeralCompany) {
      return false;
    } else if (displayedDeliveryForm === "address") {
      return false;
    }

    return true;
  };

  const submit = async() => {
    setLoadingAction(true);
    try {
      if (displayedDeliveryForm && displayedDeliveryForm === "address") {
        await formAddress.handleSubmit(addressDeliveryHandler as never)();
      } else if (displayedDeliveryForm && displayedDeliveryForm === "funeral") {
        await funeralDeliveryHandler();
      }
    } catch (error) {
      alert.defaultError();
    }
    setLoadingAction(false);
  };

  useEffect(() => {
    const errors = Object.keys(form.formState.errors);

    if (errors.length > 0) {
      alert.defaultError();
    }
  }, [form.formState]);

  useEffect(() => {
    window.scrollTo(document.body.scrollHeight, 0);
  }, [step]);

  const currentDataItem = [
    {
      component: <Box>
        <FormDeceased form={form} />

        <Box sx={{mt: 4}}>
          <BtnOfferContent 
            action={form.handleSubmit(deceasedFormHandler as never)}
            titleBtn={t("btnSummaryOffer")}
            loading={false}
          />
        </Box>
      </Box>,
      titleAction: t("titleCreateDeceased"),
      subtitleAction: t("subtitleCreateDeceased"),
      action: form.handleSubmit(deceasedFormHandler as never),
      loadingAction: false,
      titleBtn: t("btnSummaryOffer")
    },
    {
      component: <DeliveryMethod 
        onSelectFuneralCompany={(fc) => setFuneralCompany(fc)}
        form={formAddress}
        loadingAction={loadingAction}
        finalAction={submit}
        disableBtn={disableBtnHandler()}
        setDisplayedDeliveryForm={(
          displayed: TChooseForm
        ) => setDisplayedDeliveryForm(displayed)
        }
      />,
      titleAction: t("methodDelivery"),
      subtitleAction: t("subtitleMethodDelivery"),
      action: submit,
      loadingAction,
      titleBtn: t("btnDeliveryMethod"),
    }
  ];

  return(
    <>
      {currentDataItem[step] && <TemplateOffer
        offer={offersData[2]}
        titleAction={currentDataItem[step].titleAction}
        subtitleAction={currentDataItem[step].subtitleAction}
        offerExist={Boolean(offer && offer.type === "started")}
        loading={loading}
        action={currentDataItem[step].action}
        loadingAction={currentDataItem[step].loadingAction}
        titleBtn={currentDataItem[step].titleBtn}
        hideBtn={true}
      >
        <Box sx={{
          backgroundColor: "white",
          p: {md: 4, xs: 2},
          borderRadius: 1
        }}>
          {currentDataItem[step].component}
        </Box>
      </TemplateOffer>}
    </>
  );
};

export default FunnelOffer2;
