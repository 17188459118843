import React, { ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import { grayDark, gold } from "../../../../ui/color";
import { LoadingButton } from "@mui/lab";
import { ChevronRight } from "@mui/icons-material";

export const LayoutFlow = ({
  titleStep,
  subtitleStep,
  content,
  titleBtn,
  loadingBtn,
  actionBtn,
}: {
    titleStep: string,
    subtitleStep: string,
    content: ReactNode,
    titleBtn: string,
    loadingBtn: boolean | undefined,
    actionBtn: () => void
}) => {
  return(
    <Box pt={2}>
      <Typography 
        variant={"titleStep" as never}
        sx={{color: grayDark, pt: 2}}
      >
        {titleStep}
      </Typography>
      <Typography
        sx={{
          color: gold,
          pt: 2,
        }}
        variant={"subtitle" as never}
      >
        {subtitleStep}
      </Typography>

      <Box my={4}>
        {content}
      </Box>

      <LoadingButton 
        loading={loadingBtn}
        onClick={actionBtn}
        variant="contained"
        sx={{
          backgroundColor: gold,
          display: "flex",
          justifyContent: "space-between"
        }}>
        <span>{titleBtn}</span>
        <ChevronRight />
      </LoadingButton>
    </Box>
  );
};