import React from "react";
import {
  IDeathPage,
  IPostFront
} from "../../../../../backend/type/frontDocument";
import { PlacedBouquet } from "./utils/placedBouquet";
import { TemplateInteractivePost } from "./utils/templateInteractivePost";
import { useContext } from "react";
import { PageContext } from "../../../../../provider/deceased/pageProvider";

export const Memorie = ({
  post,
  death,
  openComment,
  deletePostHandler,
}: {
    post: IPostFront,
    openComment?: boolean,
    death?: IDeathPage,
    deletePostHandler?: () => Promise<void>;
}) => {
  const pageHandler = useContext(PageContext);

  if (
    post.type === "pictures" ||
    post.type === "default" ||
    post.type === "video"
  ) {
    return <TemplateInteractivePost
      post={post}
      openCollapseComment={openComment}
      deletePostHandler={deletePostHandler}
      canDeleted={(pageHandler && pageHandler.isAdmin) || false}
    />;
  }

  if (post.type === "buyProduct") {
    return <></>;
  }

  if (post.type === "placedBouquet" && death) {
    return <PlacedBouquet post={post} death={death} />;
  }

  return <></>;
};