import React from "react";
import LayoutLinkProduct from "../../layout/layoutLinkProduct";
import { useForm } from "react-hook-form";
import { SelectAKit }
  from "../../../components/utils/order/linkProduct/selectAKit";
import { useState } from "react";
import { SelectADeceased }
  from "../../../components/utils/order/linkProduct/selectADeceased";
import { ScanTheKit }
  from "../../../components/utils/order/linkProduct/scanTheKit";
import { getKit } from "../../../backend/kit";
import { useAlert } from "../../../provider/error/hooks/useAlert";
import { useTranslation } from "react-i18next";
import { IDeathFront, IKitFront } from "../../../backend/type/frontDocument";
import { postAuth } from "../../../backend/utils/postAuth";
import {
  createDeceasedWithproductUrl,
  linkProductWithDeceased } from "../../../backend/utils/url";
import {
  IDataFrontCreateDeceasedWithproduct,
  IDataFrontLinkStockOrKit
} from "type-absenso";
import { useSearchParams } from "react-router-dom";
import { CreateDeceasedFunnelProduct }
  // eslint-disable-next-line max-len
  from "../../../components/utils/order/linkProduct/createDeceasedInFunnelProduct";
import {
  IDataFormDeceased
} from "../../../components/utils/deceased/formDeceased";
import { useMemo } from "react";
import { DocumentReference, Timestamp } from "firebase/firestore";
import { useCreateDeceased } from "../offers/hooks/useCreateDeceased";

export type TStateMemorial = string | null

const FunnelLinkProduct = () => {
  const formKit = useForm();
  const formDeath = useForm();
  const alert = useAlert();
  const {t} = useTranslation();
  const createDeath = useCreateDeceased({});

  const [loading, setLoading] = useState<boolean>(false);
  const [death, setDeath] = useState<IDeathFront>();
  const [kit, setKit] = useState<IKitFront>();
  const [step, setStep] = useState<number>(0);

  const [searchParams] = useSearchParams();
  const stateMemorial: TStateMemorial = useMemo(() => searchParams.get(
    "memorial"
  ), [searchParams]);

  const handleSubmitKit = async (data: {kitId: string}) => {
    setLoading(true);
    try {
      const kitRes = await getKit({ id: data.kitId });
      if (kitRes) {
        setKit(kitRes);
        setStep(step+1);
      } else {
        alert && alert.createAlert && alert.createAlert({
          type: "error",
          message: t("kitDoesntFind")
        });
      }
    } catch (error) {
      alert.defaultError();
    }
    setLoading(false);
  };

  const handleSubmitLink = async() => {
    if (kit && death) {
      setLoading(true);
      try {
        const dataToSend: IDataFrontLinkStockOrKit = {
          death: death.id,
          products: [kit.id]
        };
        await postAuth(linkProductWithDeceased, dataToSend);
        setStep(step+1);
      } catch (error) {
        alert.defaultError();
      }
      setLoading(false);
    }
  };

  const handleCreateDeceased = async (data: IDataFormDeceased) => {
    try {
      if (kit) {
        setLoading(true);
        const death = createDeath.transformInDataFrontDeath(data);

        if (death && kit) {
          const dataToSend: IDataFrontCreateDeceasedWithproduct<
          DocumentReference, Timestamp
        > = {
          death,
          kitUid: kit.id
        };

          await postAuth(createDeceasedWithproductUrl , dataToSend);
          setStep(step+1);
        }
        setLoading(false);
      }
    } catch (error) {
      alert.defaultError();
    }
  };

  const StepComponent = () => {
    switch (step) {
    case 0:
      return <SelectAKit
        loading={loading}
        formKit={formKit}
        handleSubmitKit={handleSubmitKit}
      />;
    case 1:
      return <SelectADeceased
        handleSubmitLink={handleSubmitLink}
        idKit={kit && kit.id}
        loading={loading}
        handleSearch={(value: IDeathFront) => setDeath(value)}
        death={death}
      />;
    case 2:
      return <ScanTheKit
        idKit={kit && kit.id}
        deathname={death && death.fullname}
      />;
    default:
      return <></>;
    }
  };

  return(
    <LayoutLinkProduct stateMemorial={stateMemorial} step={step}>
      {step === 1 && stateMemorial === "create" ? 
        <CreateDeceasedFunnelProduct
          form={formDeath}
          handleDeceased={handleCreateDeceased}
          loading={loading}
        /> :
        <StepComponent />
      }
    </LayoutLinkProduct>
  );
};

export default FunnelLinkProduct;
